import { MessageTemplate } from '../../shared/Constants';
import PropTypes from 'prop-types';

function TemplateSelector({
  messageContent,
  setMessageContent,
  selectedDevices,
  publisher,
  defaultContent,
}) {
  const handleChange = (e) => {
    const devices = selectedDevices.map((device) => device.toString());
    setMessageContent(() => ({
      ...defaultContent,
      template: e.target.value,
      devices: devices,
      publisherUserName: publisher.displayName,
      publisherUserId: publisher.uid,
    }));
  };

  return (
    <>
      <div>
        <label className='form-label'>Template:</label>
        <div>
          <select
            className='select--width-300'
            id='templateSelector'
            defaultValue=''
            onChange={handleChange}
          >
            <option value=''>Select</option>
            <option value={MessageTemplate.ADMIN}>Admin content</option>
            <option value={MessageTemplate.BATT_LOW}>Battery low</option>
            <option value={MessageTemplate.BLE_REFRESH_BACKGROUND}>
              Bluetooth refresh background
            </option>
            <option value={MessageTemplate.EVENT_LOG_PERIODIC}>
              Event log upload daily
            </option>
            <option value={MessageTemplate.GET_APP_LOG}>Get App log</option>
            <option value={MessageTemplate.GET_EVENT_LOG}>
              Get device event log
            </option>
            <option value={MessageTemplate.KILL_SWITCH_MODE}>
              Kill switch mode
            </option>
            <option value={MessageTemplate.MAKE_APP_MESSAGE_UPDATE}>
              Make App message update
            </option>
            <option value={MessageTemplate.MAKE_POPIT_DEVICE_FW_UPDATE_CHECK}>
              Make Device FW update
            </option>
            <option value={MessageTemplate.BLE_FIX_WITH_NOTIFICATION}>
              Notification: Bluetooth fix
            </option>
            <option value={MessageTemplate.DOSE_BLE_OFF}>
              Notification: Have you taken dose?
            </option>
            <option value={MessageTemplate.MEDICINE_REMINDER}>
              Notification: Medicine reminder
            </option>
            <option value={MessageTemplate.PILOT_MODE}>Pilot mode</option>
            <option value={MessageTemplate.TRIAL_END_MODE}>Trial mode</option>
            <option value={MessageTemplate.UPDATE_APP_CONFIG}>
              Update App config
            </option>
            <option value={MessageTemplate.UPDATE_CLOUD_SERVER}>
              Update Cloud server
            </option>
            <option value={MessageTemplate.WEB_LINK}>
              Web content notification
            </option>
          </select>
        </div>
        <div className='mt-1 mb-1'>
          {getSelectedContent(messageContent, setMessageContent)}
        </div>
      </div>
    </>
  );
}

const getSelectedContent = (messageContent, setMessageContent) => {
  switch (messageContent.template) {
    case MessageTemplate.ADMIN:
      return contentAdmin(messageContent, setMessageContent);
    case MessageTemplate.BATT_LOW:
      return contentBattLow();
    case MessageTemplate.BLE_FIX_WITH_NOTIFICATION:
      return contentBleFixWithNotification();
    case MessageTemplate.BLE_REFRESH_BACKGROUND:
      return contentBleRefresh();
    case MessageTemplate.DOSE_BLE_OFF:
      return contentDoseBleOff();
    case MessageTemplate.EVENT_LOG_PERIODIC:
      return contentEventLogPeriodic(messageContent, setMessageContent);
    case MessageTemplate.GET_APP_LOG:
      return contentGetAppLog();
    case MessageTemplate.GET_EVENT_LOG:
      return contentGetEventLog();
    case MessageTemplate.KILL_SWITCH_MODE:
      return contentKillSwitchMode(messageContent, setMessageContent);
    case MessageTemplate.MAKE_APP_MESSAGE_UPDATE:
      return contentMakeAppMessageUpdate(setMessageContent);
    case MessageTemplate.MAKE_POPIT_DEVICE_FW_UPDATE_CHECK:
      return contentMakePopitDeviceFwUpdateCheck(setMessageContent);
    case MessageTemplate.MEDICINE_REMINDER:
      return contentMedicineReminder();
    case MessageTemplate.PILOT_MODE:
      return contentPilotMode(messageContent, setMessageContent);
    case MessageTemplate.TRIAL_END_MODE:
      return contentTrialEndMode(messageContent, setMessageContent);
    case MessageTemplate.UPDATE_APP_CONFIG:
      return contentUpdateAppConfig(setMessageContent);
    case MessageTemplate.UPDATE_CLOUD_SERVER:
      return contentUpdateCloudServer();
    case MessageTemplate.WEB_LINK:
      return contentWebLink(messageContent, setMessageContent);
    default:
      return null;
  }
};

const contentAdmin = (messageContent, setMessageContent) => {
  const updateDataKeyForIndex = (e, i, messageContent, setMessageContent) => {
    const newKeys = { ...messageContent.dataKeys };
    newKeys[i] = e.target.value;
    const newValues = { ...messageContent.dataValues };
    newValues[i] = '';
    setMessageContent((prevState) => ({
      ...prevState,
      dataKeys: { ...newKeys },
      dataValues: { ...newValues },
    }));
  };

  const updateDataValueForIndex = (e, i, messageContent, setMessageContent) => {
    const newValues = { ...messageContent.dataValues };
    newValues[i] = e.target.value;
    setMessageContent((prevState) => ({
      ...prevState,
      dataValues: { ...newValues },
    }));
  };

  const swtichWithNotification = (messageContent, setMessageContent) => {
    setMessageContent((prevState) => ({
      ...prevState,
      dataWithNotification: !messageContent.dataWithNotification,
      rawJSONSet: false,
    }));
  };

  const switchWithData = (messageContent, setMessageContent) => {
    setMessageContent((prevState) => ({
      ...prevState,
      dataSet: !messageContent.dataSet,
      dataKeysCount: 1,
      rawJSONSet: false,
      rawJSON: '',
    }));
  };
  const switchRawJson = (messageContent, setMessageContent) => {
    setMessageContent((prevState) => ({
      ...prevState,
      dataSet: false,
      dataKeys: {},
      dataValues: {},
      dataKeysCount: 0,
      dataWithNotification: false,
      rawJSONSet: !messageContent.rawJSONSet,
    }));
  };

  const handleChangeCategory = (e) => {
    setMessageContent((prevState) => ({
      ...prevState,
      contentCategory: String(e.target.value),
    }));
  };

  const handleChangeSound = (e) => {
    setMessageContent((prevState) => ({
      ...prevState,
      contentSound: String(e.target.value),
    }));
  };

  const handleChangeAddRow = () => {
    setMessageContent((prevState) => ({
      ...prevState,
      dataKeysCount: messageContent.dataKeysCount + 1,
    }));
  };

  const handleChangeRawJSON = (e) => {
    setMessageContent((prevState) => ({
      ...prevState,
      rawJSON: String(e.target.value),
    }));
  };

  return (
    <>
      <div>
        <input
          type='checkbox'
          checked={messageContent.dataWithNotification}
          onChange={() =>
            swtichWithNotification(messageContent, setMessageContent)
          }
        />
        <span> Notification content </span>
        <input
          type='checkbox'
          checked={messageContent.dataSet}
          onChange={() => switchWithData(messageContent, setMessageContent)}
        />
        <span> With data content </span>
        <input
          type='checkbox'
          checked={messageContent.rawJSONSet}
          onChange={() => switchRawJson(messageContent, setMessageContent)}
        />
        <span> Raw JSON input </span>
      </div>

      {messageContent.dataWithNotification && (
        <div>
          <b>Notification content:</b>
          <div className='fillRow'>
            Category:
            <select onChange={handleChangeCategory} defaultValue=''>
              <option value=''>None</option>
              <option value='restartApp'>Prompt app restart</option>
              <option value='battLow'>Battery low</option>
              <option value='bleConnection_1'>Fix bluetooth connection</option>
              <option value='pilotMode'>Pilot mode</option>
            </select>
          </div>
          <div className='fillRow'>
            Sound:
            <select onChange={handleChangeSound} defaultValue=''>
              <option value=''>None</option>
              <option value='default'>System default</option>
              <option value='PillReminder1.caf'>Pill reminder</option>
            </select>
          </div>
          {contentWebLink(messageContent, setMessageContent)}
        </div>
      )}

      {messageContent.dataSet && (
        <div>
          <div className='fillRow-tb'>
            <b>Data content:</b>
          </div>
          {[...Array(messageContent.dataKeysCount).keys()].map((i) => (
            <div className='fillRow-half' key={i}>
              <input
                type='text'
                placeholder='Key'
                onChange={(e) =>
                  updateDataKeyForIndex(e, i, messageContent, setMessageContent)
                }
              />
              <input
                type='text'
                placeholder='Value'
                onChange={(e) =>
                  updateDataValueForIndex(
                    e,
                    i,
                    messageContent,
                    setMessageContent,
                  )
                }
              />
            </div>
          ))}
          <div className='fillRow-tb'>
            <button className='btn-small-outline' onClick={handleChangeAddRow}>
              Add new key
            </button>
          </div>
        </div>
      )}
      {messageContent.rawJSONSet && (
        <div>
          <b>Raw JSON:</b>
          <textarea
            className='jsonInput'
            placeholder='Insert here full JSON content of the push message'
            onChange={handleChangeRawJSON}
          />
        </div>
      )}
    </>
  );
};

const contentBattLow = () => {
  return (
    <div>
      This push message displays a battery low notification on the targeted
      devices.
    </div>
  );
};

const contentBleFixWithNotification = () => {
  return (
    <div>
      This push message displays a Bluetooth fix notification on the targeted
      devices and also runs the Bluetooth fix action in background.
    </div>
  );
};

const contentBleRefresh = () => {
  return (
    <div>
      This push message executes the Bluetooth refresh action in the background
      on the targeted devices.
    </div>
  );
};

const contentDoseBleOff = () => {
  return (
    <div>
      This push message displays a Have you taken dose? notification on the
      targeted devices and also notes that the Bluetooth is turned off.
    </div>
  );
};

const contentEventLogPeriodic = (messageContent, setMessageContent) => {
  const handleChange = () => {
    setMessageContent((prevState) => ({
      ...prevState,
      parameters: {
        ...prevState.parameters,
        eventLogPeriodic: !messageContent.parameters.eventLogPeriodic,
      },
    }));
  };

  return (
    <div>
      <div>
        Set periodic device event log upload. Event logs shall be uploaded every
        day on the target device.
      </div>
      <input
        type='checkbox'
        checked={messageContent.parameters.eventLogPeriodic}
        onChange={handleChange}
      />
      <span> Enable / disable event log download </span>
    </div>
  );
};

const contentGetAppLog = () => {
  return (
    <div>
      This push message triggers App log upload to server. Log file is sent to
      cloud server as soon as message is delivered to the App.
    </div>
  );
};

const contentGetEventLog = () => {
  return (
    <div>
      This push message sends a command to App and next time when the device
      connects to the App it will upload device event log to cloud server.
    </div>
  );
};

const contentKillSwitchMode = (messageContent, setMessageContent) => {
  const handleChange = () => {
    setMessageContent((prevState) => ({
      ...prevState,
      parameters: {
        ...prevState.parameters,
        killSwitchMode: !messageContent.parameters.killSwitchMode,
      },
    }));
  };

  return (
    <>
      <div>
        This push message toggles the kill switch mode on the targeted devices.
      </div>
      <input
        type='checkbox'
        checked={messageContent.parameters.killSwitchMode}
        onChange={handleChange}
      />
      <span> Enable / disable kill switch mode </span>
    </>
  );
};

const contentMakeAppMessageUpdate = (setMessageContent) => {
  const handleChange = (e) => {
    setMessageContent((prevState) => ({
      ...prevState,
      parameters: {
        ...prevState.parameters,
        updateAppConfig: String(e.target.value),
      },
    }));
  };

  return (
    <>
      <div>
        This push message triggers App to reload App message content from cloud
        server.
      </div>
      <div className='fillRow'>
        <input
          type='text'
          placeholder={`Set device SN (optional)`}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

const contentMakePopitDeviceFwUpdateCheck = (setMessageContent) => {
  const handleChange = (e) => {
    setMessageContent((prevState) => ({
      ...prevState,
      parameters: {
        ...prevState.parameters,
        makePopitDeviceFwUpdateCheck: String(e.target.value),
      },
    }));
  };

  return (
    <div>
      <div>
        This push message sends a command to App and next time when the device
        connects to the App it will check possible FW update.
      </div>
      <div className='fillRow'>
        <input
          type='text'
          placeholder={`Set device SN (optional)`}
          onChange={handleChange}
        />
      </div>{' '}
    </div>
  );
};

const contentMedicineReminder = () => {
  return (
    <div>
      This push message is shown to user directly as a medicine reminder.
    </div>
  );
};

const contentPilotMode = (messageContent, setMessageContent) => {
  const handleChange = () => {
    setMessageContent((prevState) => ({
      ...prevState,
      parameters: {
        ...prevState.parameters,
        pilotMode: !messageContent.parameters.pilotMode,
      },
    }));
  };

  return (
    <>
      <div>
        This push message toggles the pilot mode on the targeted devices.
      </div>
      <input
        type='checkbox'
        checked={messageContent.parameters.pilotMode}
        onChange={handleChange}
      />
      <span> Enable / disable pilot mode </span>
    </>
  );
};

const contentTrialEndMode = (messageContent, setMessageContent) => {
  const handleChange = () => {
    setMessageContent((prevState) => ({
      ...prevState,
      parameters: {
        ...prevState.parameters,
        trialModeEnded: !messageContent.parameters.trialModeEnded,
      },
    }));
  };

  return (
    <>
      <div>
        This push message sets the trial mode ended on the targeted devices.
      </div>
      <input
        type='checkbox'
        checked={messageContent.parameters.trialModeEnded}
        onChange={handleChange}
      />
      <span> Set trial mode Enable / disable </span>
    </>
  );
};

const contentUpdateAppConfig = (setMessageContent) => {
  const handleChange = (e) => {
    setMessageContent((prevState) => ({
      ...prevState,
      parameters: {
        ...prevState.parameters,
        updateAppConfig: String(e.target.value),
      },
    }));
  };
  return (
    <>
      <div>
        This push message triggers App to reload App configuration from cloud
        server.
      </div>
      <div className='fillRow'>
        <input
          type='text'
          placeholder={`Set device SN (optional)`}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

const contentUpdateCloudServer = () => {
  return (
    <div>
      This push message triggers App to reload Cloud server configuration from
      cloud server. This is done based on App ID or possible connected device
      SN.
    </div>
  );
};

const contentWebLink = (messageContent, setMessageContent) => {
  const updateTitleForLanguage = (
    title,
    lang,
    messageContent,
    setMessageContent,
  ) => {
    const newTitles = { ...messageContent.contentLocalizedTitles };
    if (title.length > 0) {
      newTitles[lang] = title;
      setMessageContent((prevState) => ({
        ...prevState,
        contentLocalizedTitles: { ...newTitles },
      }));
    } else {
      // Remove entry if lenght is zero
      delete newTitles[lang];
      setMessageContent((prevState) => ({
        ...prevState,
        contentLocalizedTitles: { ...newTitles },
      }));
    }
  };

  const updateBodyForLanguage = (
    body,
    lang,
    messageContent,
    setMessageContent,
  ) => {
    const newBodies = { ...messageContent.contentLocalizedBodies };
    if (body.length > 0) {
      newBodies[lang] = body;
      setMessageContent((prevState) => ({
        ...prevState,
        contentLocalizedBodies: { ...newBodies },
      }));
    } else {
      // Remove entry if lenght is zero
      delete newBodies[lang];
      setMessageContent((prevState) => ({
        ...prevState,
        contentLocalizedBodies: { ...newBodies },
      }));
    }
  };

  const handleChangeImage = (e) => {
    setMessageContent((prevState) => ({
      ...prevState,
      contentImage: String(e.target.value),
    }));
  };

  const handleChangeLink = (e) => {
    setMessageContent((prevState) => ({
      ...prevState,
      contentLink: e.target.value,
    }));
  };

  return (
    <>
      <div className='fillRow-tb'>
        <p>
          This push message displays a notification with an image and opens a
          link when the user taps it.
        </p>
        <b>Note: English title and text are required fields!</b>
      </div>
      {messageContent.contentLanguages.map((lang) => (
        <div className='fillRow-half' key={lang}>
          <input
            type='text'
            placeholder={`Notification title - ${lang}`}
            onChange={(e) => {
              updateTitleForLanguage(
                String(e.target.value),
                lang,
                messageContent,
                setMessageContent,
              );
            }}
          />
          <input
            type='text'
            placeholder={`Notification text - ${lang}`}
            onChange={(e) => {
              updateBodyForLanguage(
                String(e.target.value),
                lang,
                messageContent,
                setMessageContent,
              );
            }}
          />
        </div>
      ))}
      <div className='fillRow-half'>
        <input
          type='text'
          placeholder='Image url'
          onChange={handleChangeImage}
        />
      </div>
      <div className='fillRow-half'>
        <input type='text' placeholder='Web link' onChange={handleChangeLink} />
      </div>
    </>
  );
};

TemplateSelector.propTypes = {
  messageContent: PropTypes.object.isRequired,
  setMessageContent: PropTypes.func.isRequired,
  selectedDevices: PropTypes.arrayOf(PropTypes.number).isRequired,
  publisher: PropTypes.object.isRequired,
  defaultContent: PropTypes.object.isRequired,
};

export default TemplateSelector;
