import { db } from '../app/config';

export const isDevEnv = () => {
  const { projectId } = db.app.options || 'not-defined';
  return projectId == 'popit-cloud-development';
};

export const envNameByProjectId = () => {
  const { projectId } = db.app.options || 'not-defined';
  switch (projectId) {
    case 'popit-cloud-development':
      return 'DEV';
    case 'popit-connect':
      return 'STAGING';
    case 'popit-cloud':
      return 'PROD';
    case 'cloud-frankfurt-shared':
      return 'FRANK';
    default:
      return 'Not defined';
  }
};
