import { useState, useEffect } from 'react';
import { useCompanyEdit } from '../../hooks/useCompanyEdit';
import { ACTION_OBJECT, GROUP_ACTIONS } from '../../services/company.service';
import Alert from '../Alert';
import Modal from '../Modal';
import Spinner from '../Spinner';
import PropTypes from 'prop-types';

function GroupActionModal({ modalData, setModalData, updateCompanies }) {
  const { editCompany, isUpdating } = useCompanyEdit();
  const [title, setTitle] = useState('no title');
  const [description, setDescription] = useState('no description');
  const [error, setError] = useState(null);

  const { isOpen, actionObject } = modalData;

  const reset = (dataUpdated) => {
    setModalData({
      isOpen: false,
      title: '',
      description: '',
      actionObject: { ...ACTION_OBJECT },
    });
    setError(null);
    if (dataUpdated === true) {
      updateCompanies();
    }
  };

  useEffect(() => {
    const pageText = getTexts();
    setTitle(pageText.title);
    setDescription(pageText.description);
    if (isUpdateOrCreate()) {
      setModalData((prevState) => ({
        ...prevState,
        actionObject: {
          ...prevState.actionObject,
          newData: { name: getPreviousName() },
        },
      }));
    }
    // NOTE: warning silenced in purpose. This useEffect should be
    // run only once when component mounted.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTexts = () => {
    switch (modalData?.actionObject?.action) {
      case GROUP_ACTIONS.updateCompany:
        return {
          title: 'Update company name',
          description: `Write the new name for the company: ${
            actionObject?.rawDataCompany?.name || 'not defined'
          }.\nName must be more than 3 characters.`,
        };
      case GROUP_ACTIONS.updateGroup:
        return {
          title: 'Update group name',
          description: `Write the new name for the company group: ${
            actionObject?.rawDataGroup?.name || 'not defined'
          }.\nName must be more than 3 characters.`,
        };
      case GROUP_ACTIONS.deleteCompany:
        return {
          title: 'Delete company',
          description: `Are you sure you want to delete this company: ${
            modalData?.actionObject?.rawDataCompany?.name || 'NA comp'
          }`,
        };
      case GROUP_ACTIONS.deleteGroup:
        return {
          title: 'Delete group',
          description: `Are you sure you want to delete this company group: ${
            modalData?.actionObject?.rawDataGroup?.name || 'NA group'
          }`,
        };
      case GROUP_ACTIONS.createCompany:
        return {
          title: 'Create new company',
          description:
            'Write the name for the new company.\nName must be more than 3 characters.',
        };
      case GROUP_ACTIONS.createGroup:
        return {
          title: 'Create new group',
          description:
            'Write the name for the new company group.\nName must be more than 3 characters.',
        };
      default:
        return { title: 'NOT DEFINED', description: 'TODO' };
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await editCompany(actionObject);
      reset(true);
    } catch (error) {
      setError(error);
    }
  };

  const isUpdateOrCreate = () => {
    switch (modalData?.actionObject?.action) {
      case GROUP_ACTIONS.updateCompany:
      case GROUP_ACTIONS.updateGroup:
      case GROUP_ACTIONS.createCompany:
      case GROUP_ACTIONS.createGroup:
        return true;
      default:
        return false;
    }
  };

  const getPreviousName = () => {
    switch (modalData?.actionObject?.action) {
      case GROUP_ACTIONS.updateCompany:
        return modalData?.actionObject?.rawDataCompany?.name || 'NA comp';
      case GROUP_ACTIONS.updateGroup:
        return modalData?.actionObject?.rawDataGroup?.name || 'NA group';
      default:
        return '';
    }
  };

  const confirmDisabled = () => {
    if (isUpdateOrCreate()) {
      return modalData?.actionObject?.newData?.name.trim().length < 3;
    } else {
      return false;
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <div className='card-m'>
        <div className='card-body-m'>
          <h3 className='card-title'>{title}</h3>
          <hr />
          <p>{description}</p>

          {isUpdating && <Spinner isSpinning={isUpdating} />}
          {error != null && (
            <div className='mtb-1'>
              <Alert type='error' title={error.message} content={error.cause} />
            </div>
          )}
          {isUpdateOrCreate() && (
            <div className='row mt-1'>
              <div className='col-12'>
                <div>
                  <input
                    type='name'
                    className='form-control'
                    id='name'
                    placeholder={
                      modalData?.actionObject?.newData?.name.length > 0
                        ? ''
                        : 'Enter name'
                    }
                    value={modalData?.actionObject?.newData?.name || ''}
                    onChange={(e) =>
                      setModalData((prevState) => ({
                        ...prevState,
                        actionObject: {
                          ...prevState.actionObject,
                          newData: { name: e.target.value },
                        },
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          )}

          <div className='row mt-2'>
            <div className='col-12 c-group u-h-evenly'>
              <button className={`btn is-secondary`} onClick={reset}>
                Cancel
              </button>
              <button
                className={`btn`}
                disabled={confirmDisabled()}
                onClick={handleClick}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

GroupActionModal.propTypes = {
  modalData: PropTypes.object.isRequired,
  setModalData: PropTypes.func.isRequired,
  updateCompanies: PropTypes.func.isRequired,
};

export default GroupActionModal;
