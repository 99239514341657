import Modal from '../Modal';
import UserForm from './UserForm';
import PropTypes from 'prop-types';

function UserEditModal({ user, userEditModalIsOpen, closeModalUserEdit }) {
  return (
    <Modal isOpen={userEditModalIsOpen}>
      <UserForm user={user} closeModalUserEdit={closeModalUserEdit} />
    </Modal>
  );
}

UserEditModal.propTypes = {
  user: PropTypes.object.isRequired,
  userEditModalIsOpen: PropTypes.bool.isRequired,
  closeModalUserEdit: PropTypes.func.isRequired,
};

export default UserEditModal;
