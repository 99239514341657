import { useState } from 'react';
import Alert from '../Alert';
import Modal from '../Modal';
import Spinner from '../Spinner';
import { setUserEnabled } from '../../services/users.service';
import PropTypes from 'prop-types';

function UserEnableAccountModal({
  user,
  userEnableAccountModalIsOpen,
  closeModalUserEnabledEdit,
}) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);

  const title = user.enabled ? 'Disable user account' : 'Activate user account';
  const description = `Do you want to ${
    user.enabled ? 'disable' : 'activate'
  } user account: ${user.displayName}`;

  const handleClick = async () => {
    setError(null);
    setIsUpdating(true);
    const userEnabled = user?.enabled === undefined ? true : user?.enabled;

    try {
      await setUserEnabled(user.uid, !userEnabled);
      closeModalUserEnabledEdit(true);
    } catch (error) {
      setError(error);
    }
  };

  const reset = () => {
    closeModalUserEnabledEdit(false);
  };

  return (
    <Modal isOpen={userEnableAccountModalIsOpen}>
      <div className='card-m'>
        <div className='card-body-m'>
          <h3 className='card-title'>{title}</h3>
          <hr />
          <p>{description}</p>

          {isUpdating && <Spinner isSpinning={isUpdating} />}
          {error != null && (
            <div className='mtb-1'>
              <Alert type='error' title={error.message} content={error.cause} />
            </div>
          )}
          <div className='row mt-2'>
            <div className='col-12 c-group u-h-evenly'>
              <button
                className={`btn is-secondary`}
                type='button'
                disabled={isUpdating}
                onClick={reset}
              >
                Cancel
              </button>
              <button className={`btn`} type='button' onClick={handleClick}>
                {user.enabled ? 'Disable' : 'Activate'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

UserEnableAccountModal.propTypes = {
  user: PropTypes.object.isRequired,
  userEnableAccountModalIsOpen: PropTypes.bool.isRequired,
  closeModalUserEnabledEdit: PropTypes.func.isRequired,
};

export default UserEnableAccountModal;
