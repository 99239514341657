import { createColumnHelper } from '@tanstack/react-table';
import CellCloudServer from './cells/CellCloudServer';

function tableHeaderCloudServer(setNewCloudServer) {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.accessor('deviceId', {
      id: 'deviceId',
      header: () => 'deviceId',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('cloudServer', {
      id: 'cloudServer',
      header: () => 'cloudServer',
      cell: (info) => {
        return (
          <CellCloudServer
            cloudServer={info.row.original.cloudServer}
            setNewCloudServer={setNewCloudServer}
          />
        );
      },
      enableSorting: true,
    }),
    columnHelper.accessor('addedTimestamp', {
      id: 'addedTimestamp',
      header: () => 'addedTimestamp',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('lotNumber', {
      id: 'lotNumber',
      header: () => 'lotNumber',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
  ];
}

export default tableHeaderCloudServer;
