const pushMessageContent = {
  category: '',
  contentImage: '',
  contentLanguages: ['en', 'fi', 'fr', 'de'],
  contentLocalizedBodies: {},
  contentLocalizedTitles: {},
  contentCategory: '',
  contentLink: '',
  contentSound: '',
  dataKeys: {},
  dataKeysCount: 0,
  dataSet: false,
  dataValues: {},
  dataWithNotification: false,
  devices: [],
  parameters: {
    eventLogPeriodic: false,
    killSwitchMode: false,
    makeAppMessageUpdate: '',
    makePopitDeviceFwUpdateCheck: '',
    pilotMode: false,
    trialModeEnded: false,
    updateAppConfig: '',
  },
  publisherUserName: '',
  publisherUserId: '',
  rawJSON: '',
  rawJSONSet: false,
  template: '',
};

export default pushMessageContent;
