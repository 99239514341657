import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function CellEventLogDeivceId({ deviceId }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/event-log/${deviceId}`);
  };

  return (
    <div className='cell-stats-medicineId' onClick={handleClick}>
      {deviceId}
    </div>
  );
}

CellEventLogDeivceId.propTypes = {
  deviceId: PropTypes.number.isRequired,
};

export default CellEventLogDeivceId;
