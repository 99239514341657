import { IoMail, IoCheckmarkCircle, IoEye } from 'react-icons/io5';
import PropTypes from 'prop-types';

function CellMessageDeviceStatus({ rawData }) {
  return (
    <div>
      {rawData.devices.map((device) => {
        let msgID = undefined;
        if (rawData.queuedMessages) {
          msgID = rawData.queuedMessages[device];
        }

        let acknowledged = false;
        if (
          msgID !== null &&
          msgID !== undefined &&
          rawData.acknowledged &&
          rawData.acknowledged.indexOf(msgID) > -1
        ) {
          acknowledged = true;
        }

        let interaction = 'none';
        if (msgID !== null && msgID !== undefined && rawData.interactions) {
          interaction = rawData.interactions[msgID] ?? 'none';
        }

        return (
          <div className='row' key={device}>
            <div className='col-12 c-group u-v-center'>
              <div className='cell-msg-deviceId'>{device}</div>
              <div
                className={`cell-msg-status-icon ${
                  msgID !== undefined ? 'ok' : 'nok'
                }`}
                title={
                  msgID !== undefined
                    ? 'Msg sent'
                    : 'No push token, msg not sent'
                }
              >
                <IoMail />
              </div>
              <div
                className={`cell-msg-status-icon ${
                  msgID !== acknowledged ? 'ok' : 'nok'
                }`}
                title={acknowledged ? 'Msg received' : 'No ack received'}
              >
                {acknowledged && <IoCheckmarkCircle />}
              </div>
              <div
                className={`cell-msg-status-icon ${
                  interaction !== 'none' ? 'ok' : 'nok'
                }`}
                title={
                  interaction !== 'none' ? 'Action: ' + interaction : 'No data'
                }
              >
                {interaction !== 'none' && <IoEye />}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

CellMessageDeviceStatus.propTypes = {
  rawData: PropTypes.object.isRequired,
};

export default CellMessageDeviceStatus;
