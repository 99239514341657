import PropTypes from 'prop-types';
import { useState } from 'react';
import { FaRegClock, FaCalendarAlt, FaSlidersH } from 'react-icons/fa';
import { IoCheckmarkCircle, IoMedical } from 'react-icons/io5';

function CellReminderData({ reminderData }) {
  const [showAllData, setShowAllData] = useState(false);
  if (
    reminderData === undefined ||
    reminderData === null ||
    !reminderData.length > 0
  ) {
    // console.error('reminderData NA: ', reminderData);
    return null;
  }

  const hasActiveRems =
    reminderData.filter((rem) => rem.end === undefined).length > 0;
  const hasDeactivatedRems =
    reminderData.filter((rem) => rem.end !== undefined).length > 0;

  if (hasActiveRems || showAllData === true) {
    return reminderData.map((rem) => {
      const remActive = rem.end ? false : true;
      const type = rem.type;
      if (showAllData === true || remActive === true) {
        if (type === 1) {
          return getFlexRem(rem, setShowAllData, hasDeactivatedRems);
        } else if (type === 2) {
          return getIntervalRem(rem, setShowAllData, hasDeactivatedRems);
        } else {
          return getDailyRem(rem, setShowAllData, hasDeactivatedRems);
        }
      } else {
        return null;
      }
    });
  } else {
    return (
      <div
        className='cell-reminderData show-click'
        key='no-active-reminder'
        onClick={() => {
          setShowAllData((prevState) => {
            return !prevState;
          });
        }}
      >
        <div className='cell-reminderData-icon nok'>
          <IoCheckmarkCircle />
        </div>
      </div>
    );
  }
}

// Type definitions
// 0 = Daily
// 1 = Flex
// 2 = Interval

const getDailyRem = (rem, setShowAllData, hasDeactivatedRems) => {
  if (!rem.type === 0) {
    console.error('Incorrect reminder type');
    return <div>ERR</div>;
  }
  return (
    <div key={`daily-reminder-${rem.reminderUuid}`}>
      <div
        className={`cell-reminderData ${
          hasDeactivatedRems ? 'show-click' : ''
        }`}
        onClick={() => {
          setShowAllData((prevState) => {
            return !prevState;
          });
        }}
      >
        <div
          className={`cell-reminderData-icon ${
            (rem.end ? false : true) ? 'ok' : 'nok'
          }`}
        >
          <FaRegClock />
        </div>
        <div>{mapTime(rem.times[0])}</div>
        <div className='cell-reminderData-icon-dose'>
          <IoMedical />
          {rem.dose}
        </div>
      </div>
    </div>
  );
};

const getFlexRem = (rem, setShowAllData, hasDeactivatedRems) => {
  if (!rem.type === 1) {
    console.error('Incorrect reminder type');
    return <div>ERR</div>;
  }

  return (
    <div key={`flex-reminder-${rem.reminderUuid}`}>
      <div
        className={`cell-reminderData ${
          hasDeactivatedRems ? 'show-click' : ''
        }`}
        onClick={() => {
          setShowAllData((prevState) => {
            return !prevState;
          });
        }}
      >
        <div
          className={`cell-reminderData-icon ${
            (rem.end ? false : true) ? 'ok' : 'nok'
          }`}
        >
          <FaCalendarAlt />
        </div>

        <div className='cell-reminderData-times-flex'>
          {Object.keys(rem.times).map((item, index) => {
            return (
              <div className='one-time' key={`${rem.times[item]}-${index}`}>
                {`${mapWeekday(item)}: ${mapTime(rem.times[item])}`}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const getIntervalRem = (rem, setShowAllData, hasDeactivatedRems) => {
  if (!rem.type === 2) {
    console.error('Incorrect reminder type');
    return <div>ERR</div>;
  }

  return (
    <div key={`interval-reminder-${rem.reminderUuid}`}>
      <div
        className={`cell-reminderData ${
          hasDeactivatedRems ? 'show-click' : ''
        }`}
        onClick={() => {
          setShowAllData((prevState) => {
            setShowAllData(!prevState);
          });
        }}
      >
        <div
          className={`cell-reminderData-icon ${
            (rem.end ? false : true) ? 'ok' : 'nok'
          }`}
        >
          <FaSlidersH />
        </div>

        <div className='cell-reminderData-times-interval'>
          <div>Time: {mapTime(rem.times[0])}</div>
          <div>Days: {rem.intervalDay}</div>
          <div>Start: {rem.start}</div>
          <div>End: {rem.end}</div>
        </div>
      </div>
    </div>
  );
};

const mapWeekday = (item) => {
  if (item === '1') return 'Su';
  if (item === '2') return 'Mo';
  if (item === '3') return 'Tu';
  if (item === '4') return 'We';
  if (item === '5') return 'Th';
  if (item === '6') return 'Fr';
  if (item === '7') return 'Sa';
  return 'ERROR';
};

const mapTime = (totalMinutes) => {
  if (!totalMinutes) {
    return 'ERROR';
  }
  const hours = Math.floor(totalMinutes / 60);
  let minutes = totalMinutes % 60;
  if (minutes === 0) {
    minutes = '00';
  } else if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
};

CellReminderData.propTypes = {
  reminderData: PropTypes.array,
};

export default CellReminderData;
