/**
 * List of all possible push messages. Each message content defined
 * separately.
 */
export const MessageTemplate = Object.freeze({
  ADMIN: 'admin',
  BATT_LOW: 'battLow',
  BLE_FIX_WITH_NOTIFICATION: 'bleFixWithNotification',
  BLE_REFRESH_BACKGROUND: 'bleRefreshBackground',
  DOSE_BLE_OFF: 'doseBleOff',
  EVENT_LOG_PERIODIC: 'eventLogPeriodic',
  GET_APP_LOG: 'getAppLog',
  GET_EVENT_LOG: 'getEventLog',
  KILL_SWITCH_MODE: 'killSwitchMode',
  MAKE_APP_MESSAGE_UPDATE: 'makeAppMessageUpdate',
  MAKE_POPIT_DEVICE_FW_UPDATE_CHECK: 'makePopitDeviceFwUpdateCheck',
  MEDICINE_REMINDER: 'medicineReminder',
  PILOT_MODE: 'pilotMode',
  TRIAL_END_MODE: 'trialModeEnded',
  UPDATE_APP_CONFIG: 'updateAppConfig',
  UPDATE_CLOUD_SERVER: 'updateCloudServer',
  WEB_LINK: 'webLink',
});

export const USER_DATA_DEFAULTS = {
  displayName: '',
  email: '',
  created: Math.floor(Date.now() / 1000),
  enabled: true,
  theme: 'light',
  admin: false,
};

export const CLOUD_SERVER_OPTIONS = [
  'popit-cloud-development',
  'popit-connect',
  'popit-cloud',
  'cloud-frankfurt-shared',
];

export const REMINDER_DAY_STATUS = {
  '-1': 'No Data',
  0: 'All Missed',
  5: 'Took Some',
  9: 'All Early',
  10: 'All OnTime',
  11: 'All Late',
  15: 'With Deviation',
  19: 'Multiple',
  20: 'On Break',
  21: 'Skipped',
  22: 'Took NotScheduled',
  23: 'Taken NotScheduled',
  24: 'Took NoReminder',
  25: 'NoReminder',
  26: 'NoMedication',
};

// 1 - 255 DEVICE EVENTS
// 1 - 29 allocated to mark as taken
// 30 - 39 allocated to missed
// 300-> APP EVENTS
// 300 - 309 allocated to mark as taken for the current med
// 310 - 319 allocated to history mark as taken
// 320 - 329 allocated to other app mark as taken or similar like skipped
// 330 - 339 allocated to dose not taken events
// 340 - 349 allocated to app generated medication status events
// 400 - 499 allocated to app backend actions
export const APP_EVENTS = {
  0: 'Undefined',
  2: 'Dose-Mic',
  3: 'Dose-Movement',
  4: 'Dose-Touch',
  5: 'Dose-Mic and Touch',
  6: 'Dose-Mic no Touch',
  7: 'Dose-Button',
  29: 'Undefined Detection',

  30: 'Missed Generic',
  31: 'Missed NoMotion',
  32: 'Missed Motion',
  33: 'Missed Touch',
  34: 'Missed Package Open',

  38: 'PackageClosed-NoDetection',
  39: 'PackageClosed-Timeout',

  40: 'Button Short',
  41: 'Button Long',
  42: 'Button Cancellation',
  43: 'Button-lock Press',

  50: 'PowerOnReset',
  51: 'FW Upgrade',
  52: 'FW Upgrade Fail',
  53: 'Reboot',
  54: 'Detection Diagnosis',
  55: 'Suspension mode Start',
  56: 'Suspension mode End',
  57: 'Motion Start',
  58: 'FW Compatibility Error',
  59: 'FW Upgrdae Failed Ident',
  60: 'FW Upgrdae Failed Checksum',
  70: 'Temp. Alarm',
  80: 'TransmissionTestPackage',
  81: 'Motion Stats Offload',
  300: 'Dose-App-Home',
  301: 'Dose-App-Notif',
  302: 'Dose-App-Home-At',
  310: 'Dose-App-History-At',
  320: 'Skipped',
  330: 'NotTaken',
  340: 'OnBreak',
  341: 'NotScheduled',
  342: 'NoReminder',
  343: 'NoMedication',
  400: 'BLE Restart on Notif',
};

// *** Firebase Performance parameters ***
export const PERF_TRACE_NAME = Object.freeze({
  fbFetchDataStatsTiming: 'fbFetchDataStatsTiming',
  fbFetchDataTiming: 'fbFetchDataTiming',
});

export const PERF_TRACE_VALUE = Object.freeze({
  docsCount: 'docsCount',
});

export const PERF_TRACE_PRAM_NAME = Object.freeze({
  queryCollection: 'queryCollection',
});

export const ANALYTICS_EVENT = Object.freeze({
  login: 'login',
  sign_up: 'sign_up',
  forgot_password: 'forgot_password',
  page_view_appId: 'page_view_appId',
  page_view_medicineId: 'page_view_medicineId',
  page_view_stats: 'page_view_stats',
  page_view_eventLog: 'page_view_eventLog',
  page_view_devices: 'page_view_devices',
  page_view_users: 'page_view_users',
  page_view_groups: 'page_view_groups',
  page_view_messages: 'page_view_messages',
  page_view_profile: 'page_view_profile',
  action_send_push_message: 'action_send_push_message',
  action_move_device_into_group: 'action_move_device_into_group',
});

export const EVENT_LOG_COLLECTION = '2024'; // NOTE: Limited only to this year collection
