export const startLocalEmulators = (
  connectFunctionsEmulator,
  functions,
  connectAuthEmulator,
  auth,
  connectFirestoreEmulator,
  db,
) => {
  //====== This is emulator setup for local env ========

  // Enable FUNCTIONS emulator based on .env.local file settings
  if (process.env.REACT_APP_USE_EMULATOR_FUNCTIONS === 'true') {
    console.log('Local emulator started: FUNCTIONS');
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }

  // Enable AUTH emulator based on .env.local file settings
  if (process.env.REACT_APP_USE_EMULATOR_AUTH === 'true') {
    console.log('Local emulator started: AUTH');
    connectAuthEmulator(auth, 'http://localhost:9099');
  }

  // Enable FIRESTORE emulator based on .env.local file settings
  if (process.env.REACT_APP_USE_EMULATOR_FIRESTORE === 'true') {
    console.log('Local emulator started: FIRESTORE');
    connectFirestoreEmulator(db, 'localhost', 8080);
  }
};
