// *** Export COMPANY / GROUPS data ***
export const companyPrepareData = (companies) => {
  return companies.reduce((all, doc) => {
    return [
      ...all,
      {
        companyName: doc?.name,
        groupName: '',
        companyId: doc?.id,
        groupId: '',
        users: '',
      },
      ...doc.groups.map((group) => {
        return {
          companyName: doc?.name,
          groupName: group?.name,
          companyId: group?.companyId || '-',
          groupId: group?.id,
          users: Object.keys(group?.users || {}).length,
        };
      }),
    ];
  }, []);
};

export const exportCompaniesCsv = (data, fileName) => {
  let csvContent = '';

  csvContent +=
    ['Company', 'Group', 'CompanyId', 'GroupId', 'Users'].join(';') + '\r\n';

  data.forEach((rowArray) => {
    csvContent +=
      [
        rowArray.companyName,
        rowArray.groupName,
        rowArray.companyId,
        rowArray.groupId,
        rowArray.users,
      ].join(';') + '\r\n';
  });

  downloadContent(fileName, csvContent);
};

// *** Export USERS data ***
export const exportUserCsv = (data, fileName) => {
  let csvContent = '';

  csvContent +=
    ['Name', 'Email', 'UID', 'Created', 'Active', 'Admin'].join(';') + '\r\n';

  data.forEach((rowArray) => {
    csvContent +=
      [
        rowArray.displayName,
        rowArray.email,
        rowArray.uid,
        rowArray.created,
        rowArray.enabled,
        rowArray.admin,
      ].join(';') + '\r\n';
  });

  downloadContent(fileName, csvContent);
};

// *** Export APP ID data ***
export const prepareDataForAppIds = (data) => {
  let toReturn = [];
  Object.entries(data).forEach(([, value]) => {
    toReturn.push(flattenObject(value));
  });
  return toReturn;
};

export const exportAppIdsCsv = (data, fileName) => {
  let csvContent = '';

  csvContent +=
    [
      'appId',
      'companyId',
      'companyName',
      'appUseStartDay',
      'configUpdateTimestamp',
      'uid',
      'dateOfBirth',
      'country',
      'gender',
      'phoneDeviceId',
      'mobileOs',
      'appVersion',
      'phoneDeviceType',
      'deviceId',
      'firmwareBuildDate',
      'detectionLevelValue',
      'manufacturingMonthYear',
      'start',
      'end',
      'firmwareVersion',
      'hwRevision',
    ].join(';') + '\r\n';

  data.forEach((rowArray) => {
    csvContent +=
      [
        rowArray?.appId,
        rowArray?.companyId,
        rowArray?.companyName,
        rowArray?.appUseStartDay,
        rowArray?.configUpdateTimestamp,
        rowArray?.uid,
        rowArray?.dateOfBirth,
        rowArray?.country,
        rowArray?.gender,
        rowArray?.phoneDeviceId,
        rowArray?.mobileOs,
        rowArray?.appVersion,
        rowArray?.phoneDeviceType,
        rowArray?.deviceId,
        rowArray?.firmwareBuildDate,
        rowArray?.detectionLevelValue,
        rowArray?.manufacturingMonthYear,
        rowArray?.start,
        rowArray?.end,
        rowArray?.firmwareVersion,
        rowArray?.hwRevision,
      ].join(';') + '\r\n';
  });

  downloadContent(fileName, csvContent);
};

// *** Export MEDICINE ID data ***
export const prepareDataForMedicineIds = (medicineIds) => {
  return medicineIds.map((a) => {
    let data = {
      appId: a.appId,
      companyId: a.companyId,
      companyName: a.companyName,
      groupId: a.groupId,
      groupIdName: a.groupName,
      day: a.day,
      uid: a.uid,
    };
    if (a.data) {
      data = {
        ...data,
        medicineNameUuid: a.data.medicineNameUuid,
        medicineGroupAtc: a.data.medicineGroupAtc,
        medicineUuid: a.data.medicineUuid,
        medicineName: a.data.medicineName,
        medicineBrand: a.data.medicineBrand,
        medicineValidationStatusVnr: a.data.medicineValidationStatusVnr,
        totalPills: a.data.totalPills,
        cycle: a.data.cycle,
        start: a.data.start,
        end: a.data.end || null,
        medicineIcd: a.data.medicineIcd,
      };
      if (a.data.breaks) {
        data = {
          ...data,
          breaks: arrayOfObjectsToString(a.data.breaks),
        };
      }
      if (a.data.reminders) {
        data = {
          ...data,
          reminders: arrayOfObjectsToString(a.data.reminders),
        };
      }
    }
    return data;
  });
};
export const exportMedicineIdsCsv = (data, fileName) => {
  let csvContent = '';

  csvContent +=
    [
      'appId',
      'medicineId',
      'companyId',
      'companyName',
      'groupId',
      'groupIdName',
      'day',
      'uid',
      'medicineNameUuid',
      'medicineGroupAtc',
      'medicineUuid',
      'medicineName',
      'medicineValidationStatusVnr',
      'totalPills',
      'cycle',
      'start',
      'end',
      'medicineIcd',
      'reminders',
    ].join(';') + '\r\n';

  data.forEach((rowArray) => {
    csvContent +=
      [
        rowArray?.appId,
        rowArray?.uid,
        rowArray?.companyId,
        rowArray?.companyName,
        rowArray?.groupId,
        rowArray?.groupIdName,
        rowArray?.day,
        rowArray?.medicineNameUuid,
        rowArray?.medicineGroupAtc,
        rowArray?.medicineUuid,
        rowArray?.medicineName,
        rowArray?.medicineValidationStatusVnr,
        rowArray?.totalPills,
        rowArray?.cycle,
        rowArray?.start,
        rowArray?.end,
        rowArray?.medicineIcd,
        rowArray?.reminders,
      ].join(';') + '\r\n';
  });

  downloadContent(fileName, csvContent);
};

// *** STATS EXPORTS ***

// *** Export STATS - USERS BY COMPANY data ***
export const exportStatsUsersByCompanyCsv = (data, fileName) => {
  let csvContent = '';

  csvContent +=
    [
      'companyId',
      'companyName',
      'appIds',
      'usersActive',
      'usersNew',
      'usersStopped',
      'usersUse14d',
      'usersUse30d',
      'usersUse60d',
      'usersUse90d',
      'viewsIntro',
      'viewsAppMsg',
      'viewsPrivacy',
      'viewsTerms',
      'viewsFaq',
    ].join(';') + '\r\n';

  data.forEach((rowArray) => {
    csvContent +=
      [
        rowArray?.companyId,
        rowArray?.companyName,
        rowArray?.appIds,
        rowArray?.usersActive,
        rowArray?.usersNew,
        rowArray?.usersStopped,
        rowArray?.usersUse14d,
        rowArray?.usersUse30d,
        rowArray?.usersUse60d,
        rowArray?.usersUse90d,
        rowArray?.viewsIntro,
        rowArray?.viewsAppMsg,
        rowArray?.viewsPrivacy,
        rowArray?.viewsTerms,
        rowArray?.viewsFaq,
      ].join(';') + '\r\n';
  });

  downloadContent(fileName, csvContent);
};

// *** Export STATS - MEDICINES BY COMPANY data ***
export const exportStatsMedicinesByCompanyCsv = (data, fileName) => {
  let csvContent = '';

  csvContent +=
    [
      'companyId',
      'companyName',
      'medicineId',
      'doseBefore',
      'doseAfter',
      'doseNotTaken',
      'doseNoReminder',
      'doseNotSheduled',
      'doseMultiple',
      'doseTotal',
      'remindersTotal',
      'remindersShow',
      'remindersPerDay',
      'remindersMorning',
      'remindersMidday',
      'remindersEvening',
      'remindersNight',
      'adherence',
      'adherenceScore',
    ].join(';') + '\r\n';

  data.forEach((rowArray) => {
    csvContent +=
      [
        rowArray?.companyId,
        rowArray?.companyName,
        rowArray?.medicineId,
        rowArray?.doseBefore,
        rowArray?.doseAfter,
        rowArray?.doseNotTaken,
        rowArray?.doseNoReminder,
        rowArray?.doseNotSheduled,
        rowArray?.doseMultiple,
        rowArray?.doseTotal,
        rowArray?.remindersTotal,
        rowArray?.remindersShow,
        rowArray?.remindersPerDay,
        rowArray?.remindersMorning,
        rowArray?.remindersMidday,
        rowArray?.remindersEvening,
        rowArray?.remindersNight,
        rowArray?.adherence,
        rowArray?.adherenceScore,
      ].join(';') + '\r\n';
  });

  downloadContent(fileName, csvContent);
};

// *** Export STATS USERS data ***
export const prepareDataForStatsUsers = (users) => {
  let toReturn = [];
  users.forEach((user) => {
    toReturn.push(flattenObject(user));
  });
  return toReturn;
};
export const exportStatsUsersCsv = (data, fileName) => {
  let csvContent = '';

  csvContent +=
    [
      'appId',
      'modifiedTimestamp',
      'rawdataId',
      'counterViewFaq',
      'counterViewPrivacyPolicy',
      'counterViewTermsOfUse',
      'pilotLockActivated',
      'phoneBTisON',
      'daysSinceLastUserAction',
      'counterViewPopitIntro',
      'timezone',
      'counterViewAppMessages',
      'useDaysApp',
      'day',
      'createdTimestamp',
      'companyId',
      'groupId',
      'uid',
      'companyName',
      'groupName',
    ].join(';') + '\r\n';

  data.forEach((rowArray) => {
    csvContent +=
      [
        rowArray?.appId,
        rowArray?.modifiedTimestamp,
        rowArray?.rawdataId,
        rowArray?.counterViewFaq,
        rowArray?.counterViewPrivacyPolicy,
        rowArray?.counterViewTermsOfUse,
        rowArray?.pilotLockActivated,
        rowArray?.phoneBTisON,
        rowArray?.daysSinceLastUserAction,
        rowArray?.counterViewPopitIntro,
        rowArray?.timezone,
        rowArray?.counterViewAppMessages,
        rowArray?.useDaysApp,
        rowArray?.day,
        rowArray?.createdTimestamp,
        rowArray?.companyId,
        rowArray?.groupId,
        rowArray?.uid,
        rowArray?.companyName,
        rowArray?.groupName,
      ].join(';') + '\r\n';
  });

  downloadContent(fileName, csvContent);
};

// *** Export STATS MEDICINES data ***
export const prepareDataForStatsMedicines = (medicines) => {
  let toReturn = [];
  medicines.forEach((med) => {
    toReturn.push(flattenObject(med));
  });
  return toReturn;
};
export const exportStatsMedicinesCsv = (data, fileName) => {
  let csvContent = '';

  csvContent +=
    [
      'medicineId',
      'medicineName',
      'day',
      'medicineIcd',
      'appId',
      'deviceId',
      'remainingDoses',
      'medicineUuid',
      'medicineStart',
      'useDaysMedicine',
      'daysSinceLastDose',
      'adherence',
      'streakLength',
      'streakLenghtMax',
      'scoreWeek',
      'daysSinceLastDoseAvg',
      'scoreDay',
      'streakLenghtAvg',
      'scoreMonth',
      'medicineDaysStatus',
      'lastDataUpdateApp',
      'lastDataUpdateDevice',
      'lastDataUpdateUser',
      'reminderData',
      'companyId',
      'modifiedTimestamp',
      'groupId',
      'createdTimestamp',
      'rawdataId',
      'uid',
      'companyName',
      'groupName',
      'doseBefore',
      'doseAfter',
      'doseNotTaken',
      'doseNoReminder',
      'doseNotSheduled',
      'doseMultiple',
      'doseTotal',
      'remindersTotal',
      'remindersShow',
      'remindersPerDay',
      'remindersMorning',
      'remindersMidday',
      'remindersEvening',
      'remindersNight',
      'adherenceScore',
    ].join(';') + '\r\n';

  data.forEach((rowArray) => {
    csvContent +=
      [
        rowArray?.medicineId,
        rowArray?.medicineName,
        rowArray?.day,
        rowArray?.medicineIcd,
        rowArray?.appId,
        rowArray?.deviceId,
        rowArray?.remainingDoses,
        rowArray?.medicineUuid,
        rowArray?.medicineStart,
        rowArray?.useDaysMedicine,
        rowArray?.daysSinceLastDose,
        rowArray?.adherence,
        rowArray?.streakLength,
        rowArray?.streakLenghtMax,
        rowArray?.scoreWeek,
        rowArray?.daysSinceLastDoseAvg,
        rowArray?.scoreDay,
        rowArray?.streakLenghtAvg,
        rowArray?.scoreMonth,
        rowArray?.medicineDaysStatus,
        rowArray?.lastDataUpdateApp,
        rowArray?.lastDataUpdateDevice,
        rowArray?.lastDataUpdateUser,
        rowArray?.reminderData,
        rowArray?.companyId,
        rowArray?.modifiedTimestamp,
        rowArray?.groupId,
        rowArray?.createdTimestamp,
        rowArray?.rawdataId,
        rowArray?.uid,
        rowArray?.companyName,
        rowArray?.groupName,
        rowArray?.doseBefore,
        rowArray?.doseAfter,
        rowArray?.doseNotTaken,
        rowArray?.doseNoReminder,
        rowArray?.doseNotSheduled,
        rowArray?.doseMultiple,
        rowArray?.doseTotal,
        rowArray?.remindersTotal,
        rowArray?.remindersShow,
        rowArray?.remindersPerDay,
        rowArray?.remindersMorning,
        rowArray?.remindersMidday,
        rowArray?.remindersEvening,
        rowArray?.remindersNight,
        rowArray?.adherenceScore,
      ].join(';') + '\r\n';
  });

  downloadContent(fileName, csvContent);
};

// *** Export STATS MEDICINE data ***
export const prepareDataForStatsMedicine = (data) => {
  let toReturn = [];
  Object.entries(data).forEach(([, value]) =>
    value.forEach((item) => {
      toReturn.push(flattenObject(item));
    }),
  );
  return toReturn;
};
export const exportStatsMedicineCsv = (data, fileName) => {
  let csvContent = '';

  csvContent +=
    [
      'groupId',
      'createdTimestamp',
      'medicineId',
      'day',
      'modifiedTimestamp',
      'rawdataId',
      'deviceId',
      'medicineIcd',
      'appId',
      'medicineDaysStatus',
      'streakLenghtMax',
      'scoreMonth',
      'daysSinceLastDoseAvg',
      'streakLenghtAvg',
      'scoreWeek',
      'streakLength',
      'adherence',
      'daysSinceLastDose',
      'scoreDay',
      'medicineUuid',
      'useDaysMedicine',
      'medicineStart',
      'remainingDoses',
      'lastDataUpdateDevice',
      'lastDataUpdateUser',
      'lastDataUpdateApp',
      'reminderData',
      'companyId',
      'medicineName',
      'uid',
      'companyName',
      'groupName',
    ].join(';') + '\r\n';

  data.forEach((rowArray) => {
    csvContent +=
      [
        rowArray?.groupId,
        rowArray?.createdTimestamp,
        rowArray?.medicineId,
        rowArray?.day,
        rowArray?.modifiedTimestamp,
        rowArray?.rawdataId,
        rowArray?.deviceId,
        rowArray?.medicineIcd,
        rowArray?.appId,
        rowArray?.medicineDaysStatus,
        rowArray?.streakLenghtMax,
        rowArray?.scoreMonth,
        rowArray?.daysSinceLastDoseAvg,
        rowArray?.streakLenghtAvg,
        rowArray?.scoreWeek,
        rowArray?.streakLength,
        rowArray?.adherence,
        rowArray?.daysSinceLastDose,
        rowArray?.scoreDay,
        rowArray?.medicineUuid,
        rowArray?.useDaysMedicine,
        rowArray?.medicineStart,
        rowArray?.remainingDoses,
        rowArray?.lastDataUpdateDevice,
        rowArray?.lastDataUpdateUser,
        rowArray?.lastDataUpdateApp,
        rowArray?.reminderData,
        rowArray?.companyId,
        rowArray?.medicineName,
        rowArray?.uid,
        rowArray?.companyName,
        rowArray?.groupName,
      ].join(';') + '\r\n';
  });

  downloadContent(fileName, csvContent);
};

// *** Export OLD PILL DATA data ***
export const oldDataPrepareData = (oldData) => {
  return oldData.map((item) => {
    if (item.reminderData) {
      let entries = Object.entries(item.reminderData);
      let reminderData = entries.map(([key, val]) => {
        let valObject = Object.entries(val);
        // const valData = valObject.map((key2, val2) => {
        //   return `${key2}: ${val2}`;
        // });
        return `Rem: ${key} ${valObject}`;
      });
      return {
        ...item,
        reminderData: reminderData,
      };
    } else {
      return {
        ...item,
        reminderData: '',
      };
    }
  });
};

export const exportDataOldCsv = (data, fileName) => {
  let csvContent = '';

  let itemKeys = Object.keys(data[0]);
  itemKeys = itemKeys.sort();
  csvContent += itemKeys.join(';') + '\r\n';

  data.forEach((rowArray) => {
    let rowItem = [];
    itemKeys.forEach((itemKey) => {
      rowItem.push(rowArray[itemKey]);
    });
    csvContent += rowItem.join(';') + '\r\n';
  });

  downloadContent(fileName, csvContent);
};

// *** HELPER functions ***

const arrayOfObjectsToString = (arrOfObj) => {
  let resultString = '';
  let objNumber = 1;
  arrOfObj.forEach((item) => {
    resultString += 'ITEM-' + objNumber.toString() + ':';
    resultString += Object.entries(item).reduce((str, [p, val]) => {
      let tempVal = val;
      // if nested object
      if (typeof val === 'object' && val !== null) {
        tempVal = Object.entries(val).reduce((str1, [p1, val1]) => {
          return `${str1}${p1}:${val1}::`;
        }, '');
      }
      return `${str}${p}:${tempVal}::`;
    }, '');
    objNumber++;
  });
  return resultString;
};

const flattenObject = (ob) => {
  let toReturn = {};
  for (var i in ob) {
    if (!Object.prototype.hasOwnProperty.call(ob, i)) continue;

    if (typeof ob[i] == 'object' && ob[i] !== null) {
      // Custom handler for reminderData
      if (i === 'reminderData') {
        toReturn[i] = arrayOfObjectsToString([ob[i]]);
      } else {
        var flatObject = flattenObject(ob[i]);
        for (var x in flatObject) {
          if (!Object.prototype.hasOwnProperty.call(flatObject, x)) continue;
          toReturn[x] = flatObject[x];
        }
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

// const encodeData = (data) => {
//   return encodeURI(data)
//     .replace(/-/g, '%2D')
//     .replace(/_/g, '%5F')
//     .replace(/\./g, '%2E')
//     .replace(/!/g, '%21')
//     .replace(/~/g, '%7E')
//     .replace(/\*/g, '%2A')
//     .replace(/'/g, '%27')
//     .replace(/\(/g, '%28')
//     .replace(/\)/g, '%29')
//     .replace(/#/g, '%23');
// };

const downloadContent = (fileName, content) => {
  var universalBOM = '\uFEFF';
  const downloadLink = document.createElement('a');
  downloadLink.href =
    'data:text/csv; charset=utf-8,' +
    encodeURIComponent(universalBOM + content);
  downloadLink.download = fileName;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
