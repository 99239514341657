import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function CellEventLogAppId({ appId }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/event-log/${appId}`);
  };
  return (
    <div className='cell-stats-medicineId' onClick={handleClick}>
      {appId}
    </div>
  );
}

CellEventLogAppId.propTypes = {
  appId: PropTypes.number.isRequired,
};

export default CellEventLogAppId;
