import {
  createStatsFetchMethod,
  sortByKey,
  setCompanyGroupNames,
} from './stats.service';

const COLLECTION = 'medstatus';
const DEFAULT_FIELD = 'medicineId';
const DEFAULT_FREE_FILTER_FIELD = 'appId';
const FREE_FILTER_QUERY = 'in';
const DEFAULT_DAY_FIELD = 'day';
const DEFAULT_SORT_DIRECTION = 'asc';
export const MAX_COUNT = 10000;
const AUTO_LOAD = false;

export const getStatsMedicine = createStatsFetchMethod(
  COLLECTION,
  DEFAULT_FIELD,
  DEFAULT_FREE_FILTER_FIELD,
  FREE_FILTER_QUERY,
  DEFAULT_DAY_FIELD,
  DEFAULT_SORT_DIRECTION,
  MAX_COUNT,
  AUTO_LOAD,
);

export const statsMedicineDataModifierFunc = (fetchedData, companies) => {
  const incorrectCompanyId = 'incorrect companiID';

  let results = {
    companyMedicines: [],
    medicines: [],
    medicine: [],
  };

  // return if data not available
  if (!fetchedData || !companies || companies.length < 1) {
    return results;
  }

  // create medicine data per MedicineID
  const groupByMedicineId = fetchedData.reduce((group, item) => {
    const { medicineId } = item;
    group[medicineId] = group[medicineId] ?? [];
    group[medicineId].push(setCompanyGroupNames(item, companies));
    return group;
  }, {});

  results = {
    ...results,
    medicine: groupByMedicineId,
  };

  // create medicines data - lastest status
  const newestDataPerMedicine = [];
  Object.entries(groupByMedicineId).forEach(([key, value]) => {
    const _medicineId = Number(key);
    const _data = setCompanyGroupNames(value[0] || {}, companies);
    const dataForThisMed = {};
    dataForThisMed[_medicineId] = groupByMedicineId[_medicineId];
    const _medStats = getStatsMedicineForOneCompany(dataForThisMed);
    newestDataPerMedicine.push({
      medicineId: _medicineId,
      data: _data,
      medStats: _medStats,
    });
  });

  results = {
    ...results,
    medicines: sortByKey(newestDataPerMedicine, 'medicineId', 'asc'),
  };

  const groupByCompanyId = fetchedData.reduce((group, item) => {
    const { companyId } = item;
    group[companyId] = group[companyId] ?? [];
    group[companyId].push(item);
    return group;
  }, {});

  const dataStatsMedicinesTemp = [];
  Object.entries(groupByCompanyId).forEach(([key, value]) =>
    dataStatsMedicinesTemp.push({
      companyId: key,
      data: value.reduce((group, item) => {
        const { medicineId } = item;
        group[medicineId] = group[medicineId] ?? [];
        group[medicineId].push(item);
        return group;
      }, {}),
    }),
  );

  const dataStatsMedicine = [];
  dataStatsMedicinesTemp.forEach((item) => {
    dataStatsMedicine.push({
      companyId: item?.companyId || incorrectCompanyId,
      companyName:
        companies.find((x) => x.id === item?.companyId)?.name ||
        item?.companyId ||
        incorrectCompanyId,
      ...getStatsMedicineForOneCompany(item.data),
    });
  });

  const companyMedicines = sortByKey(dataStatsMedicine, 'companyName', 'asc');
  results = {
    ...results,
    companyMedicines: companyMedicines,
  };

  return results;
};

const getStatsMedicineForOneCompany = (data) => {
  // Reminder time limits to split taking times
  const dayTime1 = 180;
  const dayTime2 = 540;
  const dayTime3 = 900;
  const dayTime4 = 1260;

  let medicineIdCount = 0;
  let doseBefore = 0;
  let doseAfter = 0;
  let doseNotTaken = 0;
  let doseNoReminder = 0;
  let doseNotSheduled = 0;
  let doseMultiple = 0;
  let daysTotal = 0;
  let remindersTotal = 0;
  let remindersMorning = 0;
  let remindersMidday = 0;
  let remindersEvening = 0;
  let remindersNight = 0;
  let adherenceSum = 0;
  let adherenceCount = 0;
  let adherenceScoreSum = 0;
  let adherenceScoreCount = 0;

  medicineIdCount = Object.keys(data).length;

  let statsResultsTemp = [];
  Object.entries(data).forEach(([, value]) =>
    statsResultsTemp.push({
      useDaysApp: value[value.length - 1].data.useDaysApp,
      lastStatusDay: value[value.length - 1].day,
      firstStatusDay: value[0].day,
    }),
  );

  Object.entries(data).forEach(([, value]) => {
    value.forEach((item) => {
      // Dose
      Object.entries(item.data.reminderData).forEach(([key, value]) => {
        if (value.status <= 0) {
          doseNotTaken += value.dose;
        } else if (value.status < 10) {
          doseBefore += value.dose;
        } else if (value.status === 10) {
          doseAfter += value.dose;
        } else if (value.status === 11) {
          doseAfter += value.dose;
        } else if (value.status === 15) {
          doseAfter += value.dose;
        } else if (value.status === 19) {
          doseMultiple += value.dose;
        } else if (value.status === 20) {
          doseNotSheduled += value.dose;
        } else if (value.status === 21) {
          doseNotTaken += value.dose;
        } else if (value.status === 22) {
          doseNotSheduled += value.dose;
        } else if (value.status === 23) {
          doseNotSheduled += value.dose;
        } else if (value.status === 24) {
          doseNoReminder += value.dose;
        }
        if (Number(key) > dayTime4 || Number(key) < dayTime1) {
          remindersNight++;
        } else if (Number(key) > dayTime3) {
          remindersEvening++;
        } else if (Number(key) > dayTime2) {
          remindersMidday++;
        } else {
          remindersMorning++;
        }
      });
      // Reminders
      remindersTotal += Object.keys(item.data.reminderData).length;
      daysTotal++;
      // Adherence
      // NOTE: Only 0 and greater values counted
      if (item.data.scoreData.adherence >= 0) {
        adherenceSum += item.data.scoreData.adherence;
        adherenceCount++;
      }
      if (item.data.scoreData.scoreDay >= 0) {
        adherenceScoreSum += item.data.scoreData.scoreDay;
        adherenceScoreCount++;
      }
    });
  });

  return {
    medicineIdCount: medicineIdCount,
    doseBefore: doseBefore,
    doseAfter: doseAfter,
    doseNotTaken: doseNotTaken,
    doseNoReminder: doseNoReminder,
    doseNotSheduled: doseNotSheduled,
    doseMultiple: doseMultiple,
    doseTotal:
      doseBefore + doseAfter + doseNoReminder + doseNotSheduled + doseMultiple,
    remindersTotal: remindersTotal,
    remindersShow: remindersTotal - doseBefore,
    remindersPerDay: Number(
      (Math.round((remindersTotal / daysTotal) * 100) / 100).toFixed(2),
    ),
    remindersMorning: remindersMorning,
    remindersMidday: remindersMidday,
    remindersEvening: remindersEvening,
    remindersNight: remindersNight,
    adherence: Number(
      (Math.round((adherenceSum / adherenceCount) * 100) / 100).toFixed(0),
    ),
    adherenceScore: Number(
      (
        Math.round((adherenceScoreSum / adherenceScoreCount) * 100) / 100
      ).toFixed(0),
    ),
  };
};
