import { useAuthContext } from '../hooks/useAuthContext';
import UserForm from '../components/users/UserForm';

import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';

function Account() {
  logEvent(analytics, ANALYTICS_EVENT.page_view_profile);

  const { user } = useAuthContext();

  return <UserForm user={user} />;
}

export default Account;
