import { useState, useEffect } from 'react';
import Alert from '../Alert';
import Modal from '../Modal';
import Spinner from '../Spinner';
import GroupsListSelect from '../GroupsListSelect';
import PropTypes from 'prop-types';

function UserGroupEditModal({
  user,
  userGroupEditModalIsOpen,
  setUserGroupEditModalIsOpen,
  companies,
}) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [actionResult, setActionResult] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const title = `Select groups for ${user.displayName}`;
  let description = 'User will have access only selected groups.';

  // TODO: Check this after user custom claims updated
  if (user?.admin) {
    description =
      "Admin users can't have group configuration. Admin users have access to all groups.";
  }

  const handleClick = () => {
    setIsUpdating(true);
    setIsUpdating(false);

    if (actionResult?.result) {
      setUserGroupEditModalIsOpen(false);
    }
    alert('TODO: Database updates not done yet');
  };

  const reset = () => {
    setActionResult(null);
    setUserGroupEditModalIsOpen(false);
  };

  const addSelectedGroup = (_selectedGroup) => {
    setSelectedGroups((prevState) => [...prevState, _selectedGroup]);
  };
  const removeSelectedGroup = (_selectedGroup) => {
    setSelectedGroups((prevState) => {
      return prevState.map((item) => {
        if (item.groupId !== _selectedGroup.groupId) {
          return item;
        } else {
          return null;
        }
      });
    });
  };

  useEffect(() => {
    // TODO: check what groups are enabled for the user and set to selectedGroups
    console.log('TODO: update user groups');
  }, [selectedGroups]);

  return (
    <Modal isOpen={userGroupEditModalIsOpen}>
      <div className='card-m'>
        <div className='card-body-m'>
          <h3 className='card-title'>{title}</h3>
          <hr />
          <p>{description}</p>

          {isUpdating && <Spinner isSpinning={isUpdating} />}
          {actionResult != null && (
            <div className='mtb-1'>
              <Alert
                type='error'
                title={actionResult.title}
                content={actionResult.content}
              />
            </div>
          )}
          {!user?.admin && (
            <GroupsListSelect
              companies={companies}
              selectedGroups={selectedGroups}
              addSelectedGroup={addSelectedGroup}
              removeSelectedGroup={removeSelectedGroup}
            />
          )}
          <div className='row mt-2'>
            <div className='col-12 c-group u-h-evenly'>
              <button
                className={`btn is-secondary`}
                type='button'
                disabled={isUpdating}
                onClick={reset}
              >
                Cancel
              </button>
              <button
                className={`btn`}
                type='button'
                disabled={user?.admin}
                onClick={handleClick}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

UserGroupEditModal.propTypes = {
  user: PropTypes.object.isRequired,
  userGroupEditModalIsOpen: PropTypes.bool.isRequired,
  setUserGroupEditModalIsOpen: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
};

export default UserGroupEditModal;
