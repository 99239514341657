import { useState, useEffect } from 'react';
import useAppDataContext from '../hooks/useAppDataContext';
import { useServiceDataSource } from '../hooks/useServiceDataSource';
import { getUsers } from '../services/users.service';
import { exportUserCsv } from '../services/file.service';
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import Table from '../components/table/Table';
import Spinner from '../components/Spinner';
import TablePagination, {
  getPageLastNumber,
} from '../components/table/TablePagination';
import tableHeaderUsers from '../components/table/tableHeaderUsers';
import { FaDownload, FaRedo } from 'react-icons/fa';
import UserEditModal from '../components/users/UserEditModal';
import UserEnableAccountModal from '../components/users/UserEnableAccountModal';
import UserGroupEditModal from '../components/users/UserGroupEditModal';

import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';

function Users() {
  logEvent(analytics, ANALYTICS_EVENT.page_view_users);

  const {
    data,
    isLoading,
    pageSize,
    totalDocs,
    loadMoreData,
    reloadData,
    updatePageSize,
  } = useServiceDataSource(
    getUsers.getData,
    getUsers.getQuery,
    getUsers.getDocsCount,
    getUsers.defaultFilterObject,
    null,
    getUsers.autoload,
  );
  const { companies } = useAppDataContext();
  const [selectedUser, setSelectedUser] = useState(null);
  const [pageLastNumber, setPageLastNumber] = useState(0);
  const [showNumberOfDocs, setShowNumberOfDocs] = useState(10);
  const [userEditModalIsOpen, setUserEditModalIsOpen] = useState(false);
  const [userGroupEditModalIsOpen, setUserGroupEditModalIsOpen] =
    useState(false);
  const [userEnableAccountModalIsOpen, setUserEnableAccountModalIsOpen] =
    useState(false);

  const handleUserEdit = (user) => {
    setSelectedUser(user);
    setUserEditModalIsOpen(true);
  };
  const handleUserGroupEdit = (user) => {
    setSelectedUser(user);
    setUserGroupEditModalIsOpen(true);
  };
  const handleUserEnable = (user) => {
    setSelectedUser(user);
    setUserEnableAccountModalIsOpen(true);
  };

  //====== Table
  // Table sorting
  const [sorting, setSorting] = useState([]);
  // Table - create columns
  const columns = tableHeaderUsers(
    handleUserEdit,
    handleUserEnable,
    handleUserGroupEdit,
  );

  // Table - create table content
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
  });

  const exportData = () => {
    exportUserCsv(data, 'data');
  };

  const isLoadMoreDataEnabled = () => {
    return totalDocs === data.length ? false : true;
  };

  const closeModalUserEdit = (reloadDataNeeded) => {
    if (reloadDataNeeded === true) {
      reloadData();
    }
    setUserEditModalIsOpen(false);
  };
  const closeModalUserEnabledEdit = (reloadDataNeeded) => {
    if (reloadDataNeeded === true) {
      reloadData();
    }
    setUserEnableAccountModalIsOpen(false);
  };

  useEffect(() => {
    const _pageLastNumber = getPageLastNumber(totalDocs, pageSize);
    setPageLastNumber(_pageLastNumber);
  }, [totalDocs, pageSize]);

  const showMore = async () => {
    await loadMoreData();
    setShowNumberOfDocs((prevState) => {
      if (prevState >= totalDocs) {
        return totalDocs;
      } else if (prevState + pageSize > totalDocs) {
        return totalDocs;
      } else {
        return prevState + pageSize;
      }
    });
  };

  return (
    <div className='table-container'>
      {userEditModalIsOpen && (
        <UserEditModal
          user={selectedUser}
          userEditModalIsOpen={userEditModalIsOpen}
          closeModalUserEdit={closeModalUserEdit}
        />
      )}
      {userGroupEditModalIsOpen && (
        <UserGroupEditModal
          user={selectedUser}
          userGroupEditModalIsOpen={userGroupEditModalIsOpen}
          setUserGroupEditModalIsOpen={setUserGroupEditModalIsOpen}
          companies={companies}
        />
      )}
      {userEnableAccountModalIsOpen && (
        <UserEnableAccountModal
          user={selectedUser}
          userEnableAccountModalIsOpen={userEnableAccountModalIsOpen}
          closeModalUserEnabledEdit={closeModalUserEnabledEdit}
        />
      )}

      <div className='table-menu-header'>
        <div>
          <h3>Users</h3>
          <p>{totalDocs} users</p>
        </div>
        <div className='table-menu-header-actions'>
          <div>
            <button
              type='button'
              className={`btn-icon`}
              title='Download user data'
              onClick={exportData}
            >
              <FaDownload />
            </button>
          </div>
          <div>
            <button
              type='button'
              className={`btn-icon`}
              title='Reload data'
              onClick={() => {
                reloadData();
                console.log('refresh click');
              }}
            >
              <FaRedo />
            </button>
          </div>
        </div>
      </div>

      <Spinner isSpinning={isLoading}></Spinner>
      {!isLoading && (
        <>
          <Table table={table} />

          <TablePagination
            pageCurrentNumber={Math.ceil(showNumberOfDocs / pageSize)}
            pageLastNumber={pageLastNumber}
            pageSize={pageSize}
            totalNumberOfDocs={totalDocs}
            changePageSize={(newSize) => {
              updatePageSize(newSize);
            }}
            loadMoreDataEnabled={isLoadMoreDataEnabled()}
            loadMoreData={showMore}
          />
        </>
      )}
    </div>
  );
}

export default Users;
