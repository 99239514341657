import { createDataFecthMethod } from './data.service';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../app/config';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { updateProfile } from 'firebase/auth';
import { auth } from '../app/config';
import { FirebaseError, ValidationError } from '../utils/errors';

const COLLECTION = 'users';
const DEFAULT_FIELD = 'displayName';
const DEFAULT_FREE_FILTER_FIELD = 'displayName';
const DEFAULT_FREE_FILTER_FIELD_TYPE = 'string';
const FREE_FILTER_QUERY = '==';
const DEFAULT_DAY_FIELD = 'created';
const DEFAULT_SORT_DIRECTION = 'asc';
const MAX_COUNT = 100;
const AUTO_LOAD = true;

export const getUsers = createDataFecthMethod(
  COLLECTION,
  DEFAULT_FIELD,
  DEFAULT_FREE_FILTER_FIELD,
  DEFAULT_FREE_FILTER_FIELD_TYPE,
  FREE_FILTER_QUERY,
  DEFAULT_DAY_FIELD,
  DEFAULT_SORT_DIRECTION,
  MAX_COUNT,
  AUTO_LOAD,
);
export const getUsersAll = async () => {
  // Get users
  const collectionRef = collection(db, COLLECTION);
  const docsQuery = query(collectionRef);
  const snapshotDocs = await getDocs(docsQuery);
  const allUsers = snapshotDocs.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });

  return allUsers;
};

// export const updateUserGroups = async (user, groups) => {
// TODO: implement function after DB updates
// and when company roles implemented
// };

export const setUserAdmin = async (userId, enabled) => {
  if (userId === undefined || userId === null || userId?.length === 0) {
    throw new ValidationError(
      'Data validation error: setUserAdmin',
      'userId is empty',
    );
  }

  const adminEnabled = enabled || false;

  const userSetCustomClaims = httpsCallable(
    getFunctions(),
    'userSetCustomClaims',
  );

  try {
    const updateResult = await userSetCustomClaims({
      userUid: userId,
      claims: { admin: adminEnabled },
    });

    if (updateResult?.data?.success) {
      return;
    } else {
      throw new FirebaseError(
        'Firebase error: setUserAdmin',
        updateResult?.data?.message,
      );
    }
  } catch (error) {
    throw new FirebaseError(
      'Firebase error: setUserAdmin',
      `Firebase error: ${error?.message}`,
    );
  }
};

export const setUserEnabled = async (userId, enabled) => {
  const userSetAccountEnabled = httpsCallable(
    getFunctions(),
    'userSetAccountEnabled',
  );
  try {
    const updateResult = await userSetAccountEnabled({
      userUid: userId,
      enabled: enabled,
    });

    if (updateResult?.data?.success) {
      return;
    } else {
      throw new FirebaseError(
        'Firebase error: userSetAccountEnabled',
        updateResult?.data?.message,
      );
    }
  } catch (error) {
    throw new FirebaseError(
      'Firebase error: userSetAccountEnabled',
      `Firebase error: ${error?.message}`,
    );
  }
};

export const updateCurrentUserDisplayName = async (displayName) => {
  try {
    await updateProfile(auth.currentUser, { displayName: displayName });
    return;
  } catch (error) {
    throw new FirebaseError(
      'Firebase error: updateProfile',
      `Firebase error: ${error?.message}`,
    );
  }
};
