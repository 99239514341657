import { useState, useEffect } from 'react';
import useAppDataContext from '../hooks/useAppDataContext';
import {
  companyPrepareData,
  exportCompaniesCsv,
} from '../services/file.service';
import Table from '../components/table/Table';
import Spinner from '../components/Spinner';
import TablePagination, {
  getPageLastNumber,
} from '../components/table/TablePagination';
import tableHeaderGroups from '../components/table/tableHeaderGroups';
import { FaDownload, FaPlus } from 'react-icons/fa';
import {
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import { ACTION_OBJECT, GROUP_ACTIONS } from '../services/company.service';
import GroupActionModal from '../components/companies/GroupActionModal';

import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';

function Groups() {
  logEvent(analytics, ANALYTICS_EVENT.page_view_groups);

  const { isLoading, companies, updateCompanies } = useAppDataContext();

  const [totalDocs, setTotalDocs] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageLastNumber, setPageLastNumber] = useState(0);
  const [showNumberOfCompanies, setShowNumberOfCompanies] = useState(10);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setShowNumberOfCompanies(pageSize);
  }, [pageSize]);

  useEffect(() => {
    const numberOfCompanies = Object.keys(companies).length;
    setTotalDocs(numberOfCompanies);

    const _pageLastNumber = getPageLastNumber(numberOfCompanies, pageSize);
    setPageLastNumber(_pageLastNumber);
    const _data = companies.slice(0, showNumberOfCompanies);
    setTableData(_data);
  }, [companies, pageSize, showNumberOfCompanies]);

  const showMore = () => {
    setShowNumberOfCompanies((prevState) => {
      if (prevState >= totalDocs) {
        return totalDocs;
      } else if (prevState + pageSize > totalDocs) {
        return totalDocs;
      } else {
        return prevState + pageSize;
      }
    });
  };

  const exportData = () => {
    const preparadData = companyPrepareData(companies);
    exportCompaniesCsv(preparadData, 'data');
  };

  const [modalData, setModalData] = useState({
    isOpen: false,
    title: 'title 1',
    description: 'some text',
    actionObject: { ...ACTION_OBJECT },
  });

  //====== Table
  // Table sorting
  const [sorting, setSorting] = useState([]);
  // Table - create columns
  const columns = tableHeaderGroups(setModalData);
  // Table expandable row
  const getRowCanExpand = () => true;
  const [expanded, setExpanded] = useState({});

  // Table - create table content
  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      expanded,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.groups,
    // debugTable: true,
  });

  const createNewCompany = () => {
    setModalData((prevState) => ({
      ...prevState,
      isOpen: true,
      title: 'Create new company',
      description:
        'Write the name for the new company.\nName must be more than 3 characters.',
      actionObject: {
        ...prevState.actionObject,
        action: GROUP_ACTIONS.createCompany,
      },
    }));
  };

  const isLoadMoreDataEnabled = () => {
    return showNumberOfCompanies < totalDocs;
  };

  return (
    <div className='table-container'>
      {modalData.isOpen && (
        <GroupActionModal
          modalData={modalData}
          setModalData={setModalData}
          updateCompanies={updateCompanies}
        />
      )}

      <div className='table-menu-header'>
        <div>
          <h3>Groups</h3>
          <p>{totalDocs} companies</p>
        </div>
        <div className='table-menu-header-actions'>
          <div>
            <button
              type='button'
              className={`btn-icon`}
              title='Download company data'
              onClick={exportData}
            >
              <FaDownload />
            </button>
          </div>
          <div>
            <button
              type='button'
              className={`btn-icon`}
              title='Create new company'
              onClick={createNewCompany}
            >
              <FaPlus />
            </button>
          </div>
        </div>
      </div>

      <Spinner isSpinning={isLoading}></Spinner>
      {!isLoading && (
        <>
          <Table table={table} />

          <TablePagination
            pageCurrentNumber={Math.ceil(showNumberOfCompanies / pageSize)}
            pageLastNumber={pageLastNumber}
            pageSize={pageSize}
            totalNumberOfDocs={totalDocs}
            changePageSize={(newSize) => {
              setPageSize(newSize);
            }}
            loadMoreDataEnabled={isLoadMoreDataEnabled()}
            loadMoreData={showMore}
          />
        </>
      )}
    </div>
  );
}

export default Groups;
