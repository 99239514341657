import { useState, useEffect } from 'react';
import useAppDataContext from '../hooks/useAppDataContext';
import { useServiceDataSource } from './useServiceDataSource';
import {
  getStatsApp,
  statsAppDataModifierFunc,
  MAX_COUNT as maxCountApp,
} from '../services/stats.app.service';
import {
  getStatsMedicine,
  statsMedicineDataModifierFunc,
  MAX_COUNT as maxCountMedicine,
} from '../services/stats.medicine.service';

const filterObjectMod = {
  freeFilter: '',
  selectedCompanies: [],
  selectedGroups: [],
  dateStart: '',
  dateEnd: '',
};

export function useStatsDataSource() {
  const { companies } = useAppDataContext();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dataStatsCompanyUsers, setDataStatsCompanyUsers] = useState([]);
  const [dataStatsCompanyMedicines, setDataStatsCompanyMedicines] = useState(
    [],
  );
  const [dataStatsUsers, setDataStatsUsers] = useState([]);
  const [dataStatsMedicines, setDataStatsMedicines] = useState([]);
  const [dataStatsUser, setDataStatsUser] = useState([]);
  const [dataStatsMedicine, setDataStatsMedicine] = useState([]);
  const [statsFilterObject, setStatsFilterObject] = useState(filterObjectMod);

  const {
    error: errorStatsApp,
    data: dataStatsApp,
    isLoading: isLoadingApp,
    reloadData: reloadDataApp,
    updateFilterObject: updateFilterObjectApp,
    updatePageSize: updatePageSizeApp,
  } = useServiceDataSource(
    getStatsApp.getData,
    getStatsApp.getQuery,
    getStatsApp.getDocsCount,
    getStatsApp.defaultFilterObject,
    null,
    getStatsApp.autoload,
  );

  const {
    error: errorStatsMedicine,
    data: dataStatsMed,
    isLoading: isLoadingMedicine,
    reloadData: reloadDataMedicine,
    updatePageSize: updatePageSizeMedicine,
    updateFilterObject: updateFilterObjectMedicine,
  } = useServiceDataSource(
    getStatsMedicine.getData,
    getStatsMedicine.getQuery,
    getStatsMedicine.getDocsCount,
    getStatsMedicine.defaultFilterObject,
    null,
    getStatsMedicine.autoload,
  );

  const getDataUsers = async () => {
    setError(null);
    await reloadDataApp();
  };
  const getDataMedicines = async () => {
    await reloadDataMedicine();
  };

  useEffect(() => {
    if (errorStatsApp !== null) {
      setError(errorStatsApp);
    } else if (errorStatsMedicine !== null) {
      setError(errorStatsMedicine);
    } else {
      setError(null);
    }
  }, [errorStatsApp, errorStatsMedicine]);

  useEffect(() => {
    const dataAppResults = statsAppDataModifierFunc(dataStatsApp, companies);
    setDataStatsCompanyUsers(dataAppResults.companyUsers);
    setDataStatsUsers(dataAppResults.users);
    setDataStatsUser(dataAppResults.user);
  }, [dataStatsApp, companies]);

  useEffect(() => {
    const dataMedResults = statsMedicineDataModifierFunc(
      dataStatsMed,
      companies,
    );
    setDataStatsCompanyMedicines(dataMedResults.companyMedicines);
    setDataStatsMedicines(dataMedResults.medicines);
    setDataStatsMedicine(dataMedResults.medicine);
  }, [dataStatsMed, companies]);

  useEffect(() => {
    setIsLoading(isLoadingApp === true || isLoadingMedicine === true);
  }, [isLoadingApp, isLoadingMedicine]);

  // Update App ID & Medicine ID filter objects when common updated
  useEffect(() => {
    // PageSize set to maxCount so that in stats page fetch result
    // gets all results
    updatePageSizeApp(maxCountApp);
    updatePageSizeMedicine(maxCountMedicine);
    updateFilterObjectApp((prevState) => ({
      ...prevState,
      selectedCompanies: statsFilterObject.selectedCompanies,
      selectedGroups: statsFilterObject.selectedGroups,
      dateStart: statsFilterObject.dateStart,
      dateEnd: statsFilterObject.dateEnd,
      freeFilter: statsFilterObject.freeFilter,
    }));
    updateFilterObjectMedicine((prevState) => ({
      ...prevState,
      selectedCompanies: statsFilterObject.selectedCompanies,
      selectedGroups: statsFilterObject.selectedGroups,
      dateStart: statsFilterObject.dateStart,
      dateEnd: statsFilterObject.dateEnd,
      freeFilter: statsFilterObject.freeFilter,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsFilterObject]);

  return {
    error,
    isLoading,
    statsFilterObject,
    dataStatsCompanyUsers,
    dataStatsCompanyMedicines,
    dataStatsUsers,
    dataStatsMedicines,
    dataStatsUser,
    dataStatsMedicine,
    getDataUsers,
    getDataMedicines,
    setStatsFilterObject,
  };
}
