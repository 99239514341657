import PropTypes from 'prop-types';
import { CLOUD_SERVER_OPTIONS } from '../../../shared/Constants';

function CellCloudServer({ cloudServer, setNewCloudServer }) {
  return (
    <select
      className='input'
      id='selectCloudServer'
      name='selectCloudServer'
      value={cloudServer}
      onChange={(e) => {
        const newCloudServer = e.target.value;
        setNewCloudServer(newCloudServer);
      }}
    >
      {CLOUD_SERVER_OPTIONS.map((option, index) => (
        <option value={option.id} key={`cloud-server-option-${index}`}>
          {option}
        </option>
      ))}
    </select>
  );
}

CellCloudServer.propTypes = {
  cloudServer: PropTypes.string.isRequired,
  setNewCloudServer: PropTypes.func.isRequired,
};

export default CellCloudServer;
