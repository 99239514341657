import Router from './Router';
import { AuthContextProvider } from './app/AuthContext';
import { ThemeContextProvider } from './app/ThemeContext';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <ThemeContextProvider>
      <AuthContextProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </AuthContextProvider>
    </ThemeContextProvider>
  );
}

export default App;
