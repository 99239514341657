import { useEffect, useState } from 'react';
import { useThemeContext } from '../../hooks/useThemeContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import Spinner from '../Spinner';
import Alert from '../Alert';
import { MdEmail } from 'react-icons/md';
import { FaUser } from 'react-icons/fa';
import { updateData } from '../../services/data.utils';
import {
  setUserAdmin,
  updateCurrentUserDisplayName,
} from '../../services/users.service';
import PropTypes from 'prop-types';

function UserForm({ user, closeModalUserEdit }) {
  const [userNotChanged, setUserNotChanged] = useState(false);
  const [userDetailsEdited, setUserDetailsEdited] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [newDisplayName, setNewDisplayName] = useState('');
  const [newTheme, setNewTheme] = useState('light');
  const [someSetting, setSomeSetting] = useState(false);
  const [newPopitAdmin, setNewPopitAdmin] = useState(false);
  const [error, setError] = useState(null);

  const { switchTheme } = useThemeContext();
  const { user: loginUser } = useAuthContext();

  const reset = () => {
    if (newTheme !== user.theme) {
      updateTheme();
    }
    // reset changes
    setUserDetailsEdited(false);
    setNewTheme(user.theme);
    setNewDisplayName(user.displayName);

    if (closeModalUserEdit) {
      closeModalUserEdit(false);
    }
  };

  const updateTheme = () => {
    // update current context theme ONLY if logied user is editing own profile
    if (user.email === loginUser.email) {
      switchTheme();
    }
  };

  const saveChanges = async () => {
    setError(null);
    setIsUpdating(true);
    setUserDetailsEdited(true);
    const newData = {
      displayName: newDisplayName === '' ? user.displayName : newDisplayName,
      theme: newTheme,
    };
    // Get uid either from user or currentuser object
    let userUid = '';
    if (user.email === loginUser.email) {
      userUid = loginUser.userRawData.uid;
    } else {
      userUid = user.uid;
    }
    try {
      await updateData('users', userUid, newData);
    } catch (error) {
      setError(error);
    }

    // Update user auth if modifien own data
    if (user.email === loginUser.email && newDisplayName !== '') {
      try {
        await updateCurrentUserDisplayName(newDisplayName);
      } catch (error) {
        setError(error);
      }
    }

    // TODO: Get new user data when updated

    // TODO: Add Alert box to view

    setIsUpdating(false);

    if (closeModalUserEdit) {
      closeModalUserEdit(true);
    }
  };

  useEffect(() => {
    // set original values to edited user when page loaded
    setNewDisplayName(user?.displayName || '');
    setNewTheme(user?.theme || 'light');
  }, [user]);

  useEffect(() => {
    setUserNotChanged(
      newDisplayName === user.displayName && newTheme === user.theme,
    );
  }, [newDisplayName, newTheme, user, setUserNotChanged]);

  const handleChange = (e) => {
    switch (e.target.id) {
      case 'displayName':
        setNewDisplayName(e.target.value);
        break;
      case 'theme':
        setNewTheme(newTheme === 'light' ? 'dark' : 'light');
        updateTheme();
        break;
      case 'extendedView':
        setSomeSetting((prevState) => !prevState);
        console.log('some new setting');
        break;
      case 'popitAdmin':
        setNewPopitAdmin((prevState) => !prevState);
        break;
      default:
        console.log('no action set');
        break;
    }
  };

  const setPopitAdmin = async () => {
    setError(null);
    setUserDetailsEdited(true);
    setIsUpdating(true);
    try {
      await setUserAdmin(user.uid, newPopitAdmin);
    } catch (error) {
      setError(error);
    }

    setIsUpdating(false);

    if (closeModalUserEdit) {
      closeModalUserEdit(userDetailsEdited);
    }
  };

  return (
    <div className='card-m'>
      <div className='card-body-m'>
        <h3 className='card-title'>
          Account: {user?.displayName || 'not defined'}
        </h3>
        <hr />
        {isUpdating && <Spinner isSpinning={isUpdating} />}
        {error && (
          <Alert type='error' title={error.message} content={error.cause} />
        )}
        <div className='card-secondary'>
          {getPersonalDetails(user, newDisplayName, handleChange)}
        </div>
        <div className='card-secondary mt-1'>
          {getPersonalSettings(newTheme, someSetting, handleChange)}
        </div>
        {loginUser.admin && loginUser.email !== user.email && (
          <div className='card-secondary mt-1'>
            {setAdminUser(
              newPopitAdmin,
              handleChange,
              setPopitAdmin,
              isUpdating,
            )}
          </div>
        )}
        <div className='row mt-2'>
          <div className='col-12 c-group u-h-evenly'>
            <button
              className={`btn is-secondary`}
              type='button'
              disabled={isUpdating}
              onClick={reset}
            >
              {!userDetailsEdited && userNotChanged ? 'Close' : 'Cancel'}
            </button>
            <button
              className={`btn`}
              type='button'
              disabled={isUpdating || userNotChanged}
              onClick={saveChanges}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const setAdminUser = (
  newPopitAdmin,
  handleChange,
  setPopitAdmin,
  isUpdating,
) => {
  return (
    <>
      <h4 className='card-title'>Popit admin user</h4>
      <hr />
      <p>
        Set user to Popit admin. This user will have access to all data and
        functions in the service.
      </p>

      <div className='row mt-1'>
        <div className='col-6 col-1st'>
          <label className='toggle'>
            <input
              className='toggle-checkbox'
              type='checkbox'
              checked={newPopitAdmin}
              id='popitAdmin'
              onChange={handleChange}
            />
            <div className='toggle-switch'></div>
          </label>
          <label className='toggle-label ml-1'>Popit Admin</label>
        </div>
        <div className='col-6 col-2nd'>
          <button
            className={`btn`}
            type='button'
            disabled={isUpdating}
            onClick={setPopitAdmin}
          >
            Set Admin
          </button>
        </div>
      </div>
    </>
  );
};

const getPersonalSettings = (newTheme, someSetting, handleChange) => {
  return (
    <>
      <h4 className='card-title'>Preferences</h4>
      <hr />
      <div className='row'>
        <div className='col-6 col-1st'>
          <label className='toggle'>
            <input
              className='toggle-checkbox'
              type='checkbox'
              checked={newTheme === 'dark'}
              id='theme'
              onChange={handleChange}
            />
            <div className='toggle-switch'></div>
          </label>
          <label className='toggle-label ml-1'>Enable dark theme</label>
        </div>
        <div className='col-6 col-2nd'>
          <label className='toggle'>
            <input
              className='toggle-checkbox'
              type='checkbox'
              checked={someSetting}
              id='extendedView'
              onChange={handleChange}
            />
            <div className='toggle-switch'></div>
          </label>
          <label className='toggle-label ml-1'>Not used settings</label>
        </div>
      </div>
      {/* <div className='row mt-1'>
        <div className='col-6 col-1st'>
          <label className='toggle'>
            <input
              className='toggle-checkbox'
              type='checkbox'
              checked={editUser?.companyAdmin}
              id='companyAdmin'
              onChange={handleChange}
            />
            <div className='toggle-switch'></div>
          </label>
          <label className='toggle-label ml-1'>Company admin</label>
        </div>
        <div className='col-6 col-2nd'>
          <label className='toggle'>
            <input
              className='toggle-checkbox'
              type='checkbox'
              checked={editUser?.extraSettingA}
              id='extraSettingA'
              onChange={handleChange}
            />
            <div className='toggle-switch'></div>
          </label>
          <label className='toggle-label ml-1'>Extra setting A</label>
        </div>
      </div> */}
    </>
  );
};

const getPersonalDetails = (user, newDisplayName, handleChange) => {
  return (
    <>
      <h4 className='card-title'>Personal details</h4>
      <hr />
      <div className='row'>
        <div className='col-6 col-1st display-block'>
          <label className='form-label' htmlFor='displayName'>
            First & last name
          </label>
          <div>
            <div className='input-icon'>
              <FaUser />
            </div>
            <input
              type='name'
              className='form-control'
              id='displayName'
              value={newDisplayName}
              onChange={handleChange}
              placeholder='First and last name'
              required
            />
          </div>
        </div>
        <div className='col-6 col-2nd display-block'>
          <label className='form-label' htmlFor='email'>
            Email address
          </label>
          <div>
            <div className='input-icon'>
              <MdEmail />
            </div>
            <input
              type='email'
              value={user?.email}
              className='form-control'
              id='email'
              disabled={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

UserForm.propTypes = {
  user: PropTypes.object.isRequired,
  closeModalUserEdit: PropTypes.func,
};

export default UserForm;
