import {
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  where,
  startAfter,
  collectionGroup,
} from 'firebase/firestore';
import { db } from '../app/config';
import PropTypes from 'prop-types';
import { FirebaseError, ServiceError } from '../utils/errors';
import { trace } from 'firebase/performance';
import { perf } from '../app/config';
import {
  PERF_TRACE_NAME,
  PERF_TRACE_VALUE,
  PERF_TRACE_PRAM_NAME,
  EVENT_LOG_COLLECTION,
} from '../shared/Constants';

export const createStatsFetchMethod = (
  collectionName,
  collectionMainDataField,
  freeFilterField,
  freeFilterQuery,
  dayFilterField,
  defaultSortDirection,
  maxCount,
  autoload,
) => {
  const defaultFilterObject = {
    mainDataField: collectionMainDataField,
    freeFilterField: freeFilterField,
    freeFilterQuery: freeFilterQuery,
    freeFilter: '',
    sortDirection: defaultSortDirection,
    selectedCompanies: [],
    selectedGroups: [],
    dateStart: '',
    dateEnd: '',
  };

  const getData = async (
    pageSize,
    filterObject,
    lastVisibleDoc,
    setLastVisibleDoc,
    loadMore,
  ) => {
    const [snapshotData, docCount] = await Promise.all([
      getSnapshotData(
        pageSize,
        filterObject,
        lastVisibleDoc,
        setLastVisibleDoc,
        loadMore,
      ),
      getDocsCount(filterObject),
    ]);

    let results = {
      docs: snapshotData.docs.map((doc) => {
        return { ...doc.data(), uid: doc.id };
      }),
      total: docCount,
    };
    // console.log(results);
    return results;
  };

  const getSnapshotData = async (
    pageSize,
    filterObject,
    lastVisibleDoc,
    setLastVisibleDoc,
    loadMore,
  ) => {
    const t = trace(perf, PERF_TRACE_NAME.fbFetchDataStatsTiming);
    t.start();
    try {
      const dataQuery = getQuery(
        pageSize,
        filterObject,
        lastVisibleDoc,
        loadMore,
      );
      const querySnapshot = await getDocs(dataQuery);

      t.putAttribute(PERF_TRACE_PRAM_NAME.queryCollection, collectionName);
      t.incrementMetric(PERF_TRACE_VALUE.docsCount, querySnapshot.size);

      // Set last visible doc for load more data
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisibleDoc(lastVisible);
      t.stop();
      return querySnapshot;
    } catch (error) {
      t.stop();
      throw new FirebaseError(
        'Firebase getSnapshotData error createStatsFetchMethod',
        error.message,
      );
    }
  };

  const getDocsCount = async (filterObject) => {
    const countQuery = getQuery(maxCount, filterObject, null, false);
    try {
      const snapshot = await getCountFromServer(countQuery);
      if (snapshot.data().count >= maxCount) {
        throw new ServiceError(
          'Stats service result max limit',
          `Query results hit max ${maxCount} limit ${collectionName}. Seach total result count: ${
            snapshot.data().count
          }. Make a search with more limited parameters.`,
        );
      } else {
        return snapshot.data().count;
      }
    } catch (error) {
      if (error instanceof ServiceError) {
        throw error;
      } else {
        throw new FirebaseError(
          'Firebase getDocsCount error createStatsFetchMethod',
          error.message,
        );
      }
    }
  };

  const getQuery = (pageSize, filterObject, lastVisibleDoc, loadMore) => {
    const collectionRef = collectionGroup(db, collectionName);
    const queryConstraints = [];
    let dataQuery;

    if (filterObject.dateStart !== '' || filterObject.dateEnd !== '') {
      queryConstraints.push(orderBy(dayFilterField, 'desc'));
    }
    if (filterObject.dateStart !== '') {
      queryConstraints.push(
        where(dayFilterField, '>=', filterObject.dateStart),
      );
    }
    if (filterObject.dateEnd !== '') {
      queryConstraints.push(where(dayFilterField, '<=', filterObject.dateEnd));
    }

    //===== FREE TEXT filter
    if (filterObject?.freeFilter && filterObject.freeFilter.length > 0) {
      // Remove spaces from string and split into an array
      let tempFilter = { ...filterObject };
      tempFilter = filterObject.freeFilter
        .replaceAll(/\s/g, '')
        .split(',')
        .map(Number);
      if (tempFilter.length > 0 && tempFilter.length <= 10) {
        // console.log(filterObject);
        // console.log(tempFilter);

        // queryConstraints.push(
        //   orderBy(filterObject.mainDataField, filterObject.defaultSortDirection)
        // );
        // queryConstraints.push(where(filterObject.mainDataField, '!=', null));
        queryConstraints.push(
          where(
            filterObject.freeFilterField,
            filterObject.freeFilterQuery,
            tempFilter,
          ),
        );
        // if query for EventLog => sort results absed on eventLogTimestamp
        if (collectionName === EVENT_LOG_COLLECTION) {
          queryConstraints.push(
            orderBy(dayFilterField, filterObject.sortDirection),
          );
        }
      } else {
        // TODO: handle lager queries
        console.log('TODO: search not supported');
        throw new ServiceError(
          'Stats service getQuery error',
          'Free filter has +10 items',
        );
      }
    } else {
      if (
        //===== GROUP filtering
        filterObject?.selectedCompanies &&
        filterObject.selectedCompanies.length > 0
      ) {
        // Set companyId filter
        queryConstraints.push(
          where('companyId', '==', filterObject.selectedCompanies[0]),
        );

        // Add groupId if set
        if (
          filterObject?.selectedGroups &&
          filterObject.selectedGroups.length > 0
        ) {
          queryConstraints.push(
            where('groupId', '==', filterObject.selectedGroups[0]),
          );
        }
      } else {
        //===== DEFAULT filtering
        queryConstraints.push(
          orderBy(filterObject.mainDataField, filterObject.sortDirection),
        );
      }
    }

    //===== LOAD MORE add startAfter
    if (loadMore && lastVisibleDoc) {
      queryConstraints.push(startAfter(lastVisibleDoc));
    }

    //===== LIMIT number of results
    queryConstraints.push(limit(pageSize));

    dataQuery = query(collectionRef, ...queryConstraints);
    return dataQuery;
  };

  return { getData, getQuery, getDocsCount, defaultFilterObject, autoload };
};

export const sortByKey = (array, key, direction) => {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    if (direction === 'asc') {
      return x < y ? -1 : x > y ? 1 : 0;
    } else if (direction === 'desc') {
      return x > y ? -1 : x < y ? 1 : 0;
    } else {
      console.log('ERROR: sortByKey');
      return 0;
    }
  });
};

export const setCompanyGroupNames = (item, companies) => {
  const foundCompany = companies.find((x) => x.id === item.companyId);
  const foundGroup = foundCompany
    ? foundCompany.groups.find((x) => x.id === item.groupId)
    : undefined;
  return {
    ...item,
    companyId: item.companyId,
    companyName: foundCompany ? foundCompany.name : item.companyId,
    groupId: item.groupId,
    groupName: foundGroup ? foundGroup.name : item.groupId,
  };
};

createStatsFetchMethod.propTypes = {
  collectionName: PropTypes.oneOf([
    EVENT_LOG_COLLECTION,
    'users',
    'medstatus',
    'medicines',
    'appstatus',
  ]),
  collectionMainDataField: PropTypes.oneOf(['appId', 'medicineId', 'deviceId']),
  freeFilterField: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  freeFilterQuery: PropTypes.oneOf(['in', '==']),
  dayFilterField: PropTypes.oneOf(['day', 'eventTimestamp']),
  defaultSortDirection: PropTypes.oneOf(['asc', 'desc']),
  maxCount: PropTypes.number,
  autoload: PropTypes.bool,
};
