import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
  // Check has user set dark mode in browser preferences
  const browserDefaultDark = window.matchMedia(
    '(prefers-color-scheme: dark)',
  ).matches;

  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || (browserDefaultDark ? 'dark' : 'light'),
  );

  const switchTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
    document.documentElement.setAttribute('data-color-scheme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        switchTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

ThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
