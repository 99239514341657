import { createColumnHelper } from '@tanstack/react-table';
import CellUsersAction from './cells/CellUsersAction';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa';

function tableHeaderUsers(
  handleUserEdit,
  handleUserEnable,
  handleUserGroupEdit,
) {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.accessor('displayName', {
      id: 'displayName',
      header: () => 'Name',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: () => 'E-mail',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('uid', {
      id: 'uid',
      header: () => 'ID',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('created', {
      id: 'created',
      header: () => 'Created',
      cell: (info) => new Date(info.getValue() * 1000).toLocaleString(),
      enableSorting: true,
    }),
    columnHelper.accessor('enabled', {
      id: 'enabled',
      header: () => 'Active',
      cell: (info) => {
        return (
          <div className='row u-h-center'>
            {info.getValue() ? (
              <FaCheckCircle className='icon-success' />
            ) : (
              <FaMinusCircle className='icon-fail' />
            )}
          </div>
        );
      },
      enableSorting: true,
    }),
    columnHelper.accessor('admin', {
      id: 'admin',
      header: () => 'Admin',
      cell: (info) => {
        return (
          <div className='row u-h-center'>
            {info.getValue() ? <FaCheckCircle className='icon-success' /> : ''}
          </div>
        );
      },
      enableSorting: true,
    }),
    columnHelper.accessor('', {
      id: 'actions',
      header: () => 'Actions',
      cell: (info) => (
        <CellUsersAction
          user={info.row.original}
          handleUserEdit={handleUserEdit}
          handleUserGroupEdit={handleUserGroupEdit}
          handleUserEnable={handleUserEnable}
          userEnabled={info.row.original.enabled}
        />
      ),
      enableSorting: false,
    }),
  ];
}
export default tableHeaderUsers;
