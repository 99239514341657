import PropTypes from 'prop-types';

const Spinner = ({ isSpinning }) => {
  if (!isSpinning) {
    return;
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-12 mx-2' style={{ textAlign: '-webkit-center' }}>
          <div className='spinner'></div>
        </div>
      </div>
    </div>
  );
};

Spinner.propTypes = {
  isSpinning: PropTypes.bool.isRequired,
};

export default Spinner;
