import { createColumnHelper } from '@tanstack/react-table';
import { FaArrowDown, FaArrowRight } from 'react-icons/fa';
import CellGroupsAction from './cells/CellGroupsAction';
import { IoBusiness } from 'react-icons/io5';
import PropTypes from 'prop-types';

function tableHeaderGroups(setModalData) {
  const columnHelper = createColumnHelper();
  return [
    {
      id: 'expander',
      header: () => null,
      cell: ({ row }) => {
        return row.getCanExpand() && row.depth === 0 ? (
          <button
            {...{
              onClick: row.getToggleExpandedHandler(),
              className: 'btn-transparent',
            }}
          >
            {row.getIsExpanded() ? <FaArrowDown /> : <FaArrowRight />}
          </button>
        ) : (
          ''
        );
      },
    },
    columnHelper.accessor('name', {
      id: 'name',
      header: () => 'Company / Group name',
      //   cell: (info) => info.getValue(),
      cell: ({ row }) => {
        return (
          <div
            style={{
              // Add padding based on row.depth
              paddingLeft: `${row.depth * 1}rem`,
            }}
          >
            {row.original.name}
          </div>
        );
      },
      enableSorting: true,
    }),
    columnHelper.accessor('numberOfGroups', {
      id: 'numberOfGroups',
      //   header: () => 'Groups',
      header: () => <IoBusiness />,
      cell: ({ row }) => {
        if (row.depth !== 0) {
          return <div></div>;
        }
        const groups = row.original?.groups || null;
        const numberOfGroups =
          groups != null ? Object.keys(row.original.groups).length : 0;
        return <div>{numberOfGroups}</div>;
      },
      enableSorting: true,
    }),
    columnHelper.accessor('id', {
      id: 'id',
      header: () => 'ID',
      cell: (info) => <div>{info.getValue()}</div>,
      enableSorting: true,
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      header: () => 'Actions',
      cell: (info) => (
        <CellGroupsAction
          rawData={info.row.original}
          setModalData={setModalData}
        />
      ),
      enableSorting: false,
    }),
  ];
}

tableHeaderGroups.propTypes = {
  setModalData: PropTypes.func.isRequired,
};

export default tableHeaderGroups;
