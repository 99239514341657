import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../app/config';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useAuthContext } from './useAuthContext';

export const useLogin = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const {
    dispatch,
    updateIsAdminState,
    updateUserDataState,
    getInitialUserDataState,
  } = useAuthContext();

  const login = async (email, password) => {
    setError(null);
    setIsPending(true);
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (!response || response?.user === undefined) {
        throw new Error('Account login error, please try later again');
      }

      if (response.user.emailVerified === false) {
        await signOut(auth);
        dispatch({ type: 'LOGOUT', payload: null });
        throw new Error(
          'Email not verified. Please check your email or request a new verification code below.',
        );
      }

      const initialUserData = getInitialUserDataState(response.user);
      // dispatch initial data
      dispatch({
        type: 'LOGIN',
        payload: { ...initialUserData },
      });

      // update admin property
      await updateIsAdminState(response.user);
      // update user data from Firestore
      await updateUserDataState(response.user);

      navigate('/');

      setError(null);
      setIsPending(false);
      return;
    } catch (err) {
      if (err.code === undefined) {
        setError(err.message);
      } else {
        setError('Incorrect email or password');
      }
      setIsPending(false);
      return;
    }
  };

  return {
    error,
    isPending,
    login,
  };
};
