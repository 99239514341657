import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
  getDoc,
  setDoc,
  writeBatch,
} from 'firebase/firestore';
import { db } from '../app/config';
import { ValidationError, FirebaseError } from '../utils/errors';

const MAX_BATCH_WRITE = 100;

export const getData = async (collectionPath, uid) => {
  try {
    const docRef = doc(db, collectionPath, uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    throw new FirebaseError('Firebase getData error', error.message);
  }
};

export const updateDataBatchWrite = async (collectionPath, uids, newData) => {
  if (newData === undefined || newData === null) {
    throw new ValidationError('Batch write error', 'New data content empty');
  }
  if (uids.length === 0 || uids.length >= MAX_BATCH_WRITE) {
    throw new ValidationError(
      'Batch write error',
      'Zero or over 100 items in the request. This is security limit.',
    );
  }

  const batch = writeBatch(db);
  uids.forEach((uid) => {
    const docRef = doc(db, collectionPath, uid);
    batch.update(docRef, newData);
  });
  try {
    await batch.commit();
  } catch (error) {
    console.log(error);
    throw new FirebaseError(
      'Firebase updateDataBatchWrite error',
      error.message,
    );
  }
};

// If the document does exist, its contents will be overwritten with the newly provided data,
export const addDataWithUid = async (collectionPath, uid, data) => {
  try {
    const docRef = doc(db, collectionPath, uid);
    await setDoc(docRef, data);
  } catch (error) {
    console.log(error);
    throw new FirebaseError('Firebase addDataWithUid error', error.message);
  }
};
// returns docId of new created doc
export const addDataWithoutUid = async (collectionPath, data) => {
  try {
    const collectionRef = collection(db, collectionPath);
    const docRef = await addDoc(collectionRef, data);
    return { docId: docRef.id };
  } catch (error) {
    console.log(error);
    throw new FirebaseError('Firebase addDataWithoutUid error', error.message);
  }
};

export const updateData = async (collectionPath, uid, newData) => {
  try {
    const docRef = doc(db, collectionPath, uid);
    await updateDoc(docRef, newData);
  } catch (error) {
    console.log(error);
    throw new FirebaseError('Firebase updateData error', error.message);
  }
};

export const deleteData = async (collectionPath, uid) => {
  try {
    const docRef = doc(db, collectionPath, uid);
    await deleteDoc(docRef);
  } catch (error) {
    console.log(error);
    throw new FirebaseError('Firebase deleteData error', error.message);
  }
};

export const sortByField = (arr, field) => {
  arr.sort((x, y) => {
    const fieldValue1 = x[field];
    const fieldValue2 = y[field];

    if (fieldValue1 === fieldValue2) {
      return 0;
    }

    if (fieldValue1 && !fieldValue2) {
      return 1;
    }

    if (!fieldValue1 && fieldValue2) {
      return -1;
    }

    return fieldValue1.localeCompare(fieldValue2);
  });
};
