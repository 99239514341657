import { useState } from 'react';
import Modal from '../Modal';
import { sendFbMessage } from '../../services/message.service';
import pushMessageContent from './pushMessageContent';
import TemplateSelector from './TemplateSelector';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import Alert from '../Alert';

function SendPushMessageModal({
  sendMessageModalOpen,
  setSendMessageModalOpen,
  selectedDevices,
  publisher,
}) {
  const [messageContent, setMessageContent] = useState(pushMessageContent);
  const [isSending, setIsSending] = useState(false);
  const [sendResult, setSendResult] = useState(null);

  const closeModal = () => {
    setMessageContent({ ...pushMessageContent });
    setSendMessageModalOpen(false);
    setSendResult(null);
  };

  const sendPushMessage = () => {
    setSendResult(null);
    setIsSending(true);
    sendFbMessage(messageContent)
      .then((res) => {
        if (
          res.data.devicesNoToken.length > 0 ||
          res.data.devicesInvalidToken.length > 0
        ) {
          setSendResult({
            type: 'warning',
            title: 'Some messages not sent',
            content: `\nMessage sent OK: ${res.data.validDevices}\n\nERROR: Invalid token: ${res.data.devicesInvalidToken}\nERROR: No token: ${res.data.devicesNoToken}`,
          });
        } else {
          setSendResult({
            type: 'success',
            title: 'Message sent OK.',
          });
        }
        setIsSending(false);
      })
      .catch((error) => {
        console.log('==== error: ', error);
        setSendResult({
          type: 'error',
          title: 'ERROR',
          content: error.message || error,
        });
        setIsSending(false);
      });
  };

  return (
    <Modal isOpen={sendMessageModalOpen}>
      <div className='card-m'>
        <div className='card-body-m'>
          <h3 className='card-title'>Send push message</h3>
          <hr />
          {isSending && <Spinner isSpinning={isSending} />}
          {sendResult && (
            <div className='mtb-1'>
              <Alert
                type={sendResult.type}
                title={sendResult.title}
                content={sendResult.content}
              />
            </div>
          )}
          {mSelectedDevices(selectedDevices)}
          <div className='row mt-1'>
            <div className='col-12'>
              <TemplateSelector
                messageContent={messageContent}
                setMessageContent={setMessageContent}
                selectedDevices={selectedDevices}
                publisher={publisher}
                defaultContent={pushMessageContent}
              />
            </div>
          </div>
          <div className='row mt-1'>
            <div className='col-12'>
              {pushMessageContentFooter(
                sendPushMessage,
                closeModal,
                messageContent.template === '',
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const pushMessageContentFooter = (okAction, cancelAction, okActionEnabled) => {
  return (
    <div className='row space-evenly'>
      <div className='mx-auto'>
        <button
          className={`btn-secondary`}
          onClick={() => {
            cancelAction();
          }}
        >
          Close
        </button>
      </div>
      <div className='mx-auto'>
        <button
          className={`btn`}
          disabled={okActionEnabled}
          onClick={() => {
            okAction();
          }}
        >
          Send
        </button>
      </div>
    </div>
  );
};

const mSelectedDevices = (selectedDevices) => {
  return (
    <div>
      <b>Selected devices:</b>
      <textarea
        className='textarea-no-edit'
        disabled
        defaultValue={selectedDevices.join(', ')}
      />
    </div>
  );
};

SendPushMessageModal.defaultProps = {
  sendMessageModalOpen: true,
  selectedDevices: [],
};

SendPushMessageModal.propTypes = {
  sendMessageModalOpen: PropTypes.bool,
  setSendMessageModalOpen: PropTypes.func.isRequired,
  selectedDevices: PropTypes.arrayOf(PropTypes.number).isRequired,
  publisher: PropTypes.object.isRequired,
};

export default SendPushMessageModal;
