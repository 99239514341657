import {
  createStatsFetchMethod,
  sortByKey,
  setCompanyGroupNames,
} from './stats.service';

const COLLECTION = 'appstatus';
const DEFAULT_FIELD = 'appId';
const DEFAULT_FREE_FILTER_FIELD = 'appId';
const FREE_FILTER_QUERY = 'in';
const DEFAULT_DAY_FIELD = 'day';
const DEFAULT_SORT_DIRECTION = 'asc';
export const MAX_COUNT = 10000;
const AUTO_LOAD = false;

export const getStatsApp = createStatsFetchMethod(
  COLLECTION,
  DEFAULT_FIELD,
  DEFAULT_FREE_FILTER_FIELD,
  FREE_FILTER_QUERY,
  DEFAULT_DAY_FIELD,
  DEFAULT_SORT_DIRECTION,
  MAX_COUNT,
  AUTO_LOAD,
);

export const statsAppDataModifierFunc = (fetchedData, companies) => {
  const incorrectCompanyId = 'incorrect companiID';

  let results = {
    companyUsers: [],
    users: [],
    user: [],
  };
  // return if data not available
  if (!fetchedData || !companies || companies.length < 1) {
    return results;
  }

  // create user data per AppId
  const groupByAppId = fetchedData.reduce((group, item) => {
    const { appId } = item;
    group[appId] = group[appId] ?? [];
    group[appId].push(item);
    return group;
  }, {});

  results = {
    ...results,
    user: groupByAppId,
  };

  // create user data - lastest status
  const newestDataPerUser = [];
  Object.entries(groupByAppId).forEach(([key, value]) => {
    // Update company and group ID to match names
    const _appId = Number(key);
    const _data = setCompanyGroupNames(value[0] || {}, companies);
    newestDataPerUser.push({
      appId: _appId,
      data: _data,
    });
  });

  results = {
    ...results,
    users: sortByKey(newestDataPerUser, 'appId', 'asc'),
  };

  // create companyUser data per companyId
  const groupByCompanyId = fetchedData.reduce((group, item) => {
    const { companyId } = item;
    group[companyId] = group[companyId] ?? [];
    group[companyId].push(item);
    return group;
  }, {});

  const dataStatsAppTemp = [];
  Object.entries(groupByCompanyId).forEach(([key, value]) =>
    dataStatsAppTemp.push({
      companyId: key,
      data: value.reduce((group, item) => {
        const { appId } = item;
        group[appId] = group[appId] ?? [];
        group[appId].push(item);
        return group;
      }, {}),
    }),
  );

  const dataStatsApp = [];
  dataStatsAppTemp.forEach((item) => {
    dataStatsApp.push({
      companyId: item?.companyId || incorrectCompanyId,
      companyName:
        companies.find((x) => x.id === item?.companyId)?.name ||
        item?.companyId ||
        incorrectCompanyId,
      ...getStatsAppForOneCompany(item.data),
    });
  });

  const companyUsers = sortByKey(dataStatsApp, 'companyName', 'asc');

  results = {
    ...results,
    companyUsers: companyUsers,
  };

  return results;
};

const getStatsAppForOneCompany = (data) => {
  let appIds = 0;
  let usersActive = 0;
  let usersNew = 0;
  let usersStopped = 0;
  let usersUse14d = 0;
  let usersUse30d = 0;
  let usersUse60d = 0;
  let usersUse90d = 0;

  let viewsIntro = 0;
  let viewsAppMsg = 0;
  let viewsPrivacy = 0;
  let viewsTerms = 0;
  let viewsFaq = 0;

  appIds = Object.keys(data).length;
  let statsResultsTemp = [];
  Object.entries(data).forEach(([, value]) => {
    statsResultsTemp.push({
      useDaysApp: value[0].data.useDaysApp,
      useDaysAppFirst: value[value.length - 1].data.useDaysApp,
      lastStatusDay: value[0].day,
      firstStatusDay: value[value.length - 1].day,

      viewsIntro: value[0].data.counterViewPopitIntro,
      viewsAppMsg: value[0].data.counterViewAppMessages,
      viewsPrivacy: value[0].data.counterViewPrivacyPolicy,
      viewsTerms: value[0].data.counterViewTermsOfUse,
      viewsFaq: value[0].data.counterViewFaq,
    });
  });

  statsResultsTemp.forEach((item) => {
    // TODO: change ref data to last filter day
    const refDate = Date.now();
    const daysSinceNow = Math.round(
      (refDate - Date.parse(item.lastStatusDay)) / (1000 * 60 * 60 * 24),
    );
    const usersActiveLimit = 14; // This defines is user is active or deactive
    if (item.useDaysAppFirst <= 1) {
      usersNew++;
    } else if (daysSinceNow < usersActiveLimit) {
      usersActive++;
    } else {
      usersStopped++;
    }

    if (item.useDaysApp >= 14) {
      usersUse14d++;
    }
    if (item.useDaysApp >= 30) {
      usersUse30d++;
    }
    if (item.useDaysApp >= 60) {
      usersUse60d++;
    }
    if (item.useDaysApp >= 90) {
      usersUse90d++;
    }

    viewsIntro += item.viewsIntro;
    viewsAppMsg += item.viewsAppMsg;
    viewsPrivacy += item.viewsPrivacy;
    viewsTerms += item.viewsTerms;
    viewsFaq += item.viewsFaq;
  });

  return {
    appIds: appIds,
    usersActive: usersActive,
    usersNew: usersNew,
    usersStopped: usersStopped,
    usersUse14d: usersUse14d,
    usersUse30d: usersUse30d,
    usersUse60d: usersUse60d,
    usersUse90d: usersUse90d,
    viewsIntro: viewsIntro,
    viewsAppMsg: viewsAppMsg,
    viewsPrivacy: viewsPrivacy,
    viewsTerms: viewsTerms,
    viewsFaq: viewsFaq,
  };
};
