import { useEffect, useState } from 'react';
import { useServiceDataSource } from '../hooks/useServiceDataSource';
import { getMessages } from '../services/message.service';
import Table from '../components/table/Table';
import Spinner from '../components/Spinner';
import TablePagination, {
  getPageLastNumber,
} from '../components/table/TablePagination';
import tableHeaderMessages from '../components/table/tableHeaderMessages';
import FilterA from '../components/filters/FilterA';
import Alert from '../components/Alert';
import {
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';

import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';

function Messages() {
  logEvent(analytics, ANALYTICS_EVENT.page_view_messages);

  const {
    error,
    data,
    filterObject,
    isLoading,
    pageSize,
    totalDocs,
    loadMoreData,
    reloadData,
    updateFilterObject,
    updatePageSize,
  } = useServiceDataSource(
    getMessages.getData,
    getMessages.getQuery,
    getMessages.getDocsCount,
    getMessages.defaultFilterObject,
    null,
    getMessages.autoload,
  );

  const [pageLastNumber, setPageLastNumber] = useState(0);
  const [showNumberOfDocs, setShowNumberOfDocs] = useState(10);

  // Table sorting
  const [sorting, setSorting] = useState([]);
  // Table - create columns
  const columns = tableHeaderMessages();
  // Table expandable row
  const getRowCanExpand = () => true;
  const [expanded, setExpanded] = useState({});

  // Table - create table content
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      expanded,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    // debugTable: true,
  });

  useEffect(() => {
    const _pageLastNumber = getPageLastNumber(totalDocs, pageSize);
    setPageLastNumber(_pageLastNumber);
  }, [totalDocs, pageSize]);

  const loadMoreDataEnabled = () => {
    if (totalDocs === data.length) {
      return false;
    } else {
      return true;
    }
  };

  const showMoreData = async () => {
    await loadMoreData();
    setShowNumberOfDocs((prevState) => {
      if (prevState >= totalDocs) {
        return totalDocs;
      } else if (prevState + pageSize > totalDocs) {
        return totalDocs;
      } else {
        return prevState + pageSize;
      }
    });
  };

  // Config for FilterA component
  const filterConfig = {
    enabled: {
      isFreeText: true,
      isCompanies: false,
      isFromDate: false,
      isToDate: false,
    },
    filterMethods: {
      filterFree: {
        freeFilterPlaceHolder: 'Search by device SN',
      },
      filterGroups: {},
      filterDate: {},
    },
  };

  return (
    <div className='table-container'>
      <div className='table-menu-header'>
        <div>
          <h3>Messages</h3>
          <p>{totalDocs} messages</p>
        </div>
      </div>

      <FilterA
        filterConfig={filterConfig}
        filterObject={filterObject}
        updateFilterObject={updateFilterObject}
        triggerSearch={reloadData}
      />

      <Spinner isSpinning={isLoading}></Spinner>

      {error != null && (
        <div className='mtb-1'>
          <Alert type='error' title={error.message} content={error.cause} />
        </div>
      )}

      {!isLoading && (
        <>
          <Table table={table} expandedRowContent='rawJson' />

          {data.length < 1 && <div className='table-no-data'>No data</div>}

          <TablePagination
            pageCurrentNumber={Math.ceil(showNumberOfDocs / pageSize)}
            pageLastNumber={pageLastNumber}
            pageSize={pageSize}
            totalNumberOfDocs={totalDocs}
            changePageSize={(newSize) => {
              updatePageSize(newSize);
            }}
            loadMoreDataEnabled={loadMoreDataEnabled()}
            loadMoreData={showMoreData}
          />
        </>
      )}
    </div>
  );
}
export default Messages;
