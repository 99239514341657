import { useEffect, useState } from 'react';
import { FaTrashAlt, FaSearch } from 'react-icons/fa';
import PropTypes from 'prop-types';

const DATE_MAX_RANGE = 365; // Max db query date range, to limit data fetch

function FilterA({
  filterConfig,
  filterObject,
  updateFilterObject,
  triggerSearch,
}) {
  const { isFreeText, isCompanies, isFromDate, isToDate } =
    filterConfig.enabled;
  const { filterFree, filterGroups, filterDate } = filterConfig.filterMethods;
  const { freeFilterPlaceHolder, disableIfEmpty } = filterFree;
  const { limitDateRange } = filterDate;
  const { companies } = filterGroups;
  const [groupOptions, setGroupOptions] = useState([]);
  const [dateStartLimit, setDateStartLimit] = useState('');
  const [dateEndLimit, setDateEndLimit] = useState('');

  const updateSelectedCompanyID = (selectedCompany) => {
    updateFilterObject((prevState) => ({
      ...prevState,
      freeFilter: '',
      selectedCompanies: selectedCompany.length > 0 ? [selectedCompany] : [],
      selectedGroups: [],
    }));
  };

  const updateSelectedGroupID = (selectedGroup) => {
    updateFilterObject((prevState) => ({
      ...prevState,
      freeFilter: '',
      selectedGroups: selectedGroup.length > 0 ? [selectedGroup] : [],
    }));
  };

  const updateFreeFilter = (e) => {
    updateFilterObject((prevState) => ({
      ...prevState,
      freeFilter: e.target.value,
      selectedCompanies: [],
      selectedGroups: [],
    }));
  };

  // Set default dates for date filters
  const dateEndDefault = () => {
    let d = new Date(); // Set end date TODAY
    return d.toISOString().split('T')[0];
  };
  const dateStartDefault = () => {
    let d = new Date();
    const x = 7; // Shift date past number of days
    d.setDate(d.getDate() - x);
    return d.toISOString().split('T')[0];
  };

  useEffect(() => {
    if (isFromDate === true && isToDate === true) {
      updateFilterObject((prevState) => ({
        ...prevState,
        dateStart: dateStartDefault(),
        dateEnd: dateEndDefault(),
      }));
    }
    // NOTE: warning silenced in purpose.
    // useEffect should be run only once when component mounted to set initial dates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearFilters = () => {
    updateFilterObject((prevState) => ({
      ...prevState,
      freeFilter: '',
      selectedCompanies: [],
      selectedGroups: [],
      dateStart: limitDateRange === true ? dateStartDefault() : '',
      dateEnd: limitDateRange === true ? dateEndDefault() : '',
    }));
  };

  const handleDateChange = (e) => {
    switch (e.target.id) {
      case 'dateStart':
        if (e.target.value === '') {
          updateFilterObject((prevState) => ({
            ...prevState,
            dateStart: '',
          }));
        } else {
          updateFilterObject((prevState) => ({
            ...prevState,
            dateStart: e.target.value,
          }));
        }
        break;
      case 'dateEnd':
        if (e.target.value === '') {
          updateFilterObject((prevState) => ({
            ...prevState,
            dateEnd: '',
          }));
        } else {
          updateFilterObject((prevState) => ({
            ...prevState,
            dateEnd: e.target.value,
          }));
        }
        break;
      default:
        console.log('no action');
        break;
    }
  };

  useEffect(() => {
    if (
      isFromDate === true &&
      isToDate === true &&
      filterObject.dateStart !== '' &&
      filterObject.dateEnd !== ''
    ) {
      let _dateStart =
        filterObject.dateStart !== ''
          ? filterObject.dateStart
          : dateStartDefault;
      let _dateEnd =
        filterObject.dateEnd !== '' ? filterObject.dateEnd : dateEndDefault;
      if (_dateEnd !== '' && _dateStart !== '') {
        let dateStartD = new Date(Date.parse(_dateStart));
        dateStartD.setDate(dateStartD.getDate() + DATE_MAX_RANGE);
        setDateEndLimit(dateStartD.toISOString().split('T')[0]);
      }
    }
    // NOTE: warning silenced in purpose.
    // useEffect should be run only when filterObject.dateStart updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObject.dateStart]);

  useEffect(() => {
    if (
      isFromDate === true &&
      isToDate === true &&
      filterObject.dateStart !== '' &&
      filterObject.dateEnd !== ''
    ) {
      let _dateStart =
        filterObject.dateStart !== ''
          ? filterObject.dateStart
          : dateStartDefault;
      let _dateEnd =
        filterObject.dateEnd !== '' ? filterObject.dateEnd : dateEndDefault;
      if (_dateEnd !== '' && _dateStart !== '') {
        let dateEndD = new Date(Date.parse(_dateEnd));
        dateEndD.setDate(dateEndD.getDate() - DATE_MAX_RANGE);
        setDateStartLimit(dateEndD.toISOString().split('T')[0]);
      }
    }
    // NOTE: warning silenced in purpose.
    // useEffect should be run only when filterObject.dateEnd updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObject.dateEnd]);

  const searchButtonDisabled = () => {
    if (isFreeText === true) {
      return (
        isFreeText &&
        disableIfEmpty &&
        filterObject.freeFilter.trim().length < 10
      );
    } else {
      return false;
    }
  };

  // Trugger search when enter pressed
  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      if (!searchButtonDisabled()) {
        triggerSearch();
      }
    }
  };

  return (
    <div className='card-secondary'>
      <div className='row'>
        <div className='col-12 c-group u-h-left u-v-bottom'>
          {isFreeText && (
            <div className='col-3'>
              <input
                type='text'
                className='input'
                id='freeFilter'
                placeholder={freeFilterPlaceHolder}
                value={filterObject.freeFilter}
                onChange={(e) => {
                  updateFreeFilter(e);
                }}
                onKeyUp={handleEnterPress}
              />
            </div>
          )}

          {isCompanies && (
            <>
              <div>
                <select
                  className='input'
                  id='selectCompany'
                  name='selectCompany'
                  value={
                    filterObject.selectedCompanies.length > 0
                      ? filterObject.selectedCompanies[0]
                      : ''
                  }
                  onChange={(e) => {
                    const companyId = e.target.value;
                    updateSelectedCompanyID(companyId);
                    const company = companies.find(
                      (cOption) => cOption.id === companyId,
                    );
                    setGroupOptions(company?.groups ? company.groups : []);
                  }}
                >
                  <option value=''>Company - all</option>
                  {companies.map((option, index) => (
                    <option value={option.id} key={`company-option-${index}`}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <select
                  className='input'
                  id='selectGroup'
                  name='selectGroup'
                  disabled={!filterObject.selectedCompanies.length > 0}
                  value={
                    filterObject.selectedGroups.length > 0
                      ? filterObject.selectedGroups[0]
                      : ''
                  }
                  onChange={(e) => {
                    updateSelectedGroupID(e.target.value);
                  }}
                >
                  <option value=''>Group - all</option>
                  {groupOptions.map((option, index) => (
                    <option value={option.id} key={`group-option-${index}`}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}

          {isFromDate && (
            <div>
              <label htmlFor='dateStart'>Start:</label>
              <input
                className='input'
                type='date'
                id='dateStart'
                name='dateStart'
                min={dateStartLimit}
                max={
                  filterObject.dateEnd !== ''
                    ? filterObject.dateEnd
                    : dateEndDefault()
                }
                value={
                  filterObject.dateStart !== ''
                    ? filterObject.dateStart
                    : limitDateRange === true
                    ? dateStartDefault()
                    : ''
                }
                onChange={handleDateChange}
              />
            </div>
          )}

          {isToDate && (
            <div>
              <label htmlFor='dateEnd'>End:</label>
              <input
                className='input'
                type='date'
                id='dateEnd'
                name='dateEnd'
                min={
                  filterObject.dateStart !== ''
                    ? filterObject.dateStart
                    : dateStartDefault()
                }
                max={dateEndLimit}
                value={
                  filterObject.dateEnd !== ''
                    ? filterObject.dateEnd
                    : limitDateRange === true
                    ? dateEndDefault()
                    : ''
                }
                onChange={handleDateChange}
              />
            </div>
          )}

          <div className='ml-1'>
            <button
              className='btn-icon'
              type='button'
              id='getData'
              name='getData'
              title='Get data'
              disabled={searchButtonDisabled()}
              onClick={triggerSearch}
            >
              <FaSearch />
            </button>
          </div>
          <div className='ml-1'>
            <button
              className='btn-icon'
              type='button'
              id='clearFilter'
              name='clearFilter'
              title='Clear filters'
              onClick={clearFilters}
            >
              <FaTrashAlt />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

FilterA.propTypes = {
  filterConfig: PropTypes.exact({
    enabled: PropTypes.exact({
      isFreeText: PropTypes.bool.isRequired,
      isCompanies: PropTypes.bool.isRequired,
      isFromDate: PropTypes.bool.isRequired,
      isToDate: PropTypes.bool.isRequired,
    }).isRequired,
    filterMethods: PropTypes.object.isRequired,
  }).isRequired,
  filterObject: PropTypes.object.isRequired,
  updateFilterObject: PropTypes.func.isRequired,
  triggerSearch: PropTypes.func.isRequired,
};

export default FilterA;
