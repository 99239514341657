import { useEffect, useState } from 'react';
import useAppDataContext from '../hooks/useAppDataContext';
import { useServiceDataSource } from '../hooks/useServiceDataSource';
import {
  getStatsApp,
  statsAppDataModifierFunc,
} from '../services/stats.app.service';
import { FaUsers } from 'react-icons/fa';

const DAY_RANGE = 7;
const MAX_COUNT = 1000;

function Home() {
  const { companies } = useAppDataContext();
  const [activeUsers, setActiveUsers] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  const { data, reloadData, updateFilterObject, updatePageSize } =
    useServiceDataSource(
      getStatsApp.getData,
      getStatsApp.getQuery,
      getStatsApp.getDocsCount,
      getStatsApp.defaultFilterObject,
      null,
      getStatsApp.autoload,
    );

  // Set default dates for date filters
  const dateEndDefault = () => {
    let d = new Date(); // Set end date TODAY
    return d.toISOString().split('T')[0];
  };
  const dateStartDefault = () => {
    let d = new Date();
    d.setDate(d.getDate() - DAY_RANGE);
    return d.toISOString().split('T')[0];
  };

  // Load data when page loaded and companies data updated
  useEffect(() => {
    if (isMounted) {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  // Update result when fetch completed
  useEffect(() => {
    if (data.length > 0) {
      const dataAppResults = statsAppDataModifierFunc(data, companies);
      setActiveUsers(dataAppResults.users.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Set filter days
  useEffect(() => {
    if (companies.length > 0) {
      updateFilterObject((prevState) => ({
        ...prevState,
        dateStart: dateStartDefault(),
        dateEnd: dateEndDefault(),
      }));
      updatePageSize(MAX_COUNT);
      setIsMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  return (
    <main className='container mt-1'>
      <div className='row'>
        <div className='col-6'>
          <div className='card-m'>
            <div className='row'>
              <div className='col-9'>
                <div className='card-body-m'>
                  <h5>Active users (past {DAY_RANGE} days)</h5>
                  <h2 className='mt-1'>{activeUsers || ' '}</h2>
                </div>
              </div>
              <div className='col-3'>
                <FaUsers className='icon icon-large ' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-1'>
        <div className='col-12'>
          <img
            className='home-image'
            alt='Samurai with medication pill sheet, Ukiyoe art'
            src={require('../assets/images/popit-home.png')}
          />
        </div>
      </div>
    </main>
  );
}
export default Home;
