import PropTypes from 'prop-types';

function Modal({ isOpen, children }) {
  return (
    <div
      className={`modal${isOpen ? ' is-open' : ''} modal--md`}
      id='deactivationConfirm'
      aria-hidden='false'
    >
      <div className='modal__overlay' tabIndex='-1'>
        <div className='modal__body' role='dialog' aria-modal='false'>
          {children}
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default Modal;
