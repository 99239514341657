import { createContext, useState, useEffect } from 'react';
import { getCompaniesAll } from '../services/company.service';
import PropTypes from 'prop-types';

const AppDataContext = createContext();

export const AppDataProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);

  // trigger fetch when context loaded the first time
  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    setIsLoading(true);
    try {
      const results = await getCompaniesAll();
      setCompanies(results);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateCompanies = () => {
    fetchCompanies();
  };

  return (
    <AppDataContext.Provider
      value={{
        isLoading,
        companies,
        updateCompanies,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};

AppDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppDataContext;
