import { createColumnHelper } from '@tanstack/react-table';
import { REMINDER_DAY_STATUS } from '../../shared/Constants';

function tableHeaderStatsMedicine() {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.group({
      id: 'Users',
      header: () => 'Medicine',
      columns: [
        columnHelper.accessor('day', {
          id: 'day',
          header: () => 'Day',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('medicineId', {
          id: 'medicineId',
          header: () => 'Medicine ID',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('appId', {
          id: 'appId',
          header: () => 'App ID',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('deviceId', {
          id: 'deviceId',
          header: () => 'Device ID',
          cell: (info) => info.getValue() || '',
          enableSorting: true,
        }),
        columnHelper.accessor('companyName', {
          id: 'companyName',
          header: () => 'Company',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('groupName', {
          id: 'groupName',
          header: () => 'Group',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),

    // medicineData
    columnHelper.group({
      id: 'Users',
      header: () => 'Medicine Info',
      columns: [
        // Medicine
        columnHelper.accessor('medicineName', {
          id: 'medicineName',
          header: () => 'Medicine',
          cell: (info) => info?.row?.original?.medicineName || '',
          enableSorting: true,
        }),
        columnHelper.accessor('medicineIcd', {
          id: 'medicineIcd',
          header: () => 'ICD',
          cell: (info) => info?.row?.original?.medicineIcd || '',
          enableSorting: true,
        }),
        columnHelper.accessor('data.medicineData.medicineStart', {
          id: 'data.medicineData.medicineStart',
          header: () => 'Created',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.medicineData.medicineEnd', {
          id: 'data.medicineData.medicineEnd',
          header: () => 'Deleted',
          cell: (info) =>
            info?.row?.original?.data?.medicineData?.medicineEnd || '',
          enableSorting: true,
        }),
        columnHelper.accessor('data.medicineData.useDaysMedicine', {
          id: 'data.medicineData.useDaysMedicine',
          header: () => 'Days Used',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.medicineData.remainingDoses', {
          id: 'data.medicineData.remainingDoses',
          header: () => 'Remaining Doses',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),

    // Day status
    columnHelper.group({
      id: 'Users',
      header: () => 'Day status',
      columns: [
        columnHelper.accessor('data.scoreData.medicineDaysStatus', {
          id: 'data.scoreData.medicineDaysStatus',
          header: () => 'State',
          cell: (info) => {
            const statusId =
              info?.row?.original?.data?.scoreData?.medicineDaysStatus || -1;
            const statusString = REMINDER_DAY_STATUS[statusId];
            return statusString;
          },
          enableSorting: true,
        }),
        columnHelper.accessor('data.scoreData.daysSinceLastDose', {
          id: 'data.scoreData.daysSinceLastDose',
          header: () => 'Days Since Last Dose',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.scoreData.daysSinceLastDoseAvg', {
          id: 'data.scoreData.daysSinceLastDoseAvg',
          header: () => 'Days Since Last Dose Avg.',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),

    // scoreData - Streak
    columnHelper.group({
      id: 'Users',
      header: () => 'Streaks',
      columns: [
        columnHelper.accessor('data.scoreData.streakLenghtMax', {
          id: 'data.scoreData.streakLenghtMax',
          header: () => 'Max Length',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.scoreData.streakLenghtAvg', {
          id: 'data.scoreData.streakLenghtAvg',
          header: () => 'Avg. Length',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.scoreData.streakLength', {
          id: 'data.scoreData.streakLength',
          header: () => 'Latest Length',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),

    // scoreData - Adherence
    columnHelper.group({
      id: 'Users',
      header: () => 'Score',
      columns: [
        columnHelper.accessor('data.scoreData.scoreMonth', {
          id: 'data.scoreData.scoreMonth',
          header: () => 'Monthly',
          cell: (info) => (info.getValue() >= 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('data.scoreData.scoreWeek', {
          id: 'data.scoreData.scoreWeek',
          header: () => 'Weekly',
          cell: (info) => (info.getValue() >= 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('data.scoreData.scoreDay', {
          id: 'data.scoreData.scoreDay',
          header: () => 'Daily',
          cell: (info) => (info.getValue() >= 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('data.scoreData.adherence', {
          id: 'data.scoreData.adherence',
          header: () => 'Adherence',
          cell: (info) => (info.getValue() >= 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
      ],
    }),

    // scoreData - Reminders
    columnHelper.group({
      id: 'Users',
      header: () => 'Reminder',
      columns: [
        columnHelper.accessor('data.reminderData', {
          id: 'data.reminderData',
          header: () => '',
          cell: (info) => {
            const reminderData = info.getValue();

            if (reminderData) {
              const reminderTimes = Object.keys(reminderData);
              return reminderTimes.map((remTime) => {
                const modifiedRem = mapTime(Number(remTime));
                return <div key={remTime}>{modifiedRem}</div>;
              });
            } else {
              return null;
            }
          },
          enableSorting: true,
        }),
      ],
    }),

    // Last data
    columnHelper.group({
      id: 'Users',
      header: () => 'Last data update',
      columns: [
        columnHelper.accessor('data.lastData.lastDataUpdateUser', {
          id: 'data.lastData.lastDataUpdateUser',
          header: () => 'User',
          cell: (info) =>
            info?.row?.original?.data?.lastData?.lastDataUpdateUser || '',
          enableSorting: true,
        }),
        columnHelper.accessor('data.lastData.lastDataUpdateApp', {
          id: 'data.lastData.lastDataUpdateApp',
          header: () => 'App',
          cell: (info) =>
            info?.row?.original?.data?.lastData?.lastDataUpdateApp || '',
          enableSorting: true,
        }),
        columnHelper.accessor('data.lastData.lastDataUpdateDevice', {
          id: 'data.lastData.lastDataUpdateDevice',
          header: () => 'Device',
          cell: (info) =>
            info?.row?.original?.data?.lastData?.lastDataUpdateDevice || '',
          enableSorting: true,
        }),
      ],
    }),
  ];
}

const mapTime = (totalMinutes) => {
  if (!totalMinutes) {
    return 'Error';
  }
  const hours = Math.floor(totalMinutes / 60);
  let minutes = totalMinutes % 60;
  if (minutes === 0) {
    minutes = '00';
  } else if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes}`;
};

export default tableHeaderStatsMedicine;
