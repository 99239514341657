import { createColumnHelper } from '@tanstack/react-table';
import CellEventLogAppId from './cells/CellEventLogAppId';
import CellReminderData from './cells/CellReminderData';

function tableHeaderMedicineIds() {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.accessor('uid', {
      id: 'uid',
      header: () => 'Medicine ID',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('appId', {
      id: 'appId',
      header: () => 'App ID',
      cell: (info) => {
        return <CellEventLogAppId appId={info.getValue()} />;
      },
      enableSorting: true,
    }),
    columnHelper.accessor('companyName', {
      id: 'companyName',
      header: () => 'Company',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('groupName', {
      id: 'groupName',
      header: () => 'Group',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('data.medicineBrand', {
      id: 'data.medicineBrand',
      header: () => 'Brand',
      cell: (info) => info.row.original?.data?.medicineBrand,
      enableSorting: true,
    }),
    columnHelper.accessor('data.medicineName', {
      id: 'data.medicineName',
      header: () => 'Name',
      cell: (info) => info.row.original?.data?.medicineName,
      enableSorting: true,
    }),
    columnHelper.accessor('data.medicineIcd', {
      id: 'data.medicineIcd',
      header: () => 'ICD',
      cell: (info) => info.row.original?.data?.medicineIcd,
      enableSorting: true,
    }),
    columnHelper.accessor('data.reminders', {
      id: 'data.reminders',
      header: () => 'reminders',
      //   cell: (info) => info.row.original?.data?.reminders,
      cell: (info) => {
        return (
          <CellReminderData reminderData={info.row.original?.data?.reminders} />
        );
      },

      enableSorting: true,
    }),
    columnHelper.accessor('data.start', {
      id: 'data.start',
      header: () => 'Start',
      cell: (info) => info.row.original?.data?.start,
      enableSorting: true,
    }),
    columnHelper.accessor('data.end', {
      id: 'data.end',
      header: () => 'End',
      cell: (info) => info.row.original?.data?.end,
      enableSorting: true,
    }),
    columnHelper.accessor('day', {
      id: 'day',
      header: () => 'Changed',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
  ];
}

export default tableHeaderMedicineIds;
