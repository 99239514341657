import { useState } from 'react';
import useAppDataContext from '../hooks/useAppDataContext';
import Table from '../components/table/Table';
import Spinner from '../components/Spinner';
import FilterA from '../components/filters/FilterA';
import Alert from '../components/Alert';
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import { FaDownload } from 'react-icons/fa';
import tableHeaderStatsCompanyUsers from '../components/table/tableHeaderStatsCompanyUsers';
import tableHeaderStatsCompanyMedicines from '../components/table/tableHeaderStatsCompanyMedicines';
import tableHeaderStatsUsers from '../components/table/tableHeaderStatsUsers';
import tableHeaderStatsMedicines from '../components/table/tableHeaderStatsMedicines';
import tableHeaderStatsMedicine from '../components/table/tableHeaderStatsMedicine';
import { useStatsDataSource } from '../hooks/useStatsDataSource';
import {
  exportStatsUsersByCompanyCsv,
  exportStatsMedicinesByCompanyCsv,
  prepareDataForStatsUsers,
  exportStatsUsersCsv,
  prepareDataForStatsMedicines,
  exportStatsMedicinesCsv,
  prepareDataForStatsMedicine,
  exportStatsMedicineCsv,
} from '../services/file.service';

import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';

function Stats() {
  logEvent(analytics, ANALYTICS_EVENT.page_view_stats);

  const { companies } = useAppDataContext();
  const {
    error,
    statsFilterObject,
    dataStatsCompanyUsers,
    dataStatsCompanyMedicines,
    dataStatsUsers,
    dataStatsMedicines,
    // dataStatsUser,
    dataStatsMedicine,
    isLoading,
    getDataUsers,
    getDataMedicines,
    setStatsFilterObject,
  } = useStatsDataSource();

  // Selected medicine data
  const [selectedMedicineData, setSelectedMedicineData] = useState(null);

  // Table  - sorting
  const [sortingStatsCompanyUsers, setSortingStatsCompanyUsers] = useState([]);
  const [sortingStatsCompanyMedicines, setSortingStatsCompanyMedicines] =
    useState([]);
  const [sortingStatsUsers, setSortingStatsUsers] = useState([]);
  const [sortingStatsMedicines, setSortingStatsMedicines] = useState([]);
  const [sortingStatsMedicine, setSortingStatsMedicine] = useState([]);

  // Table - create columns
  const columnsStatsCompanyUsers = tableHeaderStatsCompanyUsers();
  const columnsStatsCompanyMedicines = tableHeaderStatsCompanyMedicines();
  const columnsStatsUsers = tableHeaderStatsUsers();

  // Func to show Medicine day details
  const showMedicineIdDetails = (medicineId) => {
    const dataAvailable = String(medicineId) in dataStatsMedicine;
    if (dataAvailable === true) {
      setSelectedMedicineData(dataStatsMedicine[String(medicineId)]);
    } else {
      setSelectedMedicineData([]);
    }
  };
  const columnsStatsMedicines = tableHeaderStatsMedicines(
    showMedicineIdDetails,
  );
  const columnsStatsMedicine = tableHeaderStatsMedicine();

  // Table Stats Company Users - create table content
  const tableStatsCompanyUsers = useReactTable({
    data: dataStatsCompanyUsers,
    columns: columnsStatsCompanyUsers,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting: sortingStatsCompanyUsers,
    },
    onSortingChange: setSortingStatsCompanyUsers,
    getSortedRowModel: getSortedRowModel(),
  });

  // Table Stats Company Medicines - create table content
  const tableStatsCompanyMedicines = useReactTable({
    data: dataStatsCompanyMedicines,
    columns: columnsStatsCompanyMedicines,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting: sortingStatsCompanyMedicines,
    },
    onSortingChange: setSortingStatsCompanyMedicines,
    getSortedRowModel: getSortedRowModel(),
  });

  // Table Stats Users - create table content
  const tableStatsUsers = useReactTable({
    data: dataStatsUsers,
    columns: columnsStatsUsers,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting: sortingStatsUsers,
    },
    onSortingChange: setSortingStatsUsers,
    getSortedRowModel: getSortedRowModel(),
  });

  // Table Stats Medicines - create table content
  const tableStatsMedicines = useReactTable({
    data: dataStatsMedicines,
    columns: columnsStatsMedicines,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting: sortingStatsMedicines,
    },
    onSortingChange: setSortingStatsMedicines,
    getSortedRowModel: getSortedRowModel(),
  });

  // Table Medicine day details - create table content
  const tableStatsMedicine = useReactTable({
    data: selectedMedicineData,
    columns: columnsStatsMedicine,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting: sortingStatsMedicine,
    },
    onSortingChange: setSortingStatsMedicine,
    getSortedRowModel: getSortedRowModel(),
  });

  const runSearch = async () => {
    setSelectedMedicineData(null);
    await getDataUsers();
    await getDataMedicines();
  };

  // Config for FilterA component
  const filterConfig = {
    enabled: {
      isFreeText: true,
      isCompanies: true,
      isFromDate: true,
      isToDate: true,
    },
    filterMethods: {
      filterFree: {
        freeFilterPlaceHolder: 'Search by App ID',
      },
      filterGroups: {
        companies: companies,
      },
      filterDate: {
        limitDateRange: true,
      },
    },
  };

  const exportDataUsersByCompany = () => {
    exportStatsUsersByCompanyCsv(
      dataStatsCompanyUsers,
      'data-statsUsersByCompany',
    );
  };
  const exportDataMedicinesByCompany = () => {
    exportStatsMedicinesByCompanyCsv(
      dataStatsCompanyMedicines,
      'data-statsMedicinesByCompany',
    );
  };
  const exportDataStatsUsers = () => {
    const usersPreparedData = prepareDataForStatsUsers(dataStatsUsers);
    exportStatsUsersCsv(usersPreparedData, 'data-statsUsers');
  };
  const exportDataStatsMedicines = () => {
    const preparedData = prepareDataForStatsMedicines(dataStatsMedicines);
    exportStatsMedicinesCsv(preparedData, 'data-statsMedicines');
  };
  const exportDataStatsMedicine = () => {
    const preparedData = prepareDataForStatsMedicine(dataStatsMedicine);
    exportStatsMedicineCsv(preparedData, 'data-statsMedicine');
  };

  return (
    <div className='table-container'>
      <div className='table-menu-header'>
        <div>
          <h3>Stats</h3>
          <p>
            {dataStatsUsers.length} Users in total. {dataStatsMedicines.length}{' '}
            Medicines in total.
          </p>
        </div>
        <div className='table-menu-header-actions'>
          <button
            className={`btn-icon`}
            type='button'
            title='Download Users by Company'
            onClick={exportDataUsersByCompany}
          >
            <FaDownload />
          </button>
          <button
            className={`btn-icon`}
            type='button'
            title='Download Medicines by Company'
            onClick={exportDataMedicinesByCompany}
          >
            <FaDownload />
          </button>
          <button
            className={`btn-icon`}
            type='button'
            title='Download Stats by User'
            onClick={exportDataStatsUsers}
          >
            <FaDownload />
          </button>
          <button
            className={`btn-icon`}
            type='button'
            title='Download Stats by Medicine'
            onClick={exportDataStatsMedicines}
          >
            <FaDownload />
          </button>
          <button
            className={`btn-icon`}
            type='button'
            title='Download Stats Medicine by day'
            onClick={exportDataStatsMedicine}
          >
            <FaDownload />
          </button>
        </div>
      </div>

      <FilterA
        filterConfig={filterConfig}
        filterObject={statsFilterObject}
        updateFilterObject={setStatsFilterObject}
        triggerSearch={runSearch}
      />

      <Spinner isSpinning={isLoading}></Spinner>

      {error != null && (
        <div className='mtb-1'>
          <Alert type='error' title={error.message} content={error.cause} />
        </div>
      )}

      {dataStatsCompanyUsers.length > 0 && (
        <>
          <h3 className='mt-2'>Users by company</h3>
          <Table table={tableStatsCompanyUsers} />
          {dataStatsCompanyUsers.length < 1 && (
            <div className='table-no-data'>No Company Users App stats data</div>
          )}
        </>
      )}

      {dataStatsCompanyMedicines.length > 0 && (
        <>
          <h3 className='mt-2'>Medication behaviour by company</h3>
          <Table table={tableStatsCompanyMedicines} />
          {dataStatsCompanyMedicines.length < 1 && (
            <div className='table-no-data'>No Company medicines stats data</div>
          )}
        </>
      )}

      {dataStatsUsers.length > 0 && (
        <>
          <h3 className='mt-2'>Stats by Users</h3>
          <Table table={tableStatsUsers} />
          {dataStatsUsers.length < 1 && (
            <div className='table-no-data'>No Users stats data</div>
          )}
        </>
      )}

      {dataStatsMedicines.length > 0 && (
        <>
          <h3 className='mt-2'>Stats by Medicines</h3>
          <Table table={tableStatsMedicines} />
          {dataStatsMedicines.length < 1 && (
            <div className='table-no-data'>No Medicines stats data</div>
          )}
        </>
      )}

      {selectedMedicineData !== null && (
        <>
          <h3 className='mt-2'>Medicine data by day</h3>
          <Table table={tableStatsMedicine} />
          {selectedMedicineData.length < 1 && (
            <div className='table-no-data'>No Medicine day data</div>
          )}
        </>
      )}
    </div>
  );
}
export default Stats;
