import { useSearchParams } from 'react-router-dom';

export const useGetUrlParam = () => {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  const code = searchParams.get('oobCode');
  let urlParam = null;
  if (mode && code) {
    urlParam = {
      mode: mode,
      code: code,
    };
  }
  return urlParam;
};
