import { useState } from 'react';
import useAppDataContext from '../hooks/useAppDataContext';
import { useAuthContext } from '../hooks/useAuthContext';
import { useServiceDataSource } from '../hooks/useServiceDataSource';
import { getDevices } from '../services/device.service';
import Table from '../components/table/Table';
import Spinner from '../components/Spinner';
import TablePagination from '../components/table/TablePagination';
import SendPushMessageModal from '../components/pushMessage/SendPushMessageModal';
import MoveDeviceModal from '../components/devices/MoveDeviceModal';
import tableHeaderDevices from '../components/table/tableHeaderDevices';
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import { FaEnvelope, FaTrashAlt, FaArrowCircleRight } from 'react-icons/fa';
import FilterA from '../components/filters/FilterA';
import Alert from '../components/Alert';

import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';

function Devices() {
  logEvent(analytics, ANALYTICS_EVENT.page_view_devices);

  const { user } = useAuthContext();
  const { companies } = useAppDataContext();
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [sorting, setSorting] = useState([]);

  // DATA METHOD
  const deviceDataModifierFunc = (results, companies) => {
    for (let item of results.docs) {
      const matchedComp = companies.find((comp) => {
        return comp.id === item.companyId;
      });

      if (matchedComp) {
        item.companyName = matchedComp.name || 'not defined';
        const matchedGroup = matchedComp.groups.find((group) => {
          return group.id === item.groupId;
        });
        if (matchedGroup) {
          item.groupName = matchedGroup.name || 'not defined';
        }
      } else {
        item.companyName = '';
        item.groupName = '';
      }
    }
    return results;
  };
  const deviceDataModifierObject = {
    func: deviceDataModifierFunc,
    data: companies,
  };

  const {
    error,
    data,
    filterObject,
    isLoading,
    pageLastNumber,
    pageSize,
    totalDocs,
    loadMoreData,
    reloadData,
    updateFilterObject,
    updatePageSize,
  } = useServiceDataSource(
    getDevices.getData,
    getDevices.getQuery,
    getDevices.getDocsCount,
    getDevices.defaultFilterObject,
    deviceDataModifierObject,
    getDevices.autoload,
  );

  // Table row select functions
  const handleRowSelect = (deviceId) => {
    if (deviceId && deviceId > 0) {
      if (selectedDevices.includes(deviceId)) {
        setSelectedDevices(
          [...selectedDevices].filter((_deviceId) => _deviceId !== deviceId),
        );
      } else {
        setSelectedDevices([...selectedDevices, deviceId]);
      }
    }
  };

  // Table header select functions
  const handleHeaderSelect = () => {
    if (selectedDevices.length > 0) {
      setSelectedDevices([]);
    } else {
      setSelectedDevices(
        data.map((item) => {
          return item.deviceId || item.id;
        }),
      );
    }
  };

  // Table - create columns
  const columns = tableHeaderDevices(
    selectedDevices,
    data,
    handleRowSelect,
    handleHeaderSelect,
  );

  // Table - create table content
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
  });

  const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false);
  const [moveDeviceModalOpen, setMoveDeviceModalOpen] = useState(false);

  const loadMoreDataEnabled = () => {
    if (totalDocs === data.length) {
      return false;
    } else {
      return true;
    }
  };

  const closeMoveDeviceModal = (updateData) => {
    setMoveDeviceModalOpen(false);
    if (updateData === true) {
      reloadData();
    }
  };

  // Config for FilterA component
  const filterConfig = {
    enabled: {
      isFreeText: true,
      isCompanies: true,
      isFromDate: false,
      isToDate: false,
    },
    filterMethods: {
      filterFree: {
        freeFilterPlaceHolder: 'Search by device SN',
      },
      filterGroups: {
        companies: companies,
      },
      filterDate: {},
    },
  };

  return (
    <div className='table-container'>
      {sendMessageModalOpen && (
        <SendPushMessageModal
          sendMessageModalOpen={sendMessageModalOpen}
          setSendMessageModalOpen={setSendMessageModalOpen}
          selectedDevices={selectedDevices}
          publisher={user}
        />
      )}
      {moveDeviceModalOpen && (
        <MoveDeviceModal
          companies={companies}
          selectedDevices={selectedDevices}
          moveDeviceModalOpen={moveDeviceModalOpen}
          closeMoveDeviceModal={closeMoveDeviceModal}
        />
      )}
      <div className='table-menu-header'>
        <div>
          <h3>Devices</h3>
          <p>
            {' '}
            {totalDocs} devices
            {selectedDevices.length > 0
              ? ' - ' + selectedDevices.length + ' selected  '
              : ''}
            {selectedDevices.length > 0 && (
              <button
                type='button'
                className='btn-transparent'
                title='Clear selected devices'
                onClick={() => setSelectedDevices([])}
              >
                <FaTrashAlt />
              </button>
            )}
          </p>
        </div>
        <div className='table-menu-header-actions'>
          <button
            disabled={!selectedDevices.length}
            type='button'
            onClick={() => setMoveDeviceModalOpen(true)}
            className={`btn-icon`}
          >
            <FaArrowCircleRight />
          </button>
          <button
            disabled={!selectedDevices.length}
            type='button'
            onClick={() => setSendMessageModalOpen(true)}
            className={`btn-icon`}
          >
            <FaEnvelope />
          </button>
        </div>
      </div>

      <FilterA
        filterConfig={filterConfig}
        filterObject={filterObject}
        updateFilterObject={updateFilterObject}
        triggerSearch={reloadData}
      />

      <Spinner isSpinning={isLoading}></Spinner>

      {error != null && (
        <div className='mtb-1'>
          <Alert type='error' title={error.message} content={error.cause} />
        </div>
      )}

      {!isLoading && (
        <>
          <Table table={table} />

          {data.length < 1 && <div className='table-no-data'>No data</div>}

          <TablePagination
            pageCurrentNumber={Math.ceil(data.length / pageSize)}
            pageLastNumber={pageLastNumber}
            pageSize={pageSize}
            totalNumberOfDocs={totalDocs}
            changePageSize={(newSize) => {
              updatePageSize(newSize);
            }}
            loadMoreDataEnabled={loadMoreDataEnabled()}
            loadMoreData={loadMoreData}
          />
        </>
      )}
    </div>
  );
}

export default Devices;
