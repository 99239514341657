import AppDataContext from '../app/AppDataContext';
import { useContext } from 'react';

function useAppDataContext() {
  const context = useContext(AppDataContext);

  if (!context) {
    throw Error('useAuthContext must be used inside an AuthContextProvider');
  }

  return context;
}
export default useAppDataContext;
