import { createColumnHelper } from '@tanstack/react-table';
import CellStatsMedicinesMedicineId from './cells/CellStatsMedicinesMedicineId';
import PropTypes from 'prop-types';

function tableHeaderStatsMedicines(showMedicineIdDetails) {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.group({
      id: 'Users',
      header: () => 'User and Medicine',
      columns: [
        columnHelper.accessor('medicineId', {
          id: 'medicineId',
          header: () => 'Medicine ID',
          cell: (info) => {
            return (
              <CellStatsMedicinesMedicineId
                medicineId={info.getValue()}
                showDetails={showMedicineIdDetails}
              />
            );
          },
          enableSorting: true,
        }),
        columnHelper.accessor('data.appId', {
          id: 'data.appId',
          header: () => 'App ID',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.companyName', {
          id: 'data.companyName',
          header: () => 'Company',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.groupName', {
          id: 'data.groupName',
          header: () => 'Group',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.deviceId', {
          id: 'data.deviceId',
          header: () => 'Device',
          cell: (info) => info.getValue() || '',
          enableSorting: true,
        }),
      ],
    }),

    // medicineData
    columnHelper.group({
      id: 'Users',
      header: () => 'Medicine Info',
      columns: [
        // Medicine
        columnHelper.accessor('data.medicineName', {
          id: 'data.medicineName',
          header: () => 'Medicine',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.medicineIcd', {
          id: 'data.medicineIcd',
          header: () => 'ICD',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.medicineData.medicineStart', {
          id: 'data.data.medicineData.medicineStart',
          header: () => 'Created',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.medicineData.medicineEnd', {
          id: 'data.data.medicineData.medicineEnd',
          header: () => 'Deleted',
          cell: (info) =>
            info?.row?.original?.data?.data?.medicineData?.medicineEnd || '',
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.medicineData.useDaysMedicine', {
          id: 'data.data.medicineData.useDaysMedicine',
          header: () => 'Days Used',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),
    columnHelper.group({
      id: 'Users',
      header: () => 'Dose stats',
      columns: [
        columnHelper.accessor('data.data.medicineData.remainingDoses', {
          id: 'data.data.medicineData.remainingDoses',
          header: () => 'Remaining Doses',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.scoreData.daysSinceLastDose', {
          id: 'data.data.scoreData.daysSinceLastDose',
          header: () => 'Days Since Last Dose',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.scoreData.daysSinceLastDoseAvg', {
          id: 'data.data.scoreData.daysSinceLastDoseAvg',
          header: () => 'Days Since Last Dose Avg.',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.lastDoseDaysSinceNow', {
          id: 'data.data.lastDoseDaysSinceNow',
          header: () => 'Days since last update',
          cell: (info) => {
            const lastMedDay = Date.parse(info?.row?.original?.data?.day);
            if (lastMedDay) {
              const refDate = Date.now();
              const daysSinceNow = Math.round(
                (refDate - lastMedDay) / (1000 * 60 * 60 * 24),
              );
              return daysSinceNow;
            } else {
              return '';
            }
          },
          enableSorting: true,
        }),
      ],
    }),

    // scoreData - Streak
    columnHelper.group({
      id: 'Users',
      header: () => 'Streaks',
      columns: [
        columnHelper.accessor('data.data.scoreData.streakLenghtMax', {
          id: 'data.data.scoreData.streakLenghtMax',
          header: () => 'Max Length',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.scoreData.streakLenghtAvg', {
          id: 'data.data.scoreData.streakLenghtAvg',
          header: () => 'Avg. Length',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.scoreData.streakLength', {
          id: 'data.data.scoreData.streakLength',
          header: () => 'Latest Length',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),

    // scoreData - Adherence
    columnHelper.group({
      id: 'Users',
      header: () => 'Score (at date range end)',
      columns: [
        columnHelper.accessor('data.data.scoreData.scoreMonth', {
          id: 'data.data.scoreData.scoreMonth',
          header: () => 'Monthly',
          cell: (info) => (info.getValue() >= 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.scoreData.scoreWeek', {
          id: 'data.data.scoreData.scoreWeek',
          header: () => 'Weekly',
          cell: (info) => (info.getValue() >= 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.scoreData.scoreDay', {
          id: 'data.data.scoreData.scoreDay',
          header: () => 'Daily',
          cell: (info) => (info.getValue() >= 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.scoreData.adherence', {
          id: 'data.data.scoreData.adherence',
          header: () => 'Adherence',
          cell: (info) => (info.getValue() >= 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
      ],
    }),

    // DOSE
    columnHelper.group({
      id: 'Dose data',
      header: () => 'Dose event reported',
      columns: [
        columnHelper.accessor('medStats.doseBefore', {
          id: 'medStats.doseBefore',
          header: () => 'Before',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('medStats.doseAfter', {
          id: 'medStats.doseAfter',
          header: () => 'After',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('medStats.doseNoReminder', {
          id: 'medStats.doseNoReminder',
          header: () => 'No Reminder',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('medStats.doseNotSheduled', {
          id: 'medStats.doseNotSheduled',
          header: () => 'Not Sheduled',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('medStats.doseMultiple', {
          id: 'medStats.doseMultiple',
          header: () => 'Multiple',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('medStats.doseTotal', {
          id: 'medStats.doseTotal',
          header: () => 'Total taken',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('medStats.doseNotTaken', {
          id: 'medStats.doseNotTaken',
          header: () => 'Not Taken',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),
    // REMINDERS
    columnHelper.group({
      id: 'Reminder data',
      header: () => 'Number of Reminders',
      columns: [
        columnHelper.accessor('medStats.remindersMorning', {
          id: 'medStats.remindersMorning',
          header: () => 'Morning (03-09)',
          cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('medStats.remindersMidday', {
          id: 'medStats.remindersMidday',
          header: () => 'Midday (9-15)',
          cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('medStats.remindersEvening', {
          id: 'medStats.remindersEvening',
          header: () => 'Evening (15-21)',
          cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('medStats.remindersNight', {
          id: 'medStats.remindersNight',
          header: () => 'Night (21-03)',
          cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('medStats.remindersTotal', {
          id: 'medStats.remindersTotal',
          header: () => 'Total',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('medStats.remindersShow', {
          id: 'medStats.remindersShow',
          header: () => 'Shown',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('medStats.remindersPerDay', {
          id: 'medStats.remindersPerDay',
          header: () => 'Avg./day',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),
    // Last data
    columnHelper.group({
      id: 'Users',
      header: () => 'Last data update',
      columns: [
        columnHelper.accessor('data.data.lastData.lastDataUpdateUser', {
          id: 'data.data.lastData.lastDataUpdateUser',
          header: () => 'User',
          cell: (info) =>
            info?.row?.original?.data?.data?.lastData?.lastDataUpdateUser || '',
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.lastData.lastDataUpdateApp', {
          id: 'data.data.lastData.lastDataUpdateApp',
          header: () => 'App',
          cell: (info) =>
            info?.row?.original?.data?.data?.lastData?.lastDataUpdateApp || '',
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.lastData.lastDataUpdateDevice', {
          id: 'data.data.lastData.lastDataUpdateDevice',
          header: () => 'Device',
          cell: (info) =>
            info?.row?.original?.data?.data?.lastData?.lastDataUpdateDevice ||
            '',
          enableSorting: true,
        }),
      ],
    }),
  ];
}

tableHeaderStatsMedicines.propTypes = {
  showMedicineIdDetails: PropTypes.func.isRequired,
};

export default tableHeaderStatsMedicines;
