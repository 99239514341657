import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../app/config';
import { useAuthContext } from './useAuthContext';

export const useLogout = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const navigate = useNavigate();

  const logout = async () => {
    setError(null);
    setIsPending(true);

    try {
      await signOut(auth);
      dispatch({ type: 'LOGOUT', payload: null });
      navigate('/login');

      setError(null);
      setIsPending(false);
    } catch (err) {
      dispatch({ type: 'LOGOUT', payload: null });
      setError(err.message);
      setIsPending(false);
    }
  };

  return { error, isPending, logout };
};
