import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRegister } from '../hooks/useRegister';
import Alert from '../components/Alert';
import Spinner from '../components/Spinner';
import Logo from '../components/Logo';
import { MdEmail } from 'react-icons/md';

import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';

const MESSAGE_HELPER =
  'Get instructions sent to this email that explain how to reset your password';
const MESSAGE_ERROR = "Can't send email, please check entered email.";

function ForgotPassword() {
  logEvent(analytics, ANALYTICS_EVENT.forgot_password);

  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(null);

  const { isPending, error, resetPassword } = useRegister();

  const handleClick = async (e) => {
    e.preventDefault();

    const response = await resetPassword(email);
    if (error == null && response != null) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  };

  return (
    <div className='login-container'>
      <div className='login-container-image'>
        <main className='container mt-3'>
          <div className='row'>
            <div className='col-6 mx-auto col-max-width-m'>
              <div className='card-logo'>
                <Logo />
              </div>

              <div className='card'>
                <div className='card-body'>
                  <h3 className='card-title'>Recover password</h3>

                  {isPending && <Spinner isSpinning={isPending}></Spinner>}

                  {success === true ? (
                    <div className='mtb-1'>
                      <Alert
                        type='success'
                        content='Password recovery email sent. Check your email for details.'
                      />
                    </div>
                  ) : (
                    <>
                      <div>
                        {success === null ? (
                          MESSAGE_HELPER
                        ) : (
                          <div className='mtb-1'>
                            <Alert type='error' content={MESSAGE_ERROR} />
                          </div>
                        )}
                      </div>

                      <form className='form-body'>
                        <div className='row'>
                          <div className='col-12 mt-1'>
                            <label className='form-label' htmlFor='email'>
                              Email address *
                            </label>
                            <div>
                              <div className='input-icon'>
                                <MdEmail />
                              </div>
                              <input
                                type='email'
                                onChange={(event) => {
                                  setEmail(event.target.value);
                                }}
                                value={email}
                                className='form-control'
                                id='email'
                                placeholder='Email address'
                                required
                              />
                            </div>
                          </div>
                          <div className='col-12 mt-1'>
                            <button
                              className='btn btn-add-flex'
                              id='email'
                              disabled={isPending || !email}
                              onClick={handleClick}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 text-end mt-1'>
                            <NavLink
                              to={'/login'}
                              className={() => 'navlink-custom'}
                            >
                              Login
                            </NavLink>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ForgotPassword;
