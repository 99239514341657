import { createColumnHelper } from '@tanstack/react-table';

function tableHeaderStatsCompanyUsers() {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.group({
      id: 'Users',
      header: () => 'Users',
      // header: (props) => {
      //   console.log(props);
      //   return 'Users';
      // },
      columns: [
        columnHelper.accessor('companyName', {
          id: 'companyName',
          header: () => 'Company',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('appIds', {
          id: 'appIds',
          header: () => 'App IDs',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('usersActive', {
          id: 'usersActive',
          header: () => 'Active',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),

        columnHelper.accessor('usersNew', {
          id: 'usersNew',
          header: () => 'New',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('usersStopped', {
          id: 'usersStopped',
          header: () => 'Stopped',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),
    columnHelper.group({
      id: 'retention',
      header: () => 'Usage by days',
      columns: [
        columnHelper.accessor('usersUse14d', {
          id: 'usersUse14d',
          header: () => '14d',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('usersUse30d', {
          id: 'usersUse30d',
          header: () => '30d',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('usersUse60d', {
          id: 'usersUse60d',
          header: () => '60d',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('usersUse90d', {
          id: 'usersUse90d',
          header: () => '90d',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),

    // Views
    columnHelper.group({
      id: 'appViews',
      header: () => <span>App page views</span>,
      columns: [
        columnHelper.accessor('viewsIntro', {
          id: 'viewsIntro',
          header: () => 'Intro',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('viewsAppMsg', {
          id: 'viewsAppMsg',
          header: () => 'AppMsg',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('viewsPrivacy', {
          id: 'viewsPrivacy',
          header: () => 'PP',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('viewsTerms', {
          id: 'viewsTerms',
          header: () => 'ToC',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('viewsFaq', {
          id: 'viewsFaq',
          header: () => 'Faq',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),
  ];
}

export default tableHeaderStatsCompanyUsers;
