import NavBar from '../navbar/NavBar';
import PropTypes from 'prop-types';

function PageLayout({ children }) {
  return (
    <>
      <NavBar>{children}</NavBar>
    </>
  );
}

PageLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PageLayout;
