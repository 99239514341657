import { createColumnHelper } from '@tanstack/react-table';
import CellEventLogDeivceId from './cells/CellEventLogDeivceId';
import PropTypes from 'prop-types';

function tableHeaderDevices(
  selectedDevices,
  data,
  handleRowSelect,
  handleHeaderSelect,
) {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.accessor('select', {
      id: 'select',
      cell: (info) => {
        const _deviceId = info.row.original.deviceId || info.row.original.id;
        return (
          <input
            type='checkbox'
            checked={selectedDevices.includes(_deviceId)}
            onChange={() => {
              handleRowSelect(_deviceId);
            }}
          />
        );
      },
      header: () => (
        <input
          type='checkbox'
          disabled={data.length < 1}
          checked={data.length > 0 && selectedDevices.length === data.length}
          onChange={() => {
            handleHeaderSelect();
          }}
        />
      ),
      enableSorting: false,
      firebaseSortField: 'deviceId',
    }),
    columnHelper.accessor('deviceId', {
      id: 'deviceId',
      cell: (info) => {
        return (
          <CellEventLogDeivceId
            deviceId={
              Number(info.row.original.deviceId) || Number(info.row.original.id)
            }
          />
        );
      },
      header: () => 'Device',
      enableSorting: true,
    }),
    columnHelper.accessor('companyName', {
      id: 'companyName',
      header: () => 'Company',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('groupName', {
      id: 'groupName',
      header: () => 'Group',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('appId', {
      id: 'appId',
      header: () => 'App ID',
      cell: (info) => info.row.original.appId,
      enableSorting: true,
    }),
    columnHelper.accessor('status.batteryLevelPercent', {
      id: 'status.batteryLevelPercent',
      header: () => 'Battery',
      cell: (info) => {
        const batValue =
          info.row.original?.status?.batteryLevelPercent || 'null';
        if (
          typeof +batValue == 'number' &&
          batValue !== 'null' &&
          batValue !== '-' &&
          batValue !== undefined
        ) {
          if (batValue >= 0 && batValue <= 100) {
            return batValue + '%';
          } else {
            return '-';
          }
        } else {
          return '';
        }
      },
      enableSorting: true,
    }),
    columnHelper.accessor('status.lastPopitDataUpdate', {
      id: 'lastPopitDataUpdate',
      cell: (info) => {
        const lastUpdate = info.row.original.status?.lastPopitDataUpdate;
        if (
          typeof +lastUpdate == 'number' &&
          lastUpdate !== 'null' &&
          lastUpdate !== '-' &&
          lastUpdate !== undefined
        ) {
          return new Date(lastUpdate * 1000).toLocaleDateString();
        } else {
          return '';
        }
      },
      header: () => 'Last update',
      enableSorting: true,
      firebaseSortField: 'status.lastPopitDataUpdate',
    }),
    columnHelper.accessor('status.firmwareVersion', {
      id: 'firmwareVersion',
      // cell: (info) => info.getValue(),
      cell: (info) => info.row.original.status?.firmwareVersion || '',
      header: () => 'FW version',
      enableSorting: true,
    }),
  ];
}

tableHeaderDevices.propTypes = {
  selectedDevices: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  handleRowSelect: PropTypes.func.isRequired,
  handleHeaderSelect: PropTypes.func.isRequired,
};

export default tableHeaderDevices;
