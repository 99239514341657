export class ValidationError extends Error {
  // Some update ss
  constructor(message, cause) {
    super(message);
    this.name = 'ValidationError';
    this.cause = cause;
  }
}
export class FirebaseError extends Error {
  constructor(message, cause) {
    super(message);
    this.name = 'FirebaseError';
    this.cause = cause;
  }
}
export class ServiceError extends Error {
  constructor(message, cause) {
    super(message);
    this.name = 'ServiceError';
    this.cause = cause;
  }
}
export class HttpError extends Error {
  constructor(message, statusCode, data) {
    super(message);
    this.statusCode = statusCode;
    this.data = data;
  }
}
