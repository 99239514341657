import { createContext, useEffect, useReducer } from 'react';
import { auth } from './config';
import { getData, addDataWithUid } from '../services/data.utils';
import { useThemeContext } from '../hooks/useThemeContext';
import { USER_DATA_DEFAULTS as userDataDefaults } from '../shared/Constants';
import PropTypes from 'prop-types';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return { ...state, user: null };
    case 'VERIFY_EMAIL':
      return { ...state, user: null };
    case 'LOGIN':
      return { ...state, user: { ...state.user, ...action.payload } };
    case 'AUTH_IS_READY':
      return { ...state, authIsReady: true };
    default:
      return { ...state };
  }
};

const initialState = {
  authIsReady: false,
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { theme, switchTheme } = useThemeContext();

  // Get admin status from user token custom claims
  const getIsAdmin = async (user) => {
    if (user === undefined || user === null) {
      return false;
    }
    const { claims } = await user.getIdTokenResult();
    return claims?.admin || false;
  };

  const updateIsAdminState = async (user) => {
    const isAdmin = await getIsAdmin(user);
    if (isAdmin) {
      // append user data with admin property
      dispatch({
        type: 'LOGIN',
        payload: {
          admin: true,
        },
      });
    }
  };

  // Create new user in 'users' collection
  const createNewUserDoc = async (user) => {
    const userData = {
      ...userDataDefaults,
      email: user.email,
      displayName: user.displayName,
    };
    const updateResult = await addDataWithUid('users', user.uid, userData);
    if (!updateResult.result) {
      console.log('Error: not able to create user document');
    }
    return;
  };

  const updateUserDataState = async (user) => {
    try {
      // Get user data from 'users' collection
      const docData = await getData('users', user.uid);
      if (docData) {
        const docDisplayName = docData?.displayName || '';
        const newDisplayName =
          docDisplayName !== '' && docDisplayName !== user.displayName
            ? docDisplayName
            : '';
        const docTheme = docData?.theme || theme;
        const newTheme = docTheme !== theme ? docTheme : '';
        if (newDisplayName !== '' || newTheme !== '') {
          const userData =
            newDisplayName === ''
              ? {
                  theme: newTheme,
                }
              : {
                  displayName: newDisplayName,
                  theme: newTheme,
                };
          // Append user data with Firestore parameters
          dispatch({ type: 'LOGIN', payload: userData });

          // Set theme is not the current one
          if (newTheme !== '' && newTheme !== theme) {
            switchTheme();
          }
        }
      } else {
        // No user document, create new
        await createNewUserDoc(user);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: 'LOGOUT', payload: null });
    }
  };

  const getInitialUserDataState = (user) => {
    return {
      displayName: user.displayName,
      email: user.email,
      userRawData: user,
      theme: theme,
    };
  };

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      dispatch({ type: 'AUTH_IS_READY', payload: null });
      if (user && user.emailVerified === true) {
        const initialUserData = getInitialUserDataState(user);
        // dispatch initial data
        dispatch({
          type: 'LOGIN',
          payload: { ...initialUserData },
        });
        // update admin property (async)
        updateIsAdminState(user);
        // update data from Firestore and create uer doc if not available
        updateUserDataState(user);
      } else if (user) {
        dispatch({ type: 'VERIFY_EMAIL', payload: null });
      } else {
        dispatch({ type: 'LOGOUT', payload: null });
      }
      unsub(); // unsubscribe onAuthStateChanged
    });

    // NOTE: warning silenced in purpose. This "theme" and "switchTheme"
    // are managed in ThemeContext and should not trigger AuthContext useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        dispatch,
        updateIsAdminState,
        updateUserDataState,
        getInitialUserDataState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
