import { useState, useEffect } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLogout } from '../../hooks/useLogout';
import preval from 'preval.macro';
import Logo from '../Logo';
import { useThemeContext } from '../../hooks/useThemeContext';
import { isDevEnv, envNameByProjectId } from '../../utils/utils';
import {
  IoHome,
  IoPerson,
  IoLogOut,
  IoMenu,
  IoClose,
  IoGameController,
  // IoBarChart,
  IoRocket,
  IoPeople,
  IoBusiness,
  IoMail,
  IoBody,
  // IoFlask,
  IoMedical,
  IoFitness,
  IoChatbubbleEllipsesOutline,
  IoChatbubbles,
  IoCloudy,
} from 'react-icons/io5';

function NavBar({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [navMenuListClass, setNavMenuListClass] = useState('nav-menu-list');
  const [activePath, setActivePath] = useState('');
  const location = useLocation();
  // const navigate = useNavigate();
  const { logout } = useLogout();
  const { switchTheme } = useThemeContext();

  // Update nav menu class to include responsive when menu is opened
  useEffect(() => {
    setNavMenuListClass(
      !isMenuOpen ? 'nav-menu-list' : 'nav-menu-list responsive',
    );
  }, [isMenuOpen]);

  // Set active page path
  useEffect(() => {
    if (location?.pathname) {
      setActivePath(location.pathname);
    }
  }, [location]);

  // Close responsive menu when selecting item
  useEffect(() => {
    setIsMenuOpen(false);
  }, [activePath]);

  // Open / close mobile menu when tapping menu icon
  const toggleDropdown = () => {
    setIsMenuOpen((prevState) => {
      return !prevState;
    });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    switch (e.currentTarget.id) {
      case 'logout':
        logout();
        break;
      case 'home':
        switchTheme();
        // navigate('/');
        break;
      default:
        console.log('Undefined click');
        break;
    }
  };

  let menuItems = [
    {
      name: 'Home',
      path: '/',
      icon: <IoHome />,
      type: 'navlink',
      action: null,
    },
    {
      name: 'Test',
      path: '/test',
      icon: <IoGameController />,
      type: 'navlink',
      action: null,
    },
    {
      name: 'App IDs',
      path: '/app-ids',
      icon: <IoBody />,
      type: 'navlink',
      action: null,
    },
    {
      name: 'Medicine IDs',
      path: '/medicine-ids',
      icon: <IoMedical />,
      type: 'navlink',
      action: null,
    },
    {
      name: 'Stats',
      path: '/stats',
      icon: <IoFitness />,
      type: 'navlink',
      action: null,
    },
    {
      name: 'Event log',
      path: '/event-log',
      icon: <IoChatbubbleEllipsesOutline />,
      type: 'navlink',
      action: null,
    },
    {
      name: 'Devices',
      path: '/devices',
      icon: <IoRocket />,
      type: 'navlink',
      action: null,
    },
    {
      name: 'Users',
      path: '/admin/users',
      icon: <IoPeople />,
      type: 'navlink',
    },
    {
      name: 'Groups',
      path: '/admin/groups',
      icon: <IoBusiness />,
      type: 'navlink',
      action: null,
    },
    {
      name: 'Push Messages',
      path: '/admin/push-messages',
      icon: <IoChatbubbles />,
      type: 'navlink',
      action: null,
    },
    {
      name: 'Messages',
      path: '/admin/messages',
      icon: <IoMail />,
      type: 'navlink',
      action: null,
    },
    {
      name: 'Profile',
      path: '/account',
      icon: <IoPerson />,
      type: 'navlink',
      action: null,
    },
    // {
    //   name: 'App Status',
    //   path: '/app-status',
    //   icon: <IoFlask />,
    //   type: 'navlink',
    //   action: null,
    // },
    // {
    //   name: 'Old event data',
    //   path: '/old-event-data',
    //   icon: <IoBarChart />,
    //   type: 'navlink',
    //   action: null,
    // },
    {
      name: 'Logout',
      path: '/logout',
      icon: <IoLogOut />,
      type: 'button',
      action: handleClick,
    },
  ];

  // add cloud server option if dev or localhost env
  if (isDevEnv()) {
    menuItems.push({
      name: 'Cloud Server',
      path: '/admin/cloud-server',
      icon: <IoCloudy />,
      type: 'navlink',
      action: null,
    });
  }

  const navBarItem = (name, icon) => {
    return (
      <div className='navbar-item'>
        <div className='navbar-item-icon'>{icon}</div>
        <div className='navbar-item-name'>{name}</div>
      </div>
    );
  };

  const navBarTop = () => {
    return (
      <div className='navbar-top'>
        <ul className='navbar-items-top'>
          <li className='navbar-item-icon'>
            <NavLink className='link' key='account' to='/account'>
              <div className='navbar-item'>
                <IoPerson />
              </div>
            </NavLink>
          </li>
          <li className='navbar-item-icon'>
            <button
              className='btn-transparent'
              type='button'
              id='logout'
              onClick={handleClick}
            >
              <div className='navbar-item'>
                <IoLogOut />
              </div>
            </button>
          </li>
        </ul>
      </div>
    );
  };

  const navBarItemNavlink = (name, path, icon) => {
    return (
      <li
        key={`nav-link-${name}`}
        className={`nav-item ${activePath === path ? 'is-active' : ''}`}
      >
        <NavLink to={path} className='nav-link'>
          {navBarItem(name, icon)}
        </NavLink>
      </li>
    );
  };
  const navBarItemButton = (name, icon, action) => {
    return (
      <li key={`nav-link-${name}`} className={`nav-item`}>
        <button
          className='nav-button'
          type='button'
          id='logout'
          onClick={action}
        >
          {navBarItem(name, icon)}
        </button>
      </li>
    );
  };

  const navBarAppVersion = () => {
    return (
      <>
        <div className='navbar-app-version'>
          <div>Popit v{process.env.REACT_APP_VERSION}</div>
          <div>
            Build Date:{' '}
            {preval`module.exports = new Date().toISOString().slice(0, 10);`}
          </div>
        </div>
      </>
    );
  };

  const navBarContent = () => {
    return (
      <div className='navbar'>
        <div className='navbar-logo-container'>
          <div className='navbar-logo-img' id='home' onClick={handleClick}>
            <Logo />
          </div>
          <div className='navbar-logo-text'>
            <div>{envNameByProjectId()}</div>
          </div>
        </div>
        <div className='nav-menu'>
          <ul className={navMenuListClass}>
            {/* Navigation items */}
            {menuItems.map(({ name, path, icon, type, action }) => {
              switch (type) {
                case 'navlink':
                  return navBarItemNavlink(name, path, icon);
                case 'button':
                  return navBarItemButton(name, icon, action);
                default:
                  return null;
              }
            })}
            {/* Mobile menu icon */}
            <li className='dropdownLink'>
              <button className='btn-transparent' onClick={toggleDropdown}>
                {!isMenuOpen ? <IoMenu /> : <IoClose />}
              </button>
            </li>
          </ul>
        </div>
        {navBarAppVersion()}
      </div>
    );
  };

  return (
    <div className='wrapper-main'>
      {navBarContent()}
      <div className='container-main'>
        {navBarTop()}
        <div className='container px-1'>{children}</div>
      </div>
    </div>
  );
}

NavBar.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NavBar;
