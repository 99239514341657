import { useState, useEffect } from 'react';
import { useRegister } from '../hooks/useRegister';
import { NavLink } from 'react-router-dom';
import { useGetUrlParam } from '../hooks/useGetUrlParam';
import Spinner from '../components/Spinner';
import Alert from '../components/Alert';
import Logo from '../components/Logo';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { MdLock } from 'react-icons/md';

function Verify() {
  const { mode, code } = useGetUrlParam();

  const {
    error,
    isPending,
    resetPasswordVerifyCode,
    resetPasswordSetNew,
    verifyEmail,
  } = useRegister();
  const [verified, setVerified] = useState(null);
  const [completed, setCompleted] = useState(null);
  const [emailVerified, setEmailVerified] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [showPasword, setShowPassword] = useState(false);

  // Handle reset password functions
  // Step 1: Verify code and email
  const handleResetPassword = async (code) => {
    const userEmail = await resetPasswordVerifyCode(code);
    if (!error && userEmail) {
      setVerified(true);
    } else {
      setVerified(false);
    }
  };

  // Step 2: Set new password
  const submitNewPassword = async () => {
    const result = await resetPasswordSetNew(newPassword, code);
    if (result) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
  };

  // Email verification handler
  const handleVerifyEmail = async (code) => {
    const result = await verifyEmail(code);
    if (result) {
      setEmailVerified(true);
    } else {
      setEmailVerified(false);
    }
  };

  // Handle the code action.
  const runVerification = (code) => {
    switch (mode) {
      case 'resetPassword':
        handleResetPassword(code);
        break;
      case 'verifyEmail':
        handleVerifyEmail(code);
        break;
      default:
        console.log('Error: invalid mode.');
    }
  };

  useEffect(() => {
    runVerification(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <div className='login-container'>
      <div className='login-container-image'>
        <main className='container mt-3'>
          <div className='row'>
            <div className='col-6 mx-auto col-max-width-m'>
              <div className='card-logo'>
                <Logo />
              </div>

              <div className='card'>
                <div className='card-body'>
                  {emailVerified === false && (
                    <>
                      <h3 className='card-title'>Email verification</h3>
                      <div className='mtb-1'>
                        <Alert
                          type='error'
                          content={error}
                          title='Email verification failed'
                        />
                      </div>
                    </>
                  )}

                  {emailVerified === true && (
                    <>
                      <h3 className='card-title'>Email verification</h3>
                      <div className='mtb-1'>
                        <Alert
                          type='success'
                          content='Please login to service'
                          title='Email verification completed'
                        />
                      </div>
                    </>
                  )}

                  {completed === false && (
                    <>
                      <h3 className='card-title'>Reset password</h3>
                      <div className='mtb-1'>
                        <Alert
                          type='error'
                          content={error}
                          title='Password reset failed'
                        />
                      </div>
                    </>
                  )}

                  {verified === false && (
                    <>
                      <h3 className='card-title'>Reset password</h3>
                      <div className='mtb-1'>
                        <Alert
                          type='error'
                          content={error}
                          title='Reset code error'
                        />
                      </div>
                    </>
                  )}

                  {verified === true &&
                    completed !== true &&
                    EnterNewPassword(
                      newPassword,
                      setNewPassword,
                      submitNewPassword,
                      showPasword,
                      setShowPassword,
                      isPending,
                    )}

                  {completed === true && (
                    <>
                      <h3 className='card-title'>Reset password</h3>
                      <Alert
                        type='success'
                        content='Please login to service.'
                        title='Password reseted'
                      />
                    </>
                  )}

                  <div className='row mt-1'>
                    <div className='col-12 text-end'>
                      <NavLink to={'/login'} className={() => 'navlink-custom'}>
                        Login
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

function EnterNewPassword(
  newPassword,
  setNewPassword,
  submitNewPassword,
  showPasword,
  setShowPassword,
  isPending,
) {
  return (
    <div className='row'>
      <div className='col-12 mt-1'>
        <h3 className='card-title'>Enter new password</h3>

        {isPending && <Spinner isSpinning={isPending}></Spinner>}

        <label className='form-label' htmlFor='password'>
          Password *
        </label>
        <div className='passwordInputDiv'>
          <div>
            <div className='input-icon'>
              <MdLock />
            </div>
            <input
              type={showPasword ? 'text' : 'password'}
              id='password'
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
              value={newPassword}
              className='form-control'
              placeholder='New password'
            />
            <div className='showPassword'>
              {showPasword === false ? (
                <HiEye
                  alt='show password'
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              ) : (
                <HiEyeOff
                  alt='show password'
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='col-12 mt-1'>
        <button
          className='btn btn-add-flex'
          disabled={isPending || !newPassword}
          onClick={(e) => {
            e.preventDefault();
            submitNewPassword();
          }}
        >
          Reset Password
        </button>
      </div>
    </div>
  );
}

export default Verify;
