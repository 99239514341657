import {
  collection,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  where,
  startAfter,
} from 'firebase/firestore';
import { db } from '../app/config';
import PropTypes from 'prop-types';
import { FirebaseError, ServiceError } from '../utils/errors';
import { trace } from 'firebase/performance';
import { perf } from '../app/config';
import {
  PERF_TRACE_NAME,
  PERF_TRACE_VALUE,
  PERF_TRACE_PRAM_NAME,
} from '../shared/Constants';

export const createDataFecthMethod = (
  collectionName,
  collectionMainDataField,
  freeFilterField,
  freeFilterFieldType,
  freeFilterQuery,
  dayFilterField,
  defaultSortDirection,
  maxCount,
  autoload,
) => {
  const defaultFilterObject = {
    mainDataField: collectionMainDataField,
    freeFilterField: freeFilterField,
    freeFilterFieldType: freeFilterFieldType,
    freeFilterQuery: freeFilterQuery,
    freeFilter: '',
    sortDirection: defaultSortDirection,
    selectedCompanies: [],
    selectedGroups: [],
    dateStart: '',
    dateEnd: '',
  };

  const getData = async (
    pageSize,
    filterObject,
    lastVisibleDoc,
    setLastVisibleDoc,
    loadMore,
  ) => {
    const [snapshotData, deviceCount] = await Promise.all([
      getSnapshotData(
        pageSize,
        filterObject,
        lastVisibleDoc,
        setLastVisibleDoc,
        loadMore,
      ),
      getDocsCount(filterObject),
    ]);

    let results = {
      docs: snapshotData.docs.map((doc) => {
        return { ...doc.data(), uid: doc.id };
      }),
      total: deviceCount,
    };

    return results;
  };

  const getSnapshotData = async (
    pageSize,
    filterObject,
    lastVisibleDoc,
    setLastVisibleDoc,
    loadMore,
  ) => {
    const t = trace(perf, PERF_TRACE_NAME.fbFetchDataTiming);
    t.start();
    try {
      const dataQuery = getQuery(
        pageSize,
        filterObject,
        lastVisibleDoc,
        loadMore,
      );
      const querySnapshot = await getDocs(dataQuery);

      t.putAttribute(PERF_TRACE_PRAM_NAME.queryCollection, collectionName);
      t.incrementMetric(PERF_TRACE_VALUE.docsCount, querySnapshot.size);

      // Set last visible doc for load more data
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisibleDoc(lastVisible);
      t.stop();
      return querySnapshot;
    } catch (error) {
      t.stop();
      throw new FirebaseError(
        'Firebase getSnapshotData error createDataFecthMethod',
        error.message,
      );
    }
  };

  const getDocsCount = async (filterObject) => {
    const countQuery = getQuery(maxCount, filterObject, null, false);

    try {
      const snapshot = await getCountFromServer(countQuery);
      if (snapshot.data().count >= maxCount) {
        throw new ServiceError(
          'Stats service result max limit',
          `Query results hit max ${maxCount} limit ${collectionName}. Seach total result count: ${
            snapshot.data().count
          }. Make a search with more limited parameters.`,
        );
      } else {
        return snapshot.data().count;
      }
    } catch (error) {
      if (error instanceof ServiceError) {
        throw error;
      } else {
        throw new FirebaseError(
          'Firebase getDocsCount error createDataFecthMethod',
          error.message,
        );
      }
    }
  };

  const getQuery = (pageSize, filterObject, lastVisibleDoc, loadMore) => {
    const collectionRef = collection(db, collectionName);
    const queryConstraints = [];
    let dataQuery;

    if (filterObject?.freeFilter && filterObject.freeFilter.length > 0) {
      //===== FREE TEXT filter

      // Remove spaces from string and split into an array
      let tempFilter = { ...filterObject };
      tempFilter = filterObject.freeFilter.replaceAll(/\s/g, '').split(',');
      // Default type is string
      // Check if filter array should be converted to number
      if (freeFilterFieldType === 'number') {
        tempFilter = tempFilter.map((item) => Number(item));
      }
      if (tempFilter.length > 0 && tempFilter.length <= 10) {
        // queryConstraints.push(where(filterObject.mainDataField, '!=', false));
        queryConstraints.push(
          where(
            filterObject.freeFilterField,
            filterObject.freeFilterQuery,
            tempFilter,
          ),
        );
        // if query for Messages => sort results absed on created
        if (collectionName === 'messages') {
          queryConstraints.push(
            orderBy(dayFilterField, filterObject.sortDirection),
          );
        }
      } else {
        // TODO: handle lager queries
        console.log('TODO: search not supported');
        throw new ServiceError(
          'Data service getQuery error',
          'Free filter has +10 items',
        );
      }
    } else {
      if (
        //===== GROUP filtering
        filterObject?.selectedCompanies &&
        filterObject.selectedCompanies.length > 0
      ) {
        if (
          filterObject?.selectedGroups &&
          filterObject.selectedGroups.length > 0
        ) {
          // Company AND Group filter
          queryConstraints.push(
            where('companyId', '==', filterObject.selectedCompanies[0]),
          );
          queryConstraints.push(
            where('groupId', '==', filterObject.selectedGroups[0]),
          );
        } else {
          // Company filter
          queryConstraints.push(
            where('companyId', '==', filterObject.selectedCompanies[0]),
          );
        }
      } else {
        //===== DEFAULT filtering
        queryConstraints.push(
          orderBy(filterObject.mainDataField, filterObject.sortDirection),
        );
      }
    }

    //===== LOAD MORE add startAfter
    if (loadMore && lastVisibleDoc) {
      queryConstraints.push(startAfter(lastVisibleDoc));
    }

    //===== LIMIT number of results
    queryConstraints.push(limit(pageSize));

    dataQuery = query(collectionRef, ...queryConstraints);
    return dataQuery;
  };

  return { getData, getQuery, getDocsCount, defaultFilterObject, autoload };
};

createDataFecthMethod.propTypes = {
  collectionName: PropTypes.oneOf([
    'companies',
    'devices',
    'messages',
    'users',
  ]),
  collectionMainDataField: PropTypes.oneOf([
    'deviceId',
    'created',
    'displayName',
  ]),
  freeFilterField: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  freeFilterQuery: PropTypes.oneOf(['in', '==', 'array-contains-any']),
  dayFilterField: PropTypes.oneOf(['day', 'eventTimestamp']),
  defaultSortDirection: PropTypes.oneOf(['asc', 'desc']),
  maxCount: PropTypes.number,
  autoload: PropTypes.bool,
};
