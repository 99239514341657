import { useState } from 'react';
import { useRegister } from '../hooks/useRegister';
import { NavLink } from 'react-router-dom';
import Spinner from '../components/Spinner';
import Alert from '../components/Alert';
import Logo from '../components/Logo';
import { MdEmail, MdLock } from 'react-icons/md';
import { FaUser } from 'react-icons/fa';
import { HiEye, HiEyeOff } from 'react-icons/hi';

import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';

function Register() {
  logEvent(analytics, ANALYTICS_EVENT.sign_up);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    displayName: '',
  });
  const [registerCompleted, setRegisterCompleted] = useState(null);
  const { error, isPending, register } = useRegister();
  const [showPasword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await register(
      formData.email,
      formData.password,
      formData.displayName,
    );
    setRegisterCompleted(result);
  };

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  return (
    <div className='login-container'>
      <div className='login-container-image'>
        <main className='container mt-3'>
          <div className='row'>
            <div className='col-6 mx-auto col-max-width-m'>
              <div className='card-logo'>
                <Logo />
              </div>

              <div className='card'>
                <div className='card-body'>
                  <h3 className='card-title'>Create an account</h3>
                  <div className='mt-1'>
                    {isPending && <Spinner isSpinning={isPending}></Spinner>}

                    {registerCompleted === false && (
                      <div className='mtb-1'>
                        <Alert
                          type='error'
                          title='Account creation'
                          content={error}
                        />
                      </div>
                    )}
                    {registerCompleted === true && (
                      <div className='mtb-1'>
                        <Alert
                          type='success'
                          title='Account created'
                          content='You need to verify your email address before the account is activated. Please check your email and follow the instructions.'
                        />
                      </div>
                    )}

                    {registerCompleted === null &&
                      RegisterUser(
                        formData,
                        handleChange,
                        isPending,
                        handleSubmit,
                        showPasword,
                        setShowPassword,
                      )}
                  </div>

                  <div className='col-12 text-end mt-1'>
                    <NavLink to={'/login'} className='navlink-custom'>
                      Login
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

function RegisterUser(
  formData,
  handleChange,
  isPending,
  handleSubmit,
  showPasword,
  setShowPassword,
) {
  return (
    <form className='form-body mt-1' onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-12'>
          <label className='form-label' htmlFor='displayName'>
            First & last name *
          </label>
          <div>
            <div className='input-icon'>
              <FaUser />
            </div>
            <input
              type='displayName'
              onChange={handleChange}
              value={formData.displayName}
              className='form-control'
              id='displayName'
              placeholder='First and last name'
              required
            />
          </div>
        </div>

        <div className='col-12 mt-1'>
          <label className='form-label' htmlFor='email'>
            Email address *
          </label>
          <div>
            <div className='input-icon'>
              <MdEmail />
            </div>
            <input
              type='email'
              onChange={handleChange}
              value={formData.email}
              className='form-control'
              id='email'
              placeholder='Email address'
              required
            />
          </div>
        </div>

        <div className='col-12 mt-1'>
          <label className='form-label' htmlFor='password'>
            Password *
          </label>
          <div className='passwordInputDiv'>
            <div>
              <div className='input-icon'>
                <MdLock />
              </div>
              <input
                type={showPasword ? 'text' : 'password'}
                onChange={handleChange}
                value={formData.password}
                className='form-control'
                id='password'
                placeholder='Password'
              />
            </div>
            <div className='showPassword'>
              {showPasword === false ? (
                <HiEye
                  title='show password'
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              ) : (
                <HiEyeOff
                  title='hide password'
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='row mt-1'>
        <div className='col-12'>
          <button
            className='btn btn-add-flex'
            disabled={
              isPending ||
              !(formData.email && formData.password && formData.displayName)
            }
          >
            Register
          </button>
        </div>
      </div>
    </form>
  );
}
export default Register;
