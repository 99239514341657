import PropTypes from 'prop-types';

function TablePagination({
  pageCurrentNumber,
  pageLastNumber,
  pageSize,
  totalNumberOfDocs,
  changePageSize,
  loadMoreDataEnabled,
  loadMoreData,
}) {
  const getOptions = () => {
    const possibleOptions = [10, 50, 100, 500, 1000];
    let optionsArray = [];
    for (var i = 0; i <= possibleOptions.length; i++) {
      if (i === 0) {
        optionsArray.push(possibleOptions[i]);
      } else if (i === possibleOptions.length) {
        // Add last item total rows and exit loop
        // NOTE: Disabled => to avoid large fetchs
        // optionsArray.push(totalNumberOfDocs);
        break;
      } else {
        if (possibleOptions[i] >= totalNumberOfDocs) {
          if (possibleOptions[0] >= totalNumberOfDocs) {
            break;
          } else {
            // Add totel rows if the next is grater and exit loop
            optionsArray.push(totalNumberOfDocs);
            break;
          }
        } else {
          optionsArray.push(possibleOptions[i]);
        }
      }
    }
    return optionsArray;
  };
  return (
    <div className='row mt-2'>
      <div className='col-12 c-group u-h-evenly'>
        <div className='table-pagination-element'>
          <button
            disabled={!loadMoreDataEnabled}
            type='button'
            onClick={() => loadMoreData()}
            className={`btn`}
            title='Load more'
          >
            Load more
          </button>
        </div>
        <div>
          Page {pageCurrentNumber} of {pageLastNumber}
        </div>
        <div className=' table-pagination-element'>
          <div className='select--width-100'>
            <select
              id='page-size-select'
              value={pageSize}
              onChange={(e) => {
                changePageSize(parseInt(e.target.value));
              }}
            >
              {getOptions().map((item) => {
                return (
                  <option key={item} value={item}>
                    {item} rows
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export const getPageLastNumber = (total, pageSize) => {
  let _possiblePages = 1;
  if ((total || 0) === 0) {
    _possiblePages = 0;
  } else if ((total || 0) === pageSize) {
    _possiblePages = 1;
  } else if ((total || 0) !== pageSize) {
    _possiblePages = Math.ceil((total || 0) / pageSize);
  }
  return _possiblePages;
};

TablePagination.propTypes = {
  pageCurrentNumber: PropTypes.number,
  pageLastNumber: PropTypes.number,
  pageSize: PropTypes.number,
  totalNumberOfDocs: PropTypes.number,
  changePageSize: PropTypes.func,
  loadMoreDataEnabled: PropTypes.bool,
  loadMoreData: PropTypes.func,
};

export default TablePagination;
