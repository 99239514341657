import { getFunctions, httpsCallable } from 'firebase/functions';
import { createDataFecthMethod } from './data.service';
import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';

const COLLECTION = 'messages';
const DEFAULT_FIELD = 'created';
const DEFAULT_FREE_FILTER_FIELD = 'devices';
const DEFAULT_FREE_FILTER_FIELD_TYPE = 'string';
const FREE_FILTER_QUERY = 'array-contains-any';
const DEFAULT_DAY_FIELD = 'created';
const DEFAULT_SORT_DIRECTION = 'desc';
const MAX_COUNT = 5000;
const AUTO_LOAD = false;

export const getMessages = createDataFecthMethod(
  COLLECTION,
  DEFAULT_FIELD,
  DEFAULT_FREE_FILTER_FIELD,
  DEFAULT_FREE_FILTER_FIELD_TYPE,
  FREE_FILTER_QUERY,
  DEFAULT_DAY_FIELD,
  DEFAULT_SORT_DIRECTION,
  MAX_COUNT,
  AUTO_LOAD,
);

export const sendFbMessage = async (messageContent) => {
  logEvent(analytics, ANALYTICS_EVENT.action_send_push_message);

  const sendPushMessage_v2 = httpsCallable(
    getFunctions(),
    'sendPushMessage_v2',
  );
  return sendPushMessage_v2(messageContent);
};
