import { useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import useAppDataContext from '../hooks/useAppDataContext';
import { useServiceDataSource } from '../hooks/useServiceDataSource';
import Table from '../components/table/Table';
import Spinner from '../components/Spinner';
import SendPushMessageModal from '../components/pushMessage/SendPushMessageModal';
import TablePagination from '../components/table/TablePagination';
import tableHeaderPushMessages from '../components/table/tableHeaderPushMessage';
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import FilterA from '../components/filters/FilterA';
import Alert from '../components/Alert';
import { getPushTokens } from '../services/pushTokens.service';
import { FaEnvelope, FaTrashAlt } from 'react-icons/fa';

function PushMessages() {
  const { user } = useAuthContext();
  const { companies } = useAppDataContext();
  const [selectedUids, setSelectedUids] = useState([]);
  const [sorting, setSorting] = useState([]);

  // DATA METHOD
  const deviceDataModifierFunc = (results, companies) => {
    for (let item of results.docs) {
      const matchedComp = companies.find((comp) => {
        return comp.id === item.companyId;
      });

      if (matchedComp) {
        item.companyName = matchedComp.name || 'not defined';
        const matchedGroup = matchedComp.groups.find((group) => {
          return group.id === item.groupId;
        });
        if (matchedGroup) {
          item.groupName = matchedGroup.name || 'not defined';
        }
      } else {
        item.companyName = '';
        item.groupName = '';
      }
    }
    return results;
  };
  const deviceDataModifierObject = {
    func: deviceDataModifierFunc,
    data: companies,
  };

  const {
    error,
    data,
    filterObject,
    isLoading,
    pageLastNumber,
    pageSize,
    totalDocs,
    loadMoreData,
    reloadData,
    updateFilterObject,
    updatePageSize,
  } = useServiceDataSource(
    getPushTokens.getData,
    getPushTokens.getQuery,
    getPushTokens.getDocsCount,
    getPushTokens.defaultFilterObject,
    deviceDataModifierObject,
    getPushTokens.autoload,
  );

  // Table row select functions
  const handleRowSelect = (uid) => {
    const _uid = parseInt(uid);

    if (_uid && _uid > 0) {
      if (selectedUids.includes(_uid)) {
        setSelectedUids([...selectedUids].filter((oneUid) => oneUid !== _uid));
      } else {
        setSelectedUids([...selectedUids, parseInt(_uid)]);
      }
    }
  };

  // Table header select functions
  const handleHeaderSelect = () => {
    if (selectedUids.length > 0) {
      setSelectedUids([]);
    } else {
      setSelectedUids(
        data.map((item) => {
          return parseInt(item.uid);
        }),
      );
    }
  };

  // Table - create columns
  const columns = tableHeaderPushMessages(
    selectedUids,
    data.length,
    handleRowSelect,
    handleHeaderSelect,
  );

  // Table - create table content
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false);

  const loadMoreDataEnabled = () => {
    if (totalDocs === data.length) {
      return false;
    } else {
      return true;
    }
  };

  // Config for FilterA component
  const filterConfig = {
    enabled: {
      isFreeText: true,
      isCompanies: true,
      isFromDate: false,
      isToDate: false,
    },
    filterMethods: {
      filterFree: {
        freeFilterPlaceHolder: 'Search by device or app ID',
      },
      filterGroups: {
        companies: companies,
      },
      filterDate: {},
    },
  };
  return (
    <div className='table-container'>
      {sendMessageModalOpen && (
        <SendPushMessageModal
          sendMessageModalOpen={sendMessageModalOpen}
          setSendMessageModalOpen={setSendMessageModalOpen}
          selectedDevices={selectedUids}
          publisher={user}
        />
      )}
      <div className='table-menu-header'>
        <div>
          <h3>Send push message</h3>
          <p>
            {' '}
            {totalDocs} devices
            {selectedUids.length > 0
              ? ' - ' + selectedUids.length + ' selected  '
              : ''}
            {selectedUids.length > 0 && (
              <button
                type='button'
                className='btn-transparent'
                title='Clear selected udis'
                onClick={() => setSelectedUids([])}
              >
                <FaTrashAlt />
              </button>
            )}
          </p>
        </div>
        <div className='table-menu-header-actions'>
          <button
            type='button'
            title='Send push message'
            disabled={!selectedUids.length}
            onClick={() => setSendMessageModalOpen(true)}
            className={`btn-icon`}
          >
            <FaEnvelope />
          </button>
        </div>
      </div>

      <FilterA
        filterConfig={filterConfig}
        filterObject={filterObject}
        updateFilterObject={updateFilterObject}
        triggerSearch={reloadData}
      />

      <Spinner isSpinning={isLoading}></Spinner>

      {error != null && (
        <div className='mtb-1'>
          <Alert type='error' title={error.message} content={error.cause} />
        </div>
      )}

      {totalDocs > 0 && (
        <div className='mt-2'>
          <Table table={table} />

          <TablePagination
            pageCurrentNumber={Math.ceil(data.length / pageSize)}
            pageLastNumber={pageLastNumber}
            pageSize={pageSize}
            totalNumberOfDocs={totalDocs}
            changePageSize={(newSize) => {
              updatePageSize(newSize);
            }}
            loadMoreDataEnabled={loadMoreDataEnabled()}
            loadMoreData={loadMoreData}
          />
        </div>
      )}
    </div>
  );
}
export default PushMessages;
