import { useState } from 'react';
import Table from '../components/table/Table';
import Spinner from '../components/Spinner';
import TablePagination from '../components/table/TablePagination';
import tableHeaderAppIds from '../components/table/tableHeaderAppIds';
import { FaDownload } from 'react-icons/fa';
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import { getAppIds } from '../services/appid.service';
import { useServiceDataSource } from '../hooks/useServiceDataSource';
import useAppDataContext from '../hooks/useAppDataContext';
import FilterA from '../components/filters/FilterA';
import Alert from '../components/Alert';
import {
  prepareDataForAppIds,
  exportAppIdsCsv,
} from '../services/file.service';

import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';

function AppIds() {
  logEvent(analytics, ANALYTICS_EVENT.page_view_appId);

  const { companies } = useAppDataContext();

  // Add company name based on companyId
  const appIdDataModifierFunc = (results, data) => {
    const incorrectCompanyId = 'incorrect companiID';

    if (!data) {
      return results;
    }
    if (!results.docs.length > 0) {
      return results;
    }

    const dataAppId = [];
    results.docs.forEach((item) => {
      dataAppId.push({
        ...item,
        companyName:
          companies.find((x) => x.id === item?.companyId)?.name ||
          item?.companyId ||
          incorrectCompanyId,
      });
    });

    results.docs = dataAppId;
    results.total = dataAppId.length;
    return results;
  };
  const appIdDataModifierObject = {
    func: appIdDataModifierFunc,
    data: companies,
  };

  const {
    data,
    error,
    filterObject,
    isLoading,
    pageLastNumber,
    pageSize,
    totalDocs,
    loadMoreData,
    reloadData,
    updateFilterObject,
    updatePageSize,
  } = useServiceDataSource(
    getAppIds.getData,
    getAppIds.getQuery,
    getAppIds.getDocsCount,
    getAppIds.defaultFilterObject,
    appIdDataModifierObject,
    getAppIds.autoload,
  );

  // Table sorting
  const [sorting, setSorting] = useState([]);
  // Table - create columns
  const columns = tableHeaderAppIds();

  // Table - create table content
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
  });

  const loadMoreDataEnabled = () => {
    if (totalDocs === data.length) {
      return false;
    } else {
      return true;
    }
  };

  const runSearch = () => {
    reloadData();
  };

  const exportData = () => {
    const prepData = prepareDataForAppIds(data);
    exportAppIdsCsv(prepData, 'data-appIds');
  };

  // Config for FilterA component
  const filterConfig = {
    enabled: {
      isFreeText: true,
      isCompanies: true,
      isFromDate: false,
      isToDate: false,
    },
    filterMethods: {
      filterFree: {
        freeFilterPlaceHolder: 'Search by App ID',
      },
      filterGroups: {
        companies: companies,
      },
      filterDate: {
        limitDateRange: false,
      },
    },
  };

  return (
    <div className='table-container'>
      <div className='table-menu-header'>
        <div>
          <h3>App IDs</h3>
          <p>{totalDocs} total</p>
        </div>
        <div className='table-menu-header-actions'>
          <button
            className={`btn-icon`}
            type='button'
            title='Download App ID data'
            onClick={exportData}
          >
            <FaDownload />
          </button>
        </div>
      </div>

      <FilterA
        filterConfig={filterConfig}
        filterObject={filterObject}
        updateFilterObject={updateFilterObject}
        triggerSearch={runSearch}
      />

      <Spinner isSpinning={isLoading}></Spinner>

      {error != null && (
        <div className='mtb-1'>
          <Alert type='error' title={error.message} content={error.cause} />
        </div>
      )}

      {data.length > 0 && (
        <div className='mt-2'>
          <Table table={table} />

          <TablePagination
            pageCurrentNumber={Math.ceil(data.length / pageSize)}
            pageLastNumber={pageLastNumber}
            pageSize={pageSize}
            totalNumberOfDocs={totalDocs}
            changePageSize={(newSize) => {
              updatePageSize(newSize);
            }}
            loadMoreDataEnabled={loadMoreDataEnabled()}
            loadMoreData={loadMoreData}
          />
        </div>
      )}
    </div>
  );
}
export default AppIds;
