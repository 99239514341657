import { useState } from 'react';
import {
  updateGroup,
  deleteGroup,
  deleteCompany,
  createGroup,
  createCompany,
  updateCompany,
} from '../services/company.service';
import { GROUP_ACTIONS } from '../services/company.service';

export const useCompanyEdit = () => {
  const [isUpdating, setIsUpdating] = useState(false);

  const editCompany = async (actionObject) => {
    setIsUpdating(true);

    try {
      switch (actionObject.action) {
        case GROUP_ACTIONS.updateGroup:
          await updateGroup(actionObject);
          break;
        case GROUP_ACTIONS.updateCompany:
          await updateCompany(actionObject);
          break;
        case GROUP_ACTIONS.deleteGroup:
          await deleteGroup(actionObject);
          break;
        case GROUP_ACTIONS.deleteCompany:
          await deleteCompany(actionObject);
          break;
        case GROUP_ACTIONS.createGroup:
          await createGroup(actionObject);
          break;
        case GROUP_ACTIONS.createCompany:
          await createCompany(actionObject);
          break;
        default:
          setIsUpdating(false);
          throw new Error('not used action');
      }
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
      throw error;
    }
  };
  return { isUpdating, editCompany };
};
