import { createDataFecthMethod } from './data.service';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../app/config';
import { ValidationError, FirebaseError } from '../utils/errors';

const COLLECTION = 'tokens';
const DEFAULT_FIELD = 'uid';
const DEFAULT_FREE_FILTER_FIELD = 'uid';
const DEFAULT_FREE_FILTER_FIELD_TYPE = 'number';
const FREE_FILTER_QUERY = 'in';
const DEFAULT_DAY_FIELD = 'updated';
const DEFAULT_SORT_DIRECTION = 'asc';
const MAX_COUNT = 1000;
const AUTO_LOAD = false;

export const getPushTokens = createDataFecthMethod(
  COLLECTION,
  DEFAULT_FIELD,
  DEFAULT_FREE_FILTER_FIELD,
  DEFAULT_FREE_FILTER_FIELD_TYPE,
  FREE_FILTER_QUERY,
  DEFAULT_DAY_FIELD,
  DEFAULT_SORT_DIRECTION,
  MAX_COUNT,
  AUTO_LOAD,
);

const validateNewData = (newTokenData) => {
  return Object.prototype.hasOwnProperty.call(newTokenData, 'uid');
};

export const updatePushTokenData = async (uid, newTokenData) => {
  if (!validateNewData(newTokenData)) {
    throw new ValidationError(
      'Invalid data',
      'updatePushToken validation error',
    );
  }
  try {
    const docRef = doc(db, COLLECTION, uid);
    await setDoc(docRef, newTokenData, { merge: true });
  } catch (error) {
    throw new FirebaseError('Firebase updateData error', error.message);
  }
};
