import { useState } from 'react';
import tableHeaderCloudServer from '../components/table/tableHeaderCloudServer';
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import FilterA from '../components/filters/FilterA';
import {
  getCloudServer,
  updateCloudServer,
  defaultFilterObject,
} from '../services/cloudServer.service';
import Spinner from '../components/Spinner';
import Alert from '../components/Alert';
import Table from '../components/table/Table';

const help_title = 'How to use';
const help_content =
  'Cloud Server defines to which cloud environment the device should report data. Use this to change device reporting environment.\nYou can search one device at the time. Search device and update cloud server field as needed.';

function CloudServer() {
  const [isLoading, setIsLoading] = useState(false);
  const [sorting, setSorting] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [filterObject, setFilterObject] = useState({ ...defaultFilterObject });

  const setNewCloudServer = async (newCloudServer) => {
    if (error) {
      return;
    }
    setIsLoading(true);
    try {
      await updateCloudServer(filterObject.freeFilter, newCloudServer);
      // Update data
      reloadData();
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };

  // Table - create columns
  const columns = tableHeaderCloudServer(setNewCloudServer);

  // Table - create table content
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  const reloadData = async () => {
    setError(null);
    setIsLoading(true);

    try {
      const _data = await getCloudServer(filterObject.freeFilter);
      setData([_data]);
      setIsLoading(false);
    } catch (error) {
      setData([]);
      setIsLoading(false);
      setError(error);
    }
  };

  // Config for FilterA component
  const filterConfig = {
    enabled: {
      isFreeText: true,
      isCompanies: false,
      isFromDate: false,
      isToDate: false,
    },
    filterMethods: {
      filterFree: {
        freeFilterPlaceHolder: 'Search by device SN',
      },
      filterGroups: {},
      filterDate: {},
    },
  };

  return (
    <>
      <div className='table-container'>
        <div className='table-menu-header'>
          <div>
            <h3>Cloud Server</h3>
          </div>
        </div>
        <div className='mtb-1'>
          <Alert type='warning' title={help_title} content={help_content} />
        </div>

        <FilterA
          filterConfig={filterConfig}
          filterObject={filterObject}
          updateFilterObject={setFilterObject}
          triggerSearch={reloadData}
        />

        <Spinner isSpinning={isLoading}></Spinner>

        {error != null && (
          <div className='mtb-1'>
            <Alert type='error' title={error.message} content={error.cause} />
          </div>
        )}

        {!isLoading && (
          <>
            <Table table={table} />

            {data.length < 1 && <div className='table-no-data'>No data</div>}
          </>
        )}
      </div>
    </>
  );
}
export default CloudServer;
