import { useState, useEffect } from 'react';
import { getDocs } from 'firebase/firestore';
import { FirebaseError } from '../utils/errors';

const DEFAULT_PAGE_SIZE = 10;

export const useServiceDataSource = (
  getData,
  getQuery,
  getDocsCount,
  defaultFilterObject,
  fetchResultsModifierObject,
  autoload,
) => {
  /**
   * ==== fetchResultsModifierObject ===
   * Object must be in the format:
   * const modifierObject = {
   *      func: [some function]
   *      data: [some data]
   * }
   *
   * func must take two parameters:
   * modifierFunction(fetchResult, data)
   * fetchResult = result from Firebase
   * data = data to be used in the function
   */

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // pageCurrent and pageLast => first page is zero (0)
  const [pageCurrentNumber, setPageCurrentNumber] = useState(0);
  const [pageLastNumber, setPageLastNumber] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalDocs, setTotalDocs] = useState(0);

  // Save the last doc ref
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);

  // filterObject variables
  const [filterObject, setFilterObject] = useState({
    ...defaultFilterObject,
  });

  const updatePageSize = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const updateFilterObject = (newFilterObject) => {
    setFilterObject(newFilterObject);
  };

  // Refresh data
  const reloadData = async () => {
    setError(null);
    setLastVisibleDoc(null);
    try {
      await loadData();
    } catch (error) {
      setError(error);
    }
  };

  // Get data based on filters
  const loadData = async () => {
    setError(null);
    setIsLoading(true);

    try {
      let fetchResults = await getData(
        pageSize,
        filterObject,
        lastVisibleDoc,
        setLastVisibleDoc,
        false,
      );
      setTotalDocs(fetchResults.total || 0);
      setPageLastNumber(getPageLastNumber(fetchResults.total));

      // Check if dataModifier function available
      // Used e.g. to update company info for devices page
      if (fetchResultsModifierObject) {
        const func = fetchResultsModifierObject.func;
        const data = fetchResultsModifierObject.data;
        fetchResults = func(fetchResults, data);
      }

      setData(fetchResults.docs || []);
      setIsLoading(false);
    } catch (error) {
      setData([]);
      setTotalDocs(0);
      setIsLoading(false);
      setError(error);
    }
  };

  // Get new data when user taps "Load more" button
  const loadMoreData = async () => {
    setError(null);

    if (!lastVisibleDoc) {
      return;
    }

    try {
      const dataQuery = await getQuery(
        pageSize,
        filterObject,
        lastVisibleDoc,
        true,
      );
      const querySnapshot = await getDocs(dataQuery);

      let newData = {
        docs: querySnapshot.docs.map((doc) => {
          return { ...doc.data(), uid: doc.id };
        }),
      };

      // Check if dataModifier function available
      // Used e.g. to update company info for devices page
      if (fetchResultsModifierObject) {
        const func = fetchResultsModifierObject.func;
        const data = fetchResultsModifierObject.data;
        newData = func(newData, data);
      }

      if (newData.docs.length > 0) {
        setData((prevState) => {
          let updatedData = [...prevState];
          updatedData.push(...newData.docs);
          return updatedData;
        });
      }

      // Set last visible doc for load more data
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisibleDoc(lastVisible);
    } catch (error) {
      setError(new FirebaseError('Firebase loadMoreData error', error.message));
    }
  };

  const getPageLastNumber = (total) => {
    let _possiblePages = 1;
    if ((total || 0) === 0) {
      _possiblePages = 0;
    } else if ((total || 0) === pageSize) {
      _possiblePages = 1;
    } else if ((total || 0) !== pageSize) {
      _possiblePages = parseInt((total || 0) / pageSize) + 1;
    }
    return _possiblePages;
  };

  const docsCount = async (_filterObject) => {
    return await getDocsCount(_filterObject || defaultFilterObject);
  };

  // Set deps that triggers data update
  useEffect(() => {
    if (autoload === true) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, filterObject, pageCurrentNumber]);

  return {
    data,
    error,
    filterObject,
    isLoading,
    pageCurrentNumber,
    pageLastNumber,
    pageSize,
    totalDocs,
    loadMoreData,
    reloadData,
    setPageCurrentNumber,
    updateFilterObject,
    updatePageSize,
    docsCount,
  };
};
