import { createDataFecthMethod } from './data.service';
import { updateDataBatchWrite } from './data.utils';
import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';
import { FirebaseError } from 'firebase/app';

const COLLECTION = 'devices';
const DEFAULT_FIELD = 'deviceId';
const DEFAULT_FREE_FILTER_FIELD = 'deviceId';
const DEFAULT_FREE_FILTER_FIELD_TYPE = 'number';
const FREE_FILTER_QUERY = 'in';
const DEFAULT_DAY_FIELD = 'created';
const DEFAULT_SORT_DIRECTION = 'asc';
const MAX_COUNT = 10000;
const AUTO_LOAD = false;

export const getDevices = createDataFecthMethod(
  COLLECTION,
  DEFAULT_FIELD,
  DEFAULT_FREE_FILTER_FIELD,
  DEFAULT_FREE_FILTER_FIELD_TYPE,
  FREE_FILTER_QUERY,
  DEFAULT_DAY_FIELD,
  DEFAULT_SORT_DIRECTION,
  MAX_COUNT,
  AUTO_LOAD,
);

export const moveDevicesToGroup = async (deviceIdArray, companyId, groupId) => {
  logEvent(analytics, ANALYTICS_EVENT.action_move_device_into_group);

  const newData = {
    group: `companies/${companyId}/groups/${groupId}`,
    companyId: companyId,
    groupId: groupId,
  };
  try {
    // Update device collection
    await updateDataBatchWrite(COLLECTION, deviceIdArray, newData);

    // // Update token collection with new company & group data
    // const newTokenData = {
    //   companyId: companyId,
    //   groupId: groupId,
    // };
    // await updateDataBatchWrite('tokens', deviceIdArray, newTokenData);
  } catch (error) {
    throw new FirebaseError('Firebase moveDevicesToGroup error', error.message);
  }
};
