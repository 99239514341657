import { Fragment } from 'react';
import { flexRender } from '@tanstack/react-table';
import PropTypes from 'prop-types';

function Table({ table, expandedRowContent }) {
  // Used for expandable rows
  const renderSubComponent = (row) => {
    return (
      <pre style={{ fontSize: '10px' }}>
        <code>{JSON.stringify(row, null, 2)}</code>
      </pre>
    );
  };

  return (
    <table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                colSpan={header.colSpan}
                {...{
                  className: header.column.getCanSort()
                    ? `x-table-header-sort ${determineColumnSortingClass(
                        header.column.getIsSorted(),
                      )}`
                    : 'x-table-header',
                  onClick: header.column.getToggleSortingHandler(),
                }}
              >
                <div>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <Fragment key={row.id}>
              <tr>
                {/* first row is a normal row */}
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
              {expandedRowContent === 'rawJson' && row.getIsExpanded() && (
                <tr>
                  {/* 2nd row is a custom 1 cell row */}
                  <td colSpan={row.getVisibleCells().length}>
                    {/**this is for messages page */}
                    {renderSubComponent(row.original.message.data)}
                    {/* {renderSubComponent(row.original)} */}
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

function determineColumnSortingClass(sortDirection) {
  switch (sortDirection) {
    case 'asc':
      return 'sorting-asc';
    case 'desc':
      return 'sorting-desc';
    default:
      return '';
  }
}

Table.propTypes = {
  table: PropTypes.object.isRequired,
  expandedRowContent: PropTypes.string,
};

export default Table;
