import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { GROUP_ACTIONS } from '../../../services/company.service';
import PropTypes from 'prop-types';

function CellGroupsAction({ rawData, setModalData }) {
  const editRow = (action) => {
    let _rawDataCompany = null;
    let _rawDataGroup = null;
    if (isRowCompany()) {
      _rawDataCompany = rawData;
    } else {
      _rawDataGroup = rawData;
    }
    setModalData((prevState) => ({
      ...prevState,
      isOpen: true,
      actionObject: {
        ...prevState.actionObject,
        action: action,
        rawDataCompany: _rawDataCompany,
        rawDataGroup: _rawDataGroup,
      },
    }));
  };

  const isRowCompany = () => {
    return rawData.groups !== undefined;
  };

  return (
    <div className='row'>
      <div className='col-12 c-group u-v-center'>
        <button
          type='button'
          className='btn-transparent'
          title='Edit'
          onClick={() => {
            editRow(
              isRowCompany()
                ? GROUP_ACTIONS.updateCompany
                : GROUP_ACTIONS.updateGroup,
            );
          }}
        >
          <FaEdit />
        </button>
        <button
          type='button'
          className='btn-transparent'
          title='Delete'
          onClick={() => {
            editRow(
              isRowCompany()
                ? GROUP_ACTIONS.deleteCompany
                : GROUP_ACTIONS.deleteGroup,
            );
          }}
        >
          <FaTrash />
        </button>
        {isRowCompany() && (
          <button
            type='button'
            className='btn-transparent'
            title='Create new group'
            onClick={() => {
              editRow(GROUP_ACTIONS.createGroup);
            }}
          >
            <FaPlus />
          </button>
        )}
      </div>
    </div>
  );
}

CellGroupsAction.propTypes = {
  rawData: PropTypes.object.isRequired,
  setModalData: PropTypes.func.isRequired,
};

export default CellGroupsAction;
