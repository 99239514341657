import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillWarning,
  AiFillExclamationCircle,
} from 'react-icons/ai';
import { FaBell } from 'react-icons/fa';
import PropTypes from 'prop-types';

function Alert({ type, title, content }) {
  const setIcon = () => {
    switch (type) {
      case 'error':
        return <AiFillCloseCircle />;
      case 'warning':
        return <AiFillWarning />;
      case 'success':
        return <AiFillCheckCircle />;
      case 'notification':
        return <FaBell />;
      default:
        return <AiFillExclamationCircle />;
    }
  };

  return (
    <div className={'alert-card ' + type}>
      <div className={'alert-icon ' + type}>{setIcon()}</div>
      <div className={'alert-content ' + type}>
        {title && <h4>{title}</h4>}
        <p>{content}</p>
      </div>
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.oneOf(['error', 'warning', 'success', 'notification']),
  title: PropTypes.string,
  content: PropTypes.string,
};

export default Alert;
