import { useState } from 'react';
import Table from '../components/table/Table';
import Spinner from '../components/Spinner';
import TablePagination from '../components/table/TablePagination';
import tableHeaderMedicineIds from '../components/table/tableHeaderMedicineIds';
import { FaDownload } from 'react-icons/fa';
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import { getMedicineIds } from '../services/medicineid.service';
import { useServiceDataSource } from '../hooks/useServiceDataSource';
import useAppDataContext from '../hooks/useAppDataContext';
import FilterA from '../components/filters/FilterA';
import Alert from '../components/Alert';
import { setCompanyGroupNames } from '../services/stats.service';
import {
  prepareDataForMedicineIds,
  exportMedicineIdsCsv,
} from '../services/file.service';

import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';

function MedicineIds() {
  logEvent(analytics, ANALYTICS_EVENT.page_view_medicineId);

  const { companies } = useAppDataContext();

  // Add company and group name based on companyId & groupId
  const medicineIdDataModifierFunc = (results, data) => {
    if (!data) {
      return results;
    }
    if (!results.docs.length > 0) {
      return results;
    }

    const dataMedicineId = [];
    results.docs.forEach((item) =>
      dataMedicineId.push({
        ...setCompanyGroupNames(item, data),
      }),
    );

    results.docs = dataMedicineId;
    results.total = dataMedicineId.length;
    return results;
  };
  const medicineIdDataModifierObject = {
    func: medicineIdDataModifierFunc,
    data: companies,
  };

  const {
    error,
    data,
    filterObject,
    isLoading,
    pageLastNumber,
    pageSize,
    totalDocs,
    loadMoreData,
    reloadData,
    updateFilterObject,
    updatePageSize,
  } = useServiceDataSource(
    getMedicineIds.getData,
    getMedicineIds.getQuery,
    getMedicineIds.getDocsCount,
    getMedicineIds.defaultFilterObject,
    medicineIdDataModifierObject,
    getMedicineIds.autoload,
  );

  // Table sorting
  const [sorting, setSorting] = useState([]);
  // Table - create columns
  const columns = tableHeaderMedicineIds();

  // Table - create table content
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
  });

  const loadMoreDataEnabled = () => {
    if (totalDocs === data.length) {
      return false;
    } else {
      return true;
    }
  };

  const exportData = () => {
    const dataPrepared = prepareDataForMedicineIds(data);
    exportMedicineIdsCsv(dataPrepared, 'data-medicineIds');
  };

  // Config for FilterA component
  const filterConfig = {
    enabled: {
      isFreeText: true,
      isCompanies: true,
      isFromDate: false,
      isToDate: false,
    },
    filterMethods: {
      filterFree: {
        freeFilterPlaceHolder: 'Search by App ID',
      },
      filterGroups: {
        companies: companies,
      },
      filterDate: {},
    },
  };

  return (
    <div className='table-container'>
      <div className='table-menu-header'>
        <div>
          <h3>Medicine IDs</h3>
          <p>{totalDocs} total</p>
        </div>
        <div className='table-menu-header-actions'>
          <button
            className={`btn-icon`}
            type='button'
            title='Download Medicine ID data'
            onClick={exportData}
          >
            <FaDownload />
          </button>
        </div>
      </div>

      <FilterA
        filterConfig={filterConfig}
        filterObject={filterObject}
        updateFilterObject={updateFilterObject}
        triggerSearch={reloadData}
      />

      <Spinner isSpinning={isLoading}></Spinner>

      {error != null && (
        <div className='mtb-1'>
          <Alert type='error' title={error.message} content={error.cause} />
        </div>
      )}

      {data.length > 0 && (
        <div className='mt-2'>
          <Table table={table} />

          <TablePagination
            pageCurrentNumber={Math.ceil(data.length / pageSize)}
            pageLastNumber={pageLastNumber}
            pageSize={pageSize}
            totalNumberOfDocs={totalDocs}
            changePageSize={(newSize) => {
              updatePageSize(newSize);
            }}
            loadMoreDataEnabled={loadMoreDataEnabled()}
            loadMoreData={loadMoreData}
          />
        </div>
      )}
    </div>
  );
}
export default MedicineIds;
