import { createColumnHelper } from '@tanstack/react-table';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';

function tableHeaderPushMessages(
  selectedUids,
  visibleItemsCount,
  handleRowSelect,
  handleHeaderSelect,
) {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.accessor('select', {
      id: 'select',
      cell: (info) => {
        const _uid = parseInt(info.row.original.uid);
        const isToken = info.row.original.token ? true : false;
        return (
          <input
            type='checkbox'
            disabled={!isToken}
            checked={selectedUids.includes(_uid)}
            onChange={() => {
              handleRowSelect(_uid);
            }}
          />
        );
      },
      header: () => (
        <input
          type='checkbox'
          disabled={visibleItemsCount < 1}
          checked={
            visibleItemsCount > 0 && selectedUids.length === visibleItemsCount
          }
          onChange={() => {
            handleHeaderSelect();
          }}
        />
      ),
      enableSorting: false,
      firebaseSortField: 'deviceId',
    }),
    columnHelper.accessor('uid', {
      id: 'uid',
      cell: (info) => info.getValue(),
      header: () => 'Uid',
      enableSorting: true,
    }),
    columnHelper.accessor('companyName', {
      id: 'companyName',
      header: () => 'Company',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('groupName', {
      id: 'groupName',
      header: () => 'Group',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('country', {
      id: 'country',
      header: () => 'Country',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('timezone', {
      id: 'timezone',
      header: () => 'Timezone',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('mobileOs', {
      id: 'mobileOs',
      header: () => 'OS',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('token', {
      id: 'token',
      header: () => 'Enabled',
      cell: (info) => {
        return (
          <div className='row u-h-center'>
            {info.getValue() ? (
              <FaCheckCircle className='icon-success' />
            ) : (
              <FaMinusCircle className='icon-fail' />
            )}
          </div>
        );
      },
      enableSorting: true,
    }),
    columnHelper.accessor('updated', {
      id: 'updated',
      header: () => 'Token set',
      cell: (info) => {
        const lastUpdate = info.getValue();
        if (
          typeof lastUpdate == 'number' &&
          lastUpdate !== 'null' &&
          lastUpdate !== '-' &&
          lastUpdate !== undefined
        ) {
          return new Date(lastUpdate * 1000).toLocaleDateString();
        } else {
          return '';
        }
      },
      enableSorting: true,
    }),
    columnHelper.accessor('phone_id', {
      id: 'phone_id',
      header: () => 'Phone ID',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
  ];
}

tableHeaderPushMessages.propTypes = {
  selectedDevices: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  handleRowSelect: PropTypes.func.isRequired,
  handleHeaderSelect: PropTypes.func.isRequired,
};

export default tableHeaderPushMessages;
