import { createColumnHelper } from '@tanstack/react-table';

function tableHeaderStatsCompanyMedicines() {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.group({
      id: 'Medicine',
      header: () => 'Medicine',
      columns: [
        columnHelper.accessor('companyName', {
          id: 'companyName',
          header: () => 'Company',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('medicineIdCount', {
          id: 'medicineIdCount',
          header: () => 'Medicine IDs',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),
    // DOSE
    columnHelper.group({
      id: 'Dose data',
      header: () => 'Dose taken',
      columns: [
        columnHelper.accessor('doseBefore', {
          id: 'doseBefore',
          header: () => 'Before',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('doseAfter', {
          id: 'doseAfter',
          header: () => 'After',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('doseNotTaken', {
          id: 'doseNotTaken',
          header: () => 'Not Taken',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('doseNoReminder', {
          id: 'doseNoReminder',
          header: () => 'No Reminder',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('doseNotSheduled', {
          id: 'doseNotSheduled',
          header: () => 'Not Sheduled',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('doseMultiple', {
          id: 'doseMultiple',
          header: () => 'Multiple',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('doseTotal', {
          id: 'doseTotal',
          header: () => 'Total',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),
    // REMINDERS
    columnHelper.group({
      id: 'Reminder data',
      header: () => 'Number of Reminders',
      columns: [
        columnHelper.accessor('remindersTotal', {
          id: 'remindersTotal',
          header: () => 'Total',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('remindersMorning', {
          id: 'remindersMorning',
          header: () => 'Morning',
          cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('remindersMidday', {
          id: 'remindersMidday',
          header: () => 'Midday',
          cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('remindersEvening', {
          id: 'remindersEvening',
          header: () => 'Evening',
          cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('remindersNight', {
          id: 'remindersNight',
          header: () => 'Night',
          cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('remindersShow', {
          id: 'remindersShow',
          header: () => 'Shown',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('remindersPerDay', {
          id: 'remindersPerDay',
          header: () => 'Avg./day',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),
    // ADHERENCE
    columnHelper.group({
      id: 'Adherence',
      header: () => 'Adherence',
      columns: [
        columnHelper.accessor('adherence', {
          id: 'adherence',
          header: () => 'Adherence',
          cell: (info) => (info.getValue() >= 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
        columnHelper.accessor('adherenceScore', {
          id: 'adherenceScore',
          header: () => 'Score',
          cell: (info) => (info.getValue() >= 0 ? info.getValue() : ''),
          enableSorting: true,
        }),
      ],
    }),
  ];
}

export default tableHeaderStatsCompanyMedicines;
