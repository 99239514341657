import { createColumnHelper } from '@tanstack/react-table';
import CellEventLogAppId from './cells/CellEventLogAppId';

function tableHeaderAppIds() {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.accessor('appId', {
      id: 'appId',
      header: () => 'App ID',
      cell: (info) => {
        return <CellEventLogAppId appId={info.getValue()} />;
      },
      enableSorting: true,
    }),
    columnHelper.accessor('appUseStartDay', {
      id: 'appUseStartDay',
      header: () => 'App Start',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('companyName', {
      id: 'companyName',
      header: () => 'Company',
      cell: (info) => info.getValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('config.device.deviceId', {
      id: 'config.device.deviceId',
      header: () => 'Device ID',
      cell: (info) => info.row.original?.config?.device?.deviceId,
      enableSorting: true,
    }),
    columnHelper.accessor('config.device.start', {
      id: 'config.device.start',
      header: () => 'Device start',
      cell: (info) => info.row.original?.config?.device?.start,
      enableSorting: true,
    }),
    columnHelper.accessor('config.device.end', {
      id: 'config.device.end',
      header: () => 'Device end',
      cell: (info) => info.row.original?.config?.device?.end,
      enableSorting: true,
    }),
    columnHelper.accessor('config.phone.appVersion', {
      id: 'config.phone.appVersion',
      header: () => 'App version',
      cell: (info) => info.row.original?.config?.phone?.appVersion,
      enableSorting: true,
    }),
    columnHelper.accessor('config.phone.mobileOs', {
      id: 'config.phone.mobileOs',
      header: () => 'OS',
      cell: (info) => info.row.original?.config?.phone?.mobileOs,
      enableSorting: true,
    }),
    columnHelper.accessor('config.phone.mobileOsVersion', {
      id: 'config.phone.mobileOsVersion',
      header: () => 'OS version',
      cell: (info) => info.row.original?.config?.phone?.mobileOsVersion,
      enableSorting: true,
    }),
    columnHelper.accessor('config.phone.phoneDeviceType', {
      id: 'config.phone.phoneDeviceType',
      header: () => 'Mobile Type',
      cell: (info) => info.row.original?.config?.phone?.phoneDeviceType,
      enableSorting: true,
    }),
    columnHelper.accessor('config.user.country', {
      id: 'config.user.country',
      header: () => 'Country',
      cell: (info) => info.row.original?.config?.user?.country,
      enableSorting: true,
    }),
    columnHelper.accessor('config.user.gender', {
      id: 'config.user.gender',
      header: () => 'Gender',
      cell: (info) => {
        const gender = info.row.original?.config?.user?.gender || 0;
        if (gender === 1) {
          return 'F';
        } else if (gender === 2) {
          return 'M';
        } else {
          return 'X';
        }
      },
      enableSorting: true,
    }),
    columnHelper.accessor('config.user.dateOfBirth', {
      id: 'config.user.dateOfBirth',
      header: () => 'Age',
      cell: (info) =>
        calculateAge(Date.parse(info.row.original?.config?.user?.dateOfBirth)),
      enableSorting: true,
    }),
    columnHelper.accessor('config.phone.phoneDeviceId', {
      id: 'config.phone.phoneDeviceId',
      header: () => 'Phone ID',
      cell: (info) => info.row.original?.config?.phone?.phoneDeviceId,
      enableSorting: true,
    }),
  ];
}

function calculateAge(birthday) {
  // birthday is a date
  if (birthday) {
    var ageDifMs = Date.now() - birthday;
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  } else {
    return '';
  }
}

export default tableHeaderAppIds;
