import { createColumnHelper } from '@tanstack/react-table';
import { APP_EVENTS, REMINDER_DAY_STATUS } from '../../shared/Constants';

function tableHeaderAppEvent() {
  const columnHelper = createColumnHelper();
  return [
    // Log time
    columnHelper.group({
      id: 'Users',
      header: () => 'Log Timestamp',
      columns: [
        columnHelper.accessor('eventTimestamp', {
          id: 'eventTimestamp',
          header: () => 'Event',
          cell: (info) => {
            if (info.getValue()) {
              // message.created in seconds => needs to x 1000 to get milliseconds
              // Show date in local presentation
              return (
                <div>{new Date(info.getValue() * 1000).toLocaleString()}</div>
              );
            }
            return <div></div>;
          },
          enableSorting: true,
        }),
        columnHelper.accessor('phoneTimestamp', {
          id: 'phoneTimestamp',
          header: () => 'Phone',
          cell: (info) => {
            if (info.getValue()) {
              // message.created in seconds => needs to x 1000 to get milliseconds
              // Show date in local presentation
              return (
                <div>{new Date(info.getValue() * 1000).toLocaleString()}</div>
              );
            }
            return <div></div>;
          },
          enableSorting: true,
        }),
        columnHelper.accessor('transactionId', {
          id: 'transactionId',
          header: () => 'ID',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),

    // Log time
    columnHelper.group({
      id: 'Users',
      header: () => 'User Time',
      columns: [
        columnHelper.accessor('eventDay', {
          id: 'eventDay',
          header: () => 'Day',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('timeOfDay', {
          id: 'timeOfDay',
          header: () => 'Time Of Day',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),

    columnHelper.accessor('eventCode', {
      id: 'eventCode',
      header: () => 'Event',
      cell: (info) => {
        return APP_EVENTS[info.getValue()] ?? `Event (${info.getValue()})`;
      },
      enableSorting: true,
    }),
    // Log time
    columnHelper.group({
      id: 'Users',
      header: () => 'User IDs',
      columns: [
        columnHelper.accessor('appId', {
          id: 'appId',
          header: () => 'App',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('medicineId', {
          id: 'medicineId',
          header: () => 'Medicine',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('deviceId', {
          id: 'deviceId',
          header: () => 'Device',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('deviceData.batteryLevelPercent', {
          id: 'deviceData.batteryLevelPercent',
          header: () => 'Battery',
          cell: (info) => {
            const batValue =
              info.row.original.deviceData?.batteryLevelPercent || 'null';
            if (
              typeof +batValue == 'number' &&
              batValue !== 'null' &&
              batValue !== '-' &&
              batValue !== undefined
            ) {
              if (batValue >= 0 && batValue <= 100) {
                return batValue + '%';
              } else {
                return '-';
              }
            } else {
              return '';
            }
          },
          enableSorting: true,
        }),
      ],
    }),

    // scoreData - Adherence
    columnHelper.group({
      id: 'Users',
      header: () => 'Score (on the day)',
      columns: [
        columnHelper.accessor('medicineStatus.scoreData.scoreMonth', {
          id: 'medicineStatus.scoreData.scoreMonth',
          header: () => 'Monthly',
          cell: (info) =>
            info.row.original?.medicineStatus?.scoreData?.scoreMonth,
          enableSorting: true,
        }),
        columnHelper.accessor('medicineStatus.scoreData.scoreWeek', {
          id: 'medicineStatus.scoreData.scoreWeek',
          header: () => 'Weekly',
          cell: (info) =>
            info.row.original?.medicineStatus?.scoreData?.scoreWeek,
          enableSorting: true,
        }),

        columnHelper.accessor('medicineStatus.scoreData.scoreDay', {
          id: 'medicineStatus.scoreData.scoreDay',
          header: () => 'Daily',
          cell: (info) =>
            info.row.original?.medicineStatus?.scoreData?.scoreDay,
          enableSorting: true,
        }),
        columnHelper.accessor('medicineStatus.scoreData.adherence', {
          id: 'medicineStatus.scoreData.adherence',
          header: () => 'Adherence',
          cell: (info) =>
            info.row.original?.medicineStatus?.scoreData?.adherence,
          enableSorting: true,
        }),
      ],
    }),

    columnHelper.group({
      id: 'Users',
      header: () => 'Dose stats',
      columns: [
        columnHelper.accessor('medicineName', {
          id: 'medicineName',
          header: () => 'Medicine',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('medicineStatus.scoreData.medicineDaysStatus', {
          id: 'medicineStatus.scoreData.medicineDaysStatus',
          header: () => 'Med status',
          cell: (info) => {
            const medicineDaysStatus =
              info.row.original?.medicineStatus?.scoreData
                ?.medicineDaysStatus || -1;
            const medicineDaysStatusSting =
              REMINDER_DAY_STATUS[medicineDaysStatus];
            return medicineDaysStatusSting;
          },
          enableSorting: true,
        }),
        columnHelper.accessor('medicineStatus.scoreData.daysSinceLastDose', {
          id: 'medicineStatus.scoreData.daysSinceLastDose',
          header: () => 'Days Since Last Dose',
          cell: (info) =>
            info.row.original?.medicineStatus?.scoreData?.daysSinceLastDose,
          enableSorting: true,
        }),
        columnHelper.accessor('medicineStatus.scoreData.daysSinceLastDoseAvg', {
          id: 'medicineStatus.scoreData.daysSinceLastDoseAvg',
          header: () => 'Days Since Last Dose Avg.',
          cell: (info) =>
            info.row.original?.medicineStatus?.scoreData?.daysSinceLastDoseAvg,
          enableSorting: true,
        }),

        columnHelper.accessor('medicineStatus.medicineData.remainingDoses', {
          id: 'medicineStatus.medicineData.remainingDoses',
          header: () => 'Remaining Doses',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),

    // scoreData - Streak
    columnHelper.group({
      id: 'Users',
      header: () => 'Streaks',
      columns: [
        columnHelper.accessor('medicineStatus.scoreData.streakLength', {
          id: 'medicineStatus.scoreData.streakLength',
          header: () => 'Max Length',
          cell: (info) =>
            info.row.original?.medicineStatus?.scoreData?.streakLength,
          enableSorting: true,
        }),
        columnHelper.accessor('medicineStatus.scoreData.streakLenghtAvg', {
          id: 'medicineStatus.scoreData.streakLenghtAvg',
          header: () => 'Avg. Length',
          cell: (info) =>
            info.row.original?.medicineStatus?.scoreData?.streakLenghtAvg,
          enableSorting: true,
        }),
        columnHelper.accessor('medicineStatus.scoreData.streakLenghtMax', {
          id: 'medicineStatus.scoreData.streakLenghtMax',
          header: () => 'Latest Length',
          cell: (info) =>
            info.row.original?.medicineStatus?.scoreData?.streakLenghtMax,
          enableSorting: true,
        }),
      ],
    }),

    // Last data
    columnHelper.group({
      id: 'Users',
      header: () => 'Last data update',
      columns: [
        columnHelper.accessor('medicineStatus.lastData.lastDataUpdateUser', {
          id: 'medicineStatus.lastData.lastDataUpdateUser',
          header: () => 'User',
          cell: (info) =>
            info.row.original?.medicineStatus?.lastData?.lastDataUpdateUser ||
            '',
          enableSorting: true,
        }),
        columnHelper.accessor('medicineStatus.lastData.lastDataUpdateApp', {
          id: 'medicineStatus.lastData.lastDataUpdateApp',
          header: () => 'App',
          cell: (info) =>
            info.row.original?.medicineStatus?.lastData?.lastDataUpdateApp ||
            '',
          enableSorting: true,
        }),
        columnHelper.accessor('medicineStatus.lastData.lastDataUpdateDevice', {
          id: 'medicineStatus.lastData.lastDataUpdateDevice',
          header: () => 'Device',
          cell: (info) =>
            info.row.original?.medicineStatus?.lastData?.lastDataUpdateDevice ||
            '',
          enableSorting: true,
        }),
        // App status
        columnHelper.accessor('appStatus.daysSinceLastUserAction', {
          id: 'appStatus.daysSinceLastUserAction',
          header: () => 'Days since last user action',
          cell: (info) => info.row.original?.appStatus?.daysSinceLastUserAction,
          enableSorting: true,
        }),
      ],
    }),
  ];
}

export default tableHeaderAppEvent;
