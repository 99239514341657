import PropTypes from 'prop-types';

function CellStatsMedicinesMedicineId({ medicineId, showDetails }) {
  const handleClick = () => {
    showDetails(medicineId);
  };
  return (
    <div className='cell-stats-medicineId' onClick={handleClick}>
      {medicineId}
    </div>
  );
}

CellStatsMedicinesMedicineId.propTypes = {
  medicineId: PropTypes.number.isRequired,
  showDetails: PropTypes.func.isRequired,
};

export default CellStatsMedicinesMedicineId;
