import { useState, useEffect } from 'react';
import { useLogin } from '../hooks/useLogin';
import { useRegister } from '../hooks/useRegister';
import { NavLink } from 'react-router-dom';
import Spinner from '../components/Spinner';
import Alert from '../components/Alert';
import { MdEmail, MdLock } from 'react-icons/md';
import Logo from '../components/Logo';

import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';

function Login() {
  logEvent(analytics, ANALYTICS_EVENT.login);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailVerificationRequested, setEmailVerificationRequested] =
    useState(null);
  const [error, setError] = useState(null);

  const { isPending: isPendingLogin, error: errorLogin, login } = useLogin();
  const {
    isPending: isPendingVerif,
    error: errorVerif,
    requestEmailVerification,
  } = useRegister();

  useEffect(() => {
    if (errorLogin) {
      setError(errorLogin);
    } else if (errorVerif) {
      setError(errorVerif);
    } else {
      setError(null);
    }
  }, [errorLogin, errorVerif]);

  const handleClick = async (e) => {
    e.preventDefault();
    switch (e.target.id) {
      case 'login':
        setError(null);
        await login(email, password);
        break;
      case 'verify':
        setError(null);
        await requestEmailVerification(null, email, password);
        setEmailVerificationRequested(true);
        break;
      default:
        console.log('Undefined click');
        break;
    }
  };

  useEffect(() => {
    if (errorLogin && errorLogin.includes('Email not verified')) {
      setEmailVerificationRequested(false);
    }
  }, [errorLogin]);

  return (
    <div className='login-container'>
      <div className='login-container-image'>
        <main className='container mt-3'>
          <div className='row'>
            <div className='col-6 mx-auto col-max-width-m'>
              <div className='card-logo'>
                <Logo />
              </div>

              <div className='card'>
                <div className='card-body'>
                  <h3 className='card-title'>Sign in</h3>

                  {(isPendingLogin || isPendingVerif) && (
                    <Spinner
                      isSpinning={isPendingLogin || isPendingVerif}
                    ></Spinner>
                  )}

                  {error != null ? (
                    <div className='mtb-1'>
                      <Alert type='error' content={error} />
                    </div>
                  ) : null}

                  {error === null && emailVerificationRequested === true && (
                    <div className='mtb-1'>
                      <Alert
                        type='success'
                        content='Email verification sent. Check your email for instructions how to complete verification.'
                      />
                    </div>
                  )}

                  {emailVerificationRequested === null &&
                    LoginForm(email, setEmail, password, setPassword)}

                  {(emailVerificationRequested === null ||
                    emailVerificationRequested === false) &&
                    ButtonAction(
                      emailVerificationRequested === null ? 'login' : 'verify',
                      emailVerificationRequested === null
                        ? 'Login'
                        : 'Verify email',
                      handleClick,
                      isPendingLogin || isPendingVerif,
                      email,
                      password,
                    )}

                  {emailVerificationRequested === null && (
                    <div className='col-12 mt-1'>
                      <p>
                        Don&apos;t have account yet?
                        <NavLink
                          to={'/register'}
                          className={() => 'navlink-custom'}
                        >
                          Register here
                        </NavLink>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

function LoginForm(email, setEmail, password, setPassword) {
  return (
    <div className='mt-1'>
      <form className='form-body'>
        <div className='col-12'>
          <label className='form-label' htmlFor='email'>
            Email address *
          </label>
          <div>
            <div className='input-icon'>
              <MdEmail />
            </div>
            <input
              type='email'
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              value={email}
              className='form-control'
              id='email'
              placeholder='Email address'
              required
            />
          </div>
        </div>
        <div className='col-12 mt-1'>
          <label className='form-label' htmlFor='password'>
            Password *
          </label>
          <div>
            <div className='input-icon'>
              <MdLock />
            </div>
            <input
              type='password'
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              value={password}
              className='form-control'
              id='password'
              placeholder='Password'
            />
          </div>
        </div>
        <div className='row mt-1'>
          <div className='col-6'></div>
          <div className='col-6 text-end'>
            <NavLink to={'/trouble'} className={() => 'navlink-custom'}>
              Forgot password?
            </NavLink>
          </div>
        </div>
      </form>
    </div>
  );
}

function ButtonAction(id, title, handleClick, isPending, email, password) {
  return (
    <div className='mt-1'>
      <div className='col-12'>
        <button
          className='btn btn-add-flex'
          id={id}
          disabled={isPending || !(email && password)}
          onClick={handleClick}
        >
          {title}
        </button>
      </div>
    </div>
  );
}

export default Login;
