import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../app/config';

import {
  updateData,
  deleteData,
  addDataWithoutUid,
  sortByField,
} from './data.utils';
import { ValidationError, FirebaseError } from '../utils/errors';

const COMPANY_COLLECTION = 'companies';

//======= General company service
export const getCompaniesAll = async () => {
  try {
    // Get companies
    const collectionRef = collection(db, COMPANY_COLLECTION);
    const docsQuery = query(collectionRef, orderBy('name'));
    const snapshotDocs = await getDocs(docsQuery);
    const companies = snapshotDocs.docs.map((doc) => {
      const compData = doc.data();
      return { id: doc.id, ...compData, companyName: compData.name || '' };
    });

    // Create collection refs for groups
    const companyGroupRefs = companies.map((company) => {
      return collection(db, COMPANY_COLLECTION, company.id, 'groups');
    });

    // Get group docs
    const groupDocs = await Promise.all(
      companyGroupRefs.map((ref) => {
        return getDocs(ref);
      }),
    );

    // Construct data for groups
    const groups = groupDocs
      .map((groupDoc) =>
        groupDoc.docs.map((doc) => {
          const groupData = doc.data();
          return {
            ...doc.data(),
            path: doc.ref.path,
            id: doc.ref.id,
            companyId: doc.ref.parent.parent.id,
            groupName: groupData.name,
          };
        }),
      )
      .reduce((acc, item) => acc.concat(...item), []);

    // combine groups data into company data and sort
    const result = companies.map((company) => {
      const companyGroups = groups.filter(
        (group) => group.companyId === company.id,
      );

      sortByField(companyGroups, 'name');

      return {
        ...company,
        groups: companyGroups || [],
      };
    });
    sortByField(result, 'name');
    return result;
  } catch (error) {
    throw new FirebaseError('Firebase getCompaniesAll error', error.message);
  }
};

//======= Group services
export const updateGroup = async (actionObject) => {
  isValidGroupDataUpdate(actionObject);
  const collectionPath = getCollectionPathFromGroup(actionObject.rawDataGroup);
  await updateData(
    collectionPath,
    actionObject.rawDataGroup.id,
    actionObject.newData,
  );
};
export const deleteGroup = async (actionObject) => {
  if (!actionObject?.rawDataGroup?.id.length > 0) {
    throw new ValidationError(
      'Request error',
      'Invalid group data in the delete request',
    );
  }

  if (actionObject?.rawDataGroup?.users?.length > 0) {
    throw new ValidationError(
      "Can't delete group",
      `Group has ${actionObject?.rawDataCompany?.users?.length} user(s). These must be removed before the group can be deleted.`,
    );
  }

  const collectionPath = getCollectionPathFromGroup(actionObject.rawDataGroup);
  await deleteData(collectionPath, actionObject.rawDataGroup.id);
};

export const createGroup = async (actionObject) => {
  if (!actionObject?.rawDataCompany?.id.length > 0) {
    throw new ValidationError(
      'Request error',
      'Invalid group data in the create request. Group ID not defined',
    );
  }

  if (actionObject?.newData === null || actionObject?.newData === undefined) {
    throw new ValidationError(
      'Request error',
      'Invalid group data in the create request. newData not defined.',
    );
  }

  const collectionPath = `${COMPANY_COLLECTION}/${actionObject.rawDataCompany.id}/groups`;
  await addDataWithoutUid(collectionPath, actionObject.newData);
};

const isValidGroupDataUpdate = (actionObject) => {
  if (
    actionObject?.rawDataGroup !== null &&
    actionObject?.newData !== null &&
    actionObject?.rawDataGroup?.companyId.length > 0
  ) {
    return;
  } else {
    throw new ValidationError(
      'Request error',
      'Invalid group data in the update request',
    );
  }
};

//======= Company services
export const updateCompany = async (actionObject) => {
  isValidCompanyDataUpdate(actionObject);
  await updateData(
    COMPANY_COLLECTION,
    actionObject.rawDataCompany.id,
    actionObject.newData,
  );
};
export const deleteCompany = async (actionObject) => {
  if (!actionObject?.rawDataCompany?.id.length > 0) {
    throw new ValidationError(
      'Request error',
      `Company has ${actionObject?.rawDataCompany?.users?.length} user(s). These must be removed before the company can be deleted.`,
    );
  }

  if (actionObject?.rawDataCompany?.groups?.length > 0) {
    throw new ValidationError(
      "Can't delete company",
      `Company has ${actionObject?.rawDataCompany?.groups?.length} group(s). These must be deleted before the company can be deleted.`,
    );
  }

  if (actionObject?.rawDataCompany?.users?.length > 0) {
    throw new ValidationError(
      "Can't delete company",
      `Company has ${actionObject?.rawDataCompany?.users?.length} user(s). These must be removed before the company can be deleted.`,
    );
  }

  await deleteData(COMPANY_COLLECTION, actionObject.rawDataCompany.id);
};

export const createCompany = async (actionObject) => {
  if (actionObject?.newData !== null) {
    await addDataWithoutUid(COMPANY_COLLECTION, actionObject.newData);
  } else {
    throw new ValidationError(
      'Request error',
      `Company has ${actionObject?.rawDataCompany?.users?.length} user(s). These must be removed before the company can be deleted.`,
    );
  }
};
const isValidCompanyDataUpdate = (actionObject) => {
  if (actionObject?.rawDataCompany !== null && actionObject?.newData !== null) {
    return;
  } else {
    throw new ValidationError(
      'Request error',
      'invalid company data in the update request',
    );
  }
};

const getCollectionPathFromGroup = (rawDataGroup) =>
  `${COMPANY_COLLECTION}/${rawDataGroup.companyId}/groups`;

export const ACTION_OBJECT = {
  action: '',
  rawDataCompany: null,
  rawDataGroup: null,
  newData: null,
  actionResult: null,
};

export const GROUP_ACTIONS = {
  updateCompany: 'updateCompany',
  updateGroup: 'updateGroup',
  deleteCompany: 'deleteCompany',
  deleteGroup: 'deleteGroup',
  createCompany: 'createCompany',
  createGroup: 'createGroup',
};
