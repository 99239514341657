import { createColumnHelper } from '@tanstack/react-table';
import { IoImage, IoOpen } from 'react-icons/io5';
import CellMessageDeviceStatus from './cells/CellMessageDeviceStatus';
import { FaArrowDown, FaArrowRight } from 'react-icons/fa';

function tableHeaderMessages() {
  const columnHelper = createColumnHelper();
  return [
    {
      id: 'expander',
      header: () => null,
      cell: ({ row }) => {
        return row.getCanExpand() ? (
          <button
            {...{
              onClick: row.getToggleExpandedHandler(),
              className: 'btn-transparent',
            }}
          >
            {row.getIsExpanded() ? <FaArrowDown /> : <FaArrowRight />}
          </button>
        ) : (
          'NA'
        );
      },
    },
    columnHelper.accessor('created', {
      id: 'created',
      header: () => 'Time',
      cell: (info) => {
        if (info.getValue()) {
          // message.created in seconds => needs to x 1000 to get milliseconds
          // Show date in local presentation
          return <div>{new Date(info.getValue() * 1000).toLocaleString()}</div>;
        }
        return <div></div>;
      },
      enableSorting: true,
    }),
    columnHelper.accessor('message.data.title_localized', {
      id: 'title_localized',
      header: () => 'Title',
      cell: (info) => {
        // const title = info.getValue();
        const title = info.row.original.message?.data?.title_localized || null;
        if (title != null) {
          const titleEN = JSON.parse(title)?.en;
          const titleFI = JSON.parse(title)?.fi;
          return (
            <div>{titleEN + (titleFI ? ' (FI: ' + titleFI + ')' : '')}</div>
          );
        } else {
          return <div></div>;
        }
      },
      enableSorting: true,
      firebaseSortField: 'message.data.title_localized',
    }),
    columnHelper.accessor('message.data.body_localized', {
      id: 'body_localized',
      header: () => 'Message',
      cell: (info) => {
        // const body = info.getValue();
        const body = info.row.original.message?.data?.body_localized || null;
        if (body != null) {
          const bodyEN = JSON.parse(body)?.en;
          const bodyFI = JSON.parse(body)?.fi;
          return <div>{bodyEN + (bodyFI ? ' (FI: ' + bodyFI + ')' : '')}</div>;
        } else {
          return <div></div>;
        }
      },
      enableSorting: true,
    }),
    columnHelper.accessor('message.data.image', {
      id: 'image',
      header: () => 'Img',
      //   cell: (info) => info.row.original.status.phoneDeviceId,
      //   cell: (info) => info.getValue(),
      cell: (info) => {
        return info.row.original.message?.data?.image ? (
          <a target='_blank' rel='noreferrer' href={info.getValue()}>
            <IoImage />
          </a>
        ) : (
          ''
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor('message.data.link', {
      id: 'link',
      header: () => 'Link',
      cell: (info) => {
        return info.row.original.message?.data?.link ? (
          <a target='_blank' rel='noreferrer' href={info.getValue()}>
            <IoOpen />
          </a>
        ) : (
          ''
        );
      },
      enableSorting: false,
    }),
    columnHelper.accessor('devices', {
      id: 'devices',
      header: () => 'Devices',
      cell: (info) => <CellMessageDeviceStatus rawData={info.row.original} />,
      //   cell: (info) => {
      //     console.log(info.row.original);
      //     return (info.getValue() || []).join(', ');
      //   },
      enableSorting: true,
      firebaseSortField: 'publisherUserName',
    }),
    columnHelper.accessor('publisherUserName', {
      id: 'publisherUserName',
      header: () => 'Sender',
      cell: (info) => <div>{info.getValue()}</div>,
      enableSorting: true,
      firebaseSortField: 'publisherUserName',
    }),
  ];
}
export default tableHeaderMessages;
