import PropTypes from 'prop-types';

function GroupsListSelect({
  companies,
  selectedGroups,
  addSelectedGroup,
  removeSelectedGroup,
}) {
  return (
    <div className='row mt-1'>
      <div className='col-12'>
        <div
          style={{
            maxHeight: '300px',
            overflowY: 'auto',
          }}
        >
          <div className='col-12'>
            <div className='c-accordion'>
              {companies.map((company) => {
                return (
                  <div
                    className='c-accordion__item'
                    key={`company-${company.id}`}
                  >
                    {company.name}
                    <div
                      className='c-accordion__body is-open'
                      id='ap-1'
                      role='region'
                      aria-labelledby='at-1'
                      aria-hidden='false'
                    >
                      {(company.groups || []).map((group) => {
                        const findGroup = selectedGroups.find(
                          (selectedGroup) => selectedGroup.groupId === group.id,
                        );
                        const checked = !!findGroup;
                        return (
                          <div key={`group-${group.id}`}>
                            <input
                              type='checkbox'
                              checked={checked}
                              onChange={() => {
                                const item = {
                                  companyId: company.id,
                                  groupId: group.id,
                                };
                                if (
                                  selectedGroups.find(
                                    (selectedGroup) =>
                                      selectedGroup.groupId === group.id,
                                  )
                                ) {
                                  removeSelectedGroup(item);
                                } else {
                                  addSelectedGroup(item);
                                }
                              }}
                              name={group.name}
                              id={group.id}
                            />
                            {group.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

GroupsListSelect.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  addSelectedGroup: PropTypes.func.isRequired,
  removeSelectedGroup: PropTypes.func.isRequired,
};

export default GroupsListSelect;
