import { doc, getDoc } from 'firebase/firestore';
import { db } from '../app/config';
import { updateData } from './data.utils';
import { FirebaseError, ValidationError } from '../utils/errors';
import { CLOUD_SERVER_OPTIONS } from '../shared/Constants';

const CLOUD_SERVER_COLLECTION = 'production/cloudServerMapping/devices';
const DEFAULT_FREE_FILTER_FIELD = 'id';
const DEFAULT_FREE_FILTER_FIELD_TYPE = 'string';
const FREE_FILTER_QUERY = 'in';
const DEFAULT_SORT_DIRECTION = 'asc';

export const defaultFilterObject = {
  mainDataField: CLOUD_SERVER_COLLECTION,
  freeFilterField: DEFAULT_FREE_FILTER_FIELD,
  freeFilterFieldType: DEFAULT_FREE_FILTER_FIELD_TYPE,
  freeFilterQuery: FREE_FILTER_QUERY,
  freeFilter: '',
  sortDirection: DEFAULT_SORT_DIRECTION,
  selectedCompanies: [],
  selectedGroups: [],
  dateStart: '',
  dateEnd: '',
};

//======= Get cloud server
export const getCloudServer = async (deviceId) => {
  if (
    deviceId === undefined ||
    deviceId === null ||
    deviceId.length != 10 ||
    !/^\d+$/.test(deviceId)
  ) {
    const errorReason = `Device SN ${deviceId} is: empty OR deviceId is not 10 digits long OR device SN contains invalid characters.`;
    throw new ValidationError('Invalid device SN', errorReason);
  }

  try {
    const docRef = doc(db, CLOUD_SERVER_COLLECTION, deviceId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return {
        deviceId: docSnap.id,
        ...docSnap.data(),
      };
    } else {
      throw new FirebaseError('Device not found', deviceId);
    }
  } catch (error) {
    throw new FirebaseError('Firebase getCloudServer', error.message);
  }
};
//======= Update cloud server
export const updateCloudServer = async (deviceId, newCloudServer) => {
  if (!isValidCouldServerOption(newCloudServer)) {
    throw new ValidationError('Not valid cloud server option', newData);
  }
  const newData = {
    cloudServer: newCloudServer,
  };
  await updateData(CLOUD_SERVER_COLLECTION, deviceId, newData);
};

const isValidCouldServerOption = (newData) => {
  return CLOUD_SERVER_OPTIONS.includes(newData);
};
