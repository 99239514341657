import { useAuthContext } from './hooks/useAuthContext';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AppDataProvider } from './app/AppDataContext';
import PageLayout from './components/layout/PageLayout';
import AuthLayout from './components/layout/AuthLayout';
import Home from './pages/Home';
import Account from './pages/Account';
import Groups from './pages/Groups';
import EventLog from './pages/EventLog';
import NotFound from './pages/NotFound';
import Spinner from './components/Spinner';
import TestPage from './pages/TestPage';
import Devices from './pages/Devices';
import Messages from './pages/Messages';
import Users from './pages/Users';
import AppIds from './pages/AppIds';
import MedicineIds from './pages/MedicineIds';
import Stats from './pages/Stats';
import CloudServer from './pages/CloudServer';
import PushMessages from './pages/PushMessages';

import Login from './pages/Login';
import Verify from './pages/Verify';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import { isDevEnv } from './utils/utils';
// import AppStatus from './pages/AppStatus';
// import DataOld from './pages/DataOld';

function Router() {
  const { user, authIsReady } = useAuthContext();
  if (authIsReady === false || user === undefined) {
    return (
      <AuthLayout>
        <Spinner isSpinning={true} />
      </AuthLayout>
    );
  }

  return (
    <>
      {user !== null ? (
        <PageLayout>
          <AppDataProvider>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/test' element={<TestPage />} />
              <Route path='/app-ids' element={<AppIds />} />
              <Route path='/medicine-ids' element={<MedicineIds />} />
              <Route path='/stats' element={<Stats />} />
              <Route path='/event-log/:id?' element={<EventLog />} />
              <Route path='/devices' element={<Devices />} />
              <Route path='/admin/users' element={<Users />} />
              <Route path='/admin/groups' element={<Groups />} />
              <Route path='/admin/push-messages' element={<PushMessages />} />
              <Route path='/admin/messages' element={<Messages />} />
              <Route path='/account' element={<Account />} />
              {isDevEnv() && (
                <Route path='/admin/cloud-server' element={<CloudServer />} />
              )}

              {/* <Route path='/app-status' element={<AppStatus />} />
              <Route path='/old-event-data' element={<DataOld />} /> */}
              <Route path='/register' element={<Navigate to='/' />} />
              <Route path='/login' element={<Navigate to='/' />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </AppDataProvider>
        </PageLayout>
      ) : (
        <AuthLayout>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/trouble' element={<ForgotPassword />} />
            <Route path='/_/auth/*' element={<Verify />} />

            <Route path='/' element={<Navigate to='/login' />} />
            <Route path='/logout' element={<Navigate to='/login' />} />
            <Route path='/devices' element={<Navigate to='/login' />} />
            <Route path='/account' element={<Navigate to='/login' />} />
            <Route path='/admin/*' element={<Navigate to='/login' />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </AuthLayout>
      )}
    </>
  );
}

export default Router;
