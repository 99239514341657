import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Table from '../components/table/Table';
import Spinner from '../components/Spinner';
import TablePagination from '../components/table/TablePagination';
import tableHeaderAppEvent from '../components/table/tableHeaderAppEvent';
import { FaDownload } from 'react-icons/fa';
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import { getEventLogs } from '../services/eventLog.service';
import { useServiceDataSource } from '../hooks/useServiceDataSource';
import useAppDataContext from '../hooks/useAppDataContext';
import FilterA from '../components/filters/FilterA';
import Alert from '../components/Alert';

import { analytics } from '../app/config';
import { logEvent } from 'firebase/analytics';
import { ANALYTICS_EVENT } from '../shared/Constants';

function EventLog() {
  logEvent(analytics, ANALYTICS_EVENT.page_view_eventLog);

  const {
    error,
    data,
    filterObject,
    isLoading,
    pageLastNumber,
    pageSize,
    totalDocs,
    loadMoreData,
    reloadData,
    updateFilterObject,
    updatePageSize,
  } = useServiceDataSource(
    getEventLogs.getData,
    getEventLogs.getQuery,
    getEventLogs.getDocsCount,
    getEventLogs.defaultFilterObject,
    null,
    getEventLogs.autoload,
  );

  const { companies } = useAppDataContext();
  const routeParams = useParams();
  const [triggerSearch, setTriggerSearch] = useState(false);

  // Table sorting
  const [sorting, setSorting] = useState([]);
  // Table - create columns
  const columns = tableHeaderAppEvent();

  // Table - create table content
  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
  });

  // Check if in URL there are id provided
  useEffect(() => {
    // check if number has exactly 10 char
    if (routeParams?.id?.length === 10) {
      // check what is id first number
      // route based on number
      const firstChar = routeParams.id.substring(0, 1);
      switch (firstChar) {
        case '1':
          // Use deviceSN search
          updateFilterObject((prevState) => ({
            ...prevState,
            freeFilter: routeParams.id,
            freeFilterField: 'deviceId',
          }));
          setTriggerSearch(true);
          break;
        case '6':
          // Use App ID search
          updateFilterObject((prevState) => ({
            ...prevState,
            freeFilter: routeParams.id,
            freeFilterField: 'appId',
          }));
          setTriggerSearch(true);
          break;
        case '7':
          // Use Medicine ID search
          updateFilterObject((prevState) => ({
            ...prevState,
            freeFilter: routeParams.id,
            freeFilterField: 'medicineId',
          }));
          setTriggerSearch(true);
          break;
        default:
          console.log('url id not defined for search');
          alert('Not a valid ID for a search');
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams]);

  // Trigger search when triggerSearch changed to TRUE
  useEffect(() => {
    if (triggerSearch === true) {
      reloadData();
      setTriggerSearch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSearch]);

  const loadMoreDataEnabled = () => {
    if (
      totalDocs === data.length ||
      filterObject.freeFilter.trim().length < 10
    ) {
      return false;
    } else {
      return true;
    }
  };

  const runSearch = () => {
    const firstChar = filterObject.freeFilter.substring(0, 1);
    switch (firstChar) {
      case '1':
        // Use deviceSN search
        updateFilterObject((prevState) => ({
          ...prevState,
          freeFilterField: 'deviceId',
        }));
        setTriggerSearch(true);
        break;
      case '6':
        // Use App ID search
        updateFilterObject((prevState) => ({
          ...prevState,
          freeFilterField: 'appId',
        }));
        setTriggerSearch(true);
        break;
      case '7':
        // Use Medicine ID search
        updateFilterObject((prevState) => ({
          ...prevState,
          freeFilterField: 'medicineId',
        }));
        setTriggerSearch(true);
        break;
      default:
        console.log('url id not defined for search');
        alert('Not a valid ID for a search');
        break;
    }
  };

  const exportData = () => {
    // TODO: create new func to export all data
    alert('TODO: create func');
    // exportDataOldCsv(data, 'data-medicineIds');
  };

  // Config for FilterA component
  const filterConfig = {
    enabled: {
      isFreeText: true,
      isCompanies: false,
      isFromDate: false,
      isToDate: false,
    },
    filterMethods: {
      filterFree: {
        freeFilterPlaceHolder: 'Enter any ID',
        disableIfEmpty: true,
      },
      filterGroups: {
        companies: companies,
      },
      filterDate: {
        limitDateRange: false,
      },
    },
  };

  return (
    <div className='table-container'>
      <div className='table-menu-header'>
        <div>
          <h3>Event log</h3>
          {totalDocs === 0 && <p>No results</p>}
        </div>
        <div className='table-menu-header-actions'>
          <button
            className={`btn-icon`}
            type='button'
            title='Download App ID data'
            onClick={exportData}
          >
            <FaDownload />
          </button>
        </div>
      </div>

      <FilterA
        filterConfig={filterConfig}
        filterObject={filterObject}
        updateFilterObject={updateFilterObject}
        triggerSearch={runSearch}
      />

      <Spinner isSpinning={isLoading}></Spinner>

      {error != null && (
        <div className='mtb-1'>
          <Alert type='error' title={error.message} content={error.cause} />
        </div>
      )}

      {totalDocs > 0 && (
        <>
          <h3 className='mt-2'>Events - total count: {totalDocs}</h3>

          <Table table={table} />

          <TablePagination
            pageCurrentNumber={Math.ceil(data.length / pageSize)}
            pageLastNumber={pageLastNumber}
            pageSize={pageSize}
            totalNumberOfDocs={totalDocs}
            changePageSize={(newSize) => {
              updatePageSize(newSize);
            }}
            loadMoreDataEnabled={loadMoreDataEnabled()}
            loadMoreData={loadMoreData}
          />
        </>
      )}
    </div>
  );
}
export default EventLog;
