import { createStatsFetchMethod } from './stats.service';
import { EVENT_LOG_COLLECTION } from '../shared/Constants';

const COLLECTION = EVENT_LOG_COLLECTION; // NOTE: Limited only to this year collection
const DEFAULT_FIELD = 'appId';
const DEFAULT_FREE_FILTER_FIELD = 'appId';
const FREE_FILTER_QUERY = 'in';
const DEFAULT_DAY_FIELD = 'eventTimestamp';
const DEFAULT_SORT_DIRECTION = 'desc';
const MAX_COUNT = 5000;
const AUTO_LOAD = false;

export const getEventLogs = createStatsFetchMethod(
  COLLECTION,
  DEFAULT_FIELD,
  DEFAULT_FREE_FILTER_FIELD,
  FREE_FILTER_QUERY,
  DEFAULT_DAY_FIELD,
  DEFAULT_SORT_DIRECTION,
  MAX_COUNT,
  AUTO_LOAD,
);
