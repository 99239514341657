import { useState } from 'react';
import { moveDevicesToGroup } from '../../services/device.service';
import Modal from '../Modal';
import Alert from '../Alert';
import Spinner from '../Spinner';
import GroupsListSelect from '../GroupsListSelect';
import PropTypes from 'prop-types';

function MoveDeviceModal({
  companies,
  selectedDevices,
  moveDeviceModalOpen,
  closeMoveDeviceModal,
}) {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [moveResult, setMoveResult] = useState(null);

  const reset = () => {
    closeMoveDeviceModal(false);
  };

  const onConfirm = async () => {
    setIsUpdating(true);

    if (
      selectedGroups.length === 0 ||
      !selectedGroups[0]?.companyId?.length > 0 ||
      !selectedGroups[0]?.groupId?.length > 0
    ) {
      setMoveResult({
        result: false,
        title: 'Invalid request',
        content: 'Missing data in the request',
      });
      setIsUpdating(false);
    } else {
      const deviceIdArray = selectedDevices.map((deviceId) =>
        deviceId.toString(),
      );
      const response = await moveDevicesToGroup(
        deviceIdArray,
        selectedGroups[0].companyId,
        selectedGroups[0].groupId,
      );

      setIsUpdating(false);
      if (response === undefined) {
        setMoveResult(null);
        closeMoveDeviceModal(true);
      } else {
        setMoveResult(response);
      }
    }
  };

  const addSelectedGroup = (_selectedGroup) => {
    setSelectedGroups([_selectedGroup]);
  };
  const removeSelectedGroup = () => {
    setSelectedGroups([]);
  };

  return (
    <Modal isOpen={moveDeviceModalOpen}>
      <div className='card-m'>
        <div className='card-body-m'>
          <h3 className='card-title'>Move to group</h3>
          <hr />

          {isUpdating && <Spinner isSpinning={isUpdating} />}
          {moveResult && (
            <div className='mtb-1'>
              <Alert
                type='error'
                title={moveResult.title}
                content={moveResult.content}
              />
            </div>
          )}
          {mSelectedDevices(selectedDevices)}

          <GroupsListSelect
            companies={companies}
            selectedGroups={selectedGroups}
            addSelectedGroup={addSelectedGroup}
            removeSelectedGroup={removeSelectedGroup}
          />

          <div className='row mt-2'>
            <div className='col-12 c-group u-h-evenly'>
              <button className={`btn is-secondary`} onClick={reset}>
                Cancel
              </button>
              <button
                className={`btn`}
                disabled={
                  selectedGroups.length === 0 &&
                  !selectedGroups[0]?.companyId?.length > 0 &&
                  !selectedGroups[0]?.groupId?.length > 0
                }
                onClick={() => onConfirm()}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const mSelectedDevices = (selectedDevices) => {
  return (
    <div>
      <b>Selected devices:</b>
      <textarea
        className='textarea-no-edit'
        disabled
        defaultValue={selectedDevices.join(', ')}
      />
    </div>
  );
};

MoveDeviceModal.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedDevices: PropTypes.arrayOf(PropTypes.number).isRequired,
  moveDeviceModalOpen: PropTypes.bool.isRequired,
  closeMoveDeviceModal: PropTypes.func.isRequired,
};

export default MoveDeviceModal;
