import { createStatsFetchMethod } from './stats.service';

const COLLECTION = 'users';
const DEFAULT_FIELD = 'appId';
const DEFAULT_FREE_FILTER_FIELD = 'appId';
const FREE_FILTER_QUERY = 'in';
const DEFAULT_DAY_FIELD = 'appUseStartDay';
const DEFAULT_SORT_DIRECTION = 'asc';
const MAX_COUNT = 5000;
const AUTO_LOAD = false;

export const getAppIds = createStatsFetchMethod(
  COLLECTION,
  DEFAULT_FIELD,
  DEFAULT_FREE_FILTER_FIELD,
  FREE_FILTER_QUERY,
  DEFAULT_DAY_FIELD,
  DEFAULT_SORT_DIRECTION,
  MAX_COUNT,
  AUTO_LOAD,
);
