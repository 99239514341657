import { useState } from 'react';
import { auth } from '../app/config';
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signOut,
  applyActionCode,
  sendEmailVerification,
  updateProfile,
} from 'firebase/auth';

import { useAuthContext } from './useAuthContext';

export const useRegister = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const ERROR_AUTH = 'Authentication error';

  const register = async (email, password, displayName) => {
    setError(null);
    setIsPending(true);
    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );

      if (!response) {
        throw new Error(ERROR_AUTH);
      }

      await updateProfile(response.user, { displayName: displayName });

      await requestEmailVerification(response.user);

      await signOut(auth);
      dispatch({ type: 'LOGOUT', payload: null });

      setError(null);
      setIsPending(false);
      return true;
    } catch (err) {
      console.log(err);
      if (err.code === 'auth/email-already-in-use') {
        setError(
          `There is already an account for email: ${email} . Please login to service.`,
        );
      } else if (err?.message === ERROR_AUTH) {
        setError(err.message);
      } else {
        setError(
          'Oops, some error when creating an account, please try again.',
        );
      }
      await signOut(auth);
      dispatch({ type: 'LOGOUT', payload: null });
      setIsPending(false);
      return false;
    }
  };

  const requestEmailVerification = async (user, email, password) => {
    setError(null);
    setIsPending(true);
    try {
      if (user != null) {
        await sendEmailVerification(user);
        setError(null);

        await signOut(auth);
        dispatch({ type: 'LOGOUT', payload: null });

        setIsPending(false);
        return;
      } else {
        if (email && password) {
          const response = await signInWithEmailAndPassword(
            auth,
            email,
            password,
          );
          if (response.user) {
            setError(null);
            await sendEmailVerification(response.user);

            await signOut(auth);
            dispatch({ type: 'LOGOUT', payload: null });
          } else {
            setError("Can't fetch user account details, check later again");
          }
        } else {
          setError('Missing email or password');
        }
        setIsPending(false);
        return;
      }
    } catch (err) {
      setError('Undefined error in request email verification');
      setIsPending(false);
      return;
    }
  };

  const resetPassword = async (email) => {
    setError(null);
    setIsPending(true);
    try {
      await sendPasswordResetEmail(auth, email);

      setError(null);
      setIsPending(false);

      return 'Password reset email sent!';
    } catch (err) {
      setError(err.message);
      setIsPending(false);
    }
  };

  const resetPasswordVerifyCode = async (code) => {
    setError(null);
    setIsPending(true);
    try {
      const result = await verifyPasswordResetCode(auth, code);
      if (!result) {
        throw new Error('Password recovery, email and link does not match.');
      }

      setError(null);
      setIsPending(false);
      return result;
    } catch (err) {
      setError('Invalid verification code');
      setIsPending(false);
    }
  };

  const resetPasswordSetNew = async (newPassword, code) => {
    setError(null);
    setIsPending(true);

    try {
      await confirmPasswordReset(auth, code, newPassword);
      setError(null);
      setIsPending(false);
      return true;
    } catch (err) {
      setError(
        'Error: The recovery code might have expired or the password is too weak.',
      );
      setIsPending(false);
      return false;
    }
  };

  const verifyEmail = async (code) => {
    // Dev issue: React.StrictMode triggers twice the call
    // this causes that the link is not valid in Firebase
    setError(null);
    setIsPending(true);

    try {
      await applyActionCode(auth, code);
      setError(null);
      setIsPending(false);
      return true;
    } catch (err) {
      setError(
        'Error: Invalid email verification code or code might have expired. Please request a new verification code.',
      );
      setIsPending(false);
      return false;
    }
  };

  return {
    error,
    isPending,
    register,
    resetPassword,
    resetPasswordVerifyCode,
    resetPasswordSetNew,
    verifyEmail,
    requestEmailVerification,
  };
};
