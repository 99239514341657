import { useAuthContext } from '../hooks/useAuthContext';
import Logo from '../components/Logo';

function NotFound() {
  const { user } = useAuthContext();

  return (
    <>
      {user && (
        <main className='container'>
          <div style={{ textAlign: 'center' }}>
            <h1>Oops, page not found</h1>
          </div>
        </main>
      )}
      {user === null && (
        <div className='login-container'>
          <div className='login-container-image'>
            <main className='container mt-3'>
              <div className='row'>
                <div className='col-6 mx-auto col-max-width-m'>
                  <div className='card-logo'>
                    <Logo />
                  </div>

                  <div className='card'>
                    <div className='card-body'>
                      <h2 className='card-title'>Oops, page not found</h2>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
    </>
  );
}
export default NotFound;
