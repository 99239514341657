import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import firebaseConfig from './firebaseConfig.json';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { startLocalEmulators } from './startLocalEmulators';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);
const perf = getPerformance(app);

// If .env.local file exists AND REACT_APP_IS_LOCAL variable set
// check then should local emulators be started based on .env.local file
if (
  process.env.REACT_APP_IS_LOCAL &&
  process.env.REACT_APP_IS_LOCAL === 'true'
) {
  startLocalEmulators(
    connectFunctionsEmulator,
    functions,
    connectAuthEmulator,
    auth,
    connectFirestoreEmulator,
    db,
  );
}

export { auth, app, db, analytics, perf };
