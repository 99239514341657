import { createColumnHelper } from '@tanstack/react-table';

function tableHeaderStatsUsers() {
  const columnHelper = createColumnHelper();
  return [
    columnHelper.group({
      id: 'Users',
      header: () => 'Users',
      columns: [
        columnHelper.accessor('appId', {
          id: 'appId',
          header: () => 'App ID',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.companyName', {
          id: 'data.companyName',
          header: () => 'Company',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.groupName', {
          id: 'data.groupName',
          header: () => 'Group',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),
    // Views
    columnHelper.group({
      id: 'appViews',
      header: () => <span>App page views</span>,
      columns: [
        columnHelper.accessor('data.data.counterViewPopitIntro', {
          id: 'data.data.counterViewPopitIntro',
          header: () => 'Intro',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.counterViewAppMessages', {
          id: 'data.data.counterViewAppMessages',
          header: () => 'Content',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.counterViewFaq', {
          id: 'data.data.counterViewFaq',
          header: () => 'FAQ',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.counterViewTermsOfUse', {
          id: 'data.data.counterViewTermsOfUse',
          header: () => 'ToC',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.counterViewPrivacyPolicy', {
          id: 'data.data.counterViewPrivacyPolicy',
          header: () => 'PP',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),
    // Views
    columnHelper.group({
      id: 'appViews',
      header: () => <span>Last status</span>,
      columns: [
        columnHelper.accessor('data.day', {
          id: 'data.day',
          header: () => 'Last data update',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.daysSinceLastUserAction', {
          id: 'data.data.daysSinceLastUserAction',
          header: () => 'Days Since Last Action',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.phoneBTisON', {
          id: 'data.data.phoneBTisON',
          header: () => 'Phone BT',
          cell: (info) => {
            const btON = info?.row?.original?.data?.data?.phoneBTisON;
            if (btON === true) {
              return 'true';
            } else if (btON === false) {
              return 'false';
            } else {
              return '';
            }
          },
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.timezone', {
          id: 'data.data.timezone',
          header: () => 'Time zone',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
        columnHelper.accessor('data.data.useDaysApp', {
          id: 'data.data.useDaysApp',
          header: () => 'Days Used',
          cell: (info) => info.getValue(),
          enableSorting: true,
        }),
      ],
    }),
  ];
}

export default tableHeaderStatsUsers;
