import { FaUserEdit } from 'react-icons/fa';
import { VscGroupByRefType } from 'react-icons/vsc';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';

function CellUsersAction({
  user,
  handleUserEdit,
  handleUserGroupEdit,
  handleUserEnable,
  userEnabled,
}) {
  return (
    <div className='row'>
      <div className='col-12 c-group u-v-center'>
        <button
          type='button'
          className='btn-transparent'
          title='Edit user'
          onClick={() => {
            handleUserEdit(user);
          }}
        >
          <FaUserEdit />
        </button>
        <button
          type='button'
          className='btn-transparent'
          title='Edit user groups'
          onClick={() => {
            handleUserGroupEdit(user);
          }}
        >
          <VscGroupByRefType />
        </button>
        <button
          type='button'
          className='btn-transparent'
          title={userEnabled ? 'Enable user' : 'Disable user'}
          onClick={() => {
            handleUserEnable(user);
          }}
        >
          {userEnabled ? <FaMinusCircle /> : <FaCheckCircle />}
        </button>
      </div>
    </div>
  );
}

CellUsersAction.propTypes = {
  user: PropTypes.object.isRequired,
  handleUserEdit: PropTypes.func.isRequired,
  handleUserGroupEdit: PropTypes.func.isRequired,
  handleUserEnable: PropTypes.func.isRequired,
  userEnabled: PropTypes.bool.isRequired,
};

export default CellUsersAction;
